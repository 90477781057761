export const moduleName = 'auth';

export default {
  FIRST_LOGIN: `${moduleName}/FIRST_LOGIN`,
  // Login
  LOGIN_FAILURE: `${moduleName}/LOGIN_FAILURE`,
  LOGIN_FAILURE_RESEND: `${moduleName}/LOGIN_FAILURE_RESEND`,
  LOGIN_REQUEST: `${moduleName}/LOGIN_REQUEST`,
  LOGIN_RESET: `${moduleName}/LOGIN_RESET`,
  LOGIN_SUCCESS: `${moduleName}/LOGIN_SUCCESS`,
  LOGIN_THROTTLING: `${moduleName}/LOGIN_THROTTLING`,
  // Logout
  LOGOUT: `${moduleName}/LOGOUT`,

  REGISTER_FAILURE: `${moduleName}/REGISTER_FAILURE`,

  // Register
  REGISTER_REQUEST: `${moduleName}/REGISTER_REQUEST`,

  REGISTER_SUCCESS: `${moduleName}/REGISTER_SUCCESS`,
  RESEND_INVITATION_EMAIL_FAILURE: `${moduleName}/RESEND_INVITATION_EMAIL_FAILURE`,
  RESEND_INVITATION_EMAIL_REQUEST: `${moduleName}/RESEND_INVITATION_EMAIL_REQUEST`,

  RESEND_INVITATION_EMAIL_SUCCESS: `${moduleName}/RESEND_INVITATION_EMAIL_SUCCESS`,
  RESET_PASSWORD_FAILURE: `${moduleName}/RESET_PASSWORD_FAILURE`,
  // Reset password
  RESET_PASSWORD_REQUEST: `${moduleName}/RESET_PASSWORD_REQUEST`,
  RESET_PASSWORD_SUCCESS: `${moduleName}/RESET_PASSWORD_SUCCESS`,
  // Set auth token
  SET_AUTH_TOKEN: `${moduleName}/SET_AUTH_TOKEN`,
  SET_FIRST_LOGIN: `${moduleName}/SET_FIRST_LOGIN`,
  SET_ORY_LOGIN_STATUS: `${moduleName}/SET_ORY_LOGIN_STATUS`,
};
