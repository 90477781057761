import React, { Suspense, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { PATHS } from '../config/consts';
import GTCPage from '../containers/GTCPage';
import GTCPageDetail from '../containers/GTCPageDetail';
import HomePage from '../containers/HomePage';
import MVideo from '../containers/MVideo';
import RemoteConfigurationPage from '../containers/RemoteConfigurationPage';
import { useCookieConsent } from '../helpers/cookieConsent';
import LoginPage from '../pages/LoginPage';
import LogoutPage from '../pages/LogoutPage';
import { Register } from '../pages/Register/Register';
import Resend from '../pages/ResendPage';
import { ResetPasswordPage } from '../pages/ResetPasswordPage/ResetPasswordPage';
import SuspiciousLoginPage from '../pages/SuspiciousLoginPage';
import { AppRoutes } from './AppRoutes';

interface IDataLayer {
  event: string;
  page: {
    url: string;
  };
}

declare global {
  interface Window {
    dataLayer: IDataLayer[];
  }
}

export function Router(): JSX.Element {
  const cookieConsent = useCookieConsent();
  const location = useLocation();

  useEffect(() => {
    if (
      cookieConsent.marketing &&
      process.env.GOOGLE_TAG_MANAGER_ID !== undefined &&
      process.env.GOOGLE_TAG_MANAGER_ID.length > 0
    ) {
      TagManager.initialize({
        gtmId: process.env.GOOGLE_TAG_MANAGER_ID ?? '',
      });
    }
  }, [cookieConsent.marketing]);

  useEffect(() => {
    if (
      cookieConsent.marketing &&
      process.env.GOOGLE_TAG_MANAGER_ID !== undefined &&
      process.env.GOOGLE_TAG_MANAGER_ID.length > 0
    ) {
      window.dataLayer.push({
        event: 'pageview',
        page: {
          url: location?.pathname,
        },
      });
    }
  }, [location?.pathname, cookieConsent.marketing]);

  return (
    <Suspense fallback={<div />}>
      <Routes>
        <Route path="/" element={<HomePage />} />

        <Route path={PATHS.LANDING_MY2N_APP} element={<MVideo />} />
        <Route path={PATHS.LANDING_REMOTE_CONFIGURATION} element={<RemoteConfigurationPage />} />
        <Route path="/cookiespolicy" element={<Navigate replace to={PATHS.COOKIE_POLICY} />} />
        <Route path="/data-processing-terms" element={<Navigate replace to={PATHS.DATA_PROCESSING_TERMS} />} />
        <Route path="/order-form" element={<Navigate replace to={PATHS.ORDER_FORM} />} />
        <Route path="/privacy-notice" element={<Navigate replace to={PATHS.PRIVACY_NOTICE} />} />
        <Route path="/terms" element={<Navigate replace to={PATHS.TERMS_OF_SERVICE} />} />
        <Route path={PATHS.GTC_PAGE_DETAIL} element={<GTCPageDetail />} />
        <Route path={PATHS.GTC_PAGE_DETAIL_BASIC} element={<GTCPageDetail />} />
        <Route path={PATHS.GTC_PAGES} element={<GTCPage />} />
        <Route path={PATHS.RESEND} element={<Resend />} />
        <Route path={PATHS.SUSPICIOUS_LOGIN} element={<SuspiciousLoginPage />} />
        <Route path={PATHS.LOGOUT} element={<LogoutPage />} />
        <Route path={PATHS.PASSWORD_RESET} element={<ResetPasswordPage />} />
        <Route path={PATHS.LOGIN} element={<LoginPage />} />
        <Route path={PATHS.REGISTER} element={<Register />} />

        <Route path="*" element={<AppRoutes />} />
      </Routes>
    </Suspense>
  );
}
