import { yupResolver } from '@hookform/resolvers/yup';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { FooterPublic, HeaderPublic, Link } from '../../components';
import { PATHS } from '../../config/consts';
import { ICompanyRoles } from '../../hooks/rolesHooks';
import actions from '../../modules/auth';
import {
  getAccessTokenSelector,
  getResendEmail,
  getThrottlingRetryAfterDate,
  isLoggedInSelector,
} from '../../modules/auth/selectors';
import { isSiteVersionWarningDisplayed } from '../../modules/common/selectors';
import { TId } from '../../modules/commonTypes';
import {
  getCurrentUserSelector,
  isMarketer as isMarketerSelector,
  isSuperadmin as isSuperadminSelector,
} from '../../modules/user/selectors';
import { useOryUserLogged } from '../../ory/hooks/oryUser/useOryUserLogged';
import { useOryUserLogin } from '../../ory/hooks/oryUserLogin/useOryUserLogin';
import { getBasicEmailValidation } from '../../validations/user/userCommonValidations';
import { OryErrorBar } from './components/OryErrorBar';
import { LoginAttemptsAlert } from './LoginAttemptsAlert';
import { AuthLoginForm } from './LoginPageForm';
import messages from './messages';
import { getRedirectPathForCurrentUser } from './redirectPath';
import { useSetAccessToken } from './useSetAccessToken';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const landingBgImage = require('../../assets/landing_bg.jpg');

const styles = {
  alert: {
    position: 'absolute',
    top: (theme: Theme) => ({
      md: theme.appNotificationHeightLargeDevice,
      xs: theme.appNotificationHeightSmallDevice,
    }),
    width: '100%',
  },
  box: {
    bgcolor: 'common.white',
    borderRadius: 1.5,
    boxSizing: 'border-box',
    mt: { sm: 7.5, xs: 3 },
    p: 3,
    width: '100%',
  },
  boxContent: {
    maxWidth: '810px',
    mx: 'auto',
    my: 0,
    p: 1.25,
    width: '100%',
  },
  sub: {
    '& a': {
      color: 'common.white',
      ml: 0.5,
      textDecoration: 'underline',
      textTransform: 'lowercase',
    },
    color: 'common.white',
    mb: 9,
    mt: 10,
    textAlign: 'center',
  },
  title: {
    color: 'common.white',
    fontSize: '32px',
    fontWeight: 'bold',
    mb: 3,
    mt: { sm: '55px', xl: '125px' },
    textAlign: 'center',
  },
  wrapper: {
    backgroundImage: `url(${landingBgImage})`,
    backgroundPosition: '50% 20%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minHeight: '980px',
    mx: 'auto',
    my: 0,
  },
};

interface ILoginForm {
  email: string;
  password: string;
}

export function LoginPage() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const accessToken = useSelector(getAccessTokenSelector);

  useSetAccessToken();
  const isSuperadmin = useSelector(isSuperadminSelector);
  const isMarketer = useSelector(isMarketerSelector);
  const currentUser = useSelector(getCurrentUserSelector) as {
    id: TId;
    lastVisitedSiteId?: number | null;
    lastVisitedCompanyId?: number | null;
    companies: ICompanyRoles[];
  };
  const resendEmail = useSelector(getResendEmail);
  const loggedIn = useSelector(isLoggedInSelector);
  const retryAfterDate = useSelector(getThrottlingRetryAfterDate);
  const isAlertShown = useSelector(isSiteVersionWarningDisplayed);

  const { isOryUserLoggedIn, oryLoginStatus } = useOryUserLogged({ accessToken, currentUser });
  const { getFormAlertMessage, isOryApiFetching, loginOryUser } = useOryUserLogin();

  useEffect(() => {
    if ((currentUser.id !== undefined && loggedIn) || isOryUserLoggedIn) {
      if (isSuperadmin) {
        navigate(PATHS.SUPERADMIN_DASHBOARD);
      }

      if (isMarketer) {
        navigate(PATHS.MARKETER_ACTIVATION_CODES);
      }

      if (currentUser.id !== undefined) {
        const redirectPath = getRedirectPathForCurrentUser({
          companies: currentUser.companies,
          lastVisitedCompanyId: currentUser.lastVisitedCompanyId,
          lastVisitedSiteId: currentUser.lastVisitedSiteId,
        });
        navigate(redirectPath);
      }
    }
  }, [currentUser.id, oryLoginStatus]);

  useEffect(() => {
    if ((resendEmail?.length ?? 0) > 0) {
      navigate(PATHS.RESEND);
    }
  }, [resendEmail]);

  const formData = useForm<ILoginForm>({
    defaultValues: { email: '', password: '' },
    mode: 'onChange',
    resolver: yupResolver(
      Yup.object().shape({
        email: getBasicEmailValidation(
          formatMessage(messages.authLoginEmailInvalid),
          formatMessage(messages.authLoginEmailRequired)
        ),
        password: Yup.string().required(formatMessage(messages.authLoginPasswordRequired)),
      })
    ),
  });

  return (
    <Box sx={{ ...(isAlertShown && styles.alert) }}>
      <HeaderPublic />
      <Box sx={styles.wrapper}>
        <Box sx={styles.boxContent}>
          <Typography sx={styles.title} variant="h1">
            <FormattedMessage {...messages.authLoginTitle} />
          </Typography>
          <Box sx={styles.box}>
            <Box>
              <OryErrorBar error={getFormAlertMessage()} />
              {retryAfterDate && (
                <Box pb={3}>
                  <LoginAttemptsAlert throttlingRetryAfter={retryAfterDate} />
                </Box>
              )}
            </Box>
            <FormProvider {...formData}>
              <form
                onSubmit={formData.handleSubmit((values: ILoginForm) =>
                  dispatch(
                    actions.loginRequest({
                      email: values.email,
                      onUserIsInOry: async () => await loginOryUser(values),
                      password: values.password,
                    })
                  )
                )}
              >
                <AuthLoginForm isSubmitDisabled={!formData.formState.isValid || isOryApiFetching} />
              </form>
            </FormProvider>
          </Box>
          <Box sx={styles.sub}>
            <Typography variant="body1">
              <FormattedMessage {...messages.authLoginUnregisteredText} />
              <Link to={PATHS.REGISTER}>
                <FormattedMessage {...messages.authLoginUnregisteredAction} />
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
      <FooterPublic />
    </Box>
  );
}
