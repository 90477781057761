import Tab from '@mui/material/Tab';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { TabsContainer } from '../../components';
import { PATHS } from '../../config/consts';
import { useUrlParams } from '../../helpers/urlParams';
import useBreakpoints from '../../helpers/useBreakpoints';
import messages from './messages';

function setPath(path, apartmentId, companyId, siteId) {
  return path.replace(':apartmentId', apartmentId).replace(':companyId', companyId).replace(':siteId', siteId);
}

const ApartmentTabPanel = () => {
  const { apartmentId, companyId, siteId } = useUrlParams();
  const { downSm } = useBreakpoints();
  const { formatMessage } = useIntl();
  const location = useLocation();
  const tabItem = useMemo(
    () => ({
      access: setPath(PATHS.APARTMENT_ACCESS, apartmentId, companyId, siteId),
      detail: setPath(PATHS.APARTMENT_DETAIL, apartmentId, companyId, siteId),
      devices: setPath(PATHS.APARTMENT_DEVICES, apartmentId, companyId, siteId),
      users: setPath(PATHS.APARTMENT_USERS, apartmentId, companyId, siteId),
    }),
    [location]
  );

  const value = useMemo(
    () =>
      [tabItem.access, tabItem.detail, tabItem.devices, tabItem.users].includes(location?.pathname)
        ? location?.pathname
        : null,
    [location]
  );

  return (
    value && (
      <TabsContainer isXs={downSm} value={value}>
        <Tab
          component={Link}
          label={
            downSm ? formatMessage(messages.apartmentTabPanelDetailXs) : formatMessage(messages.apartmentTabPanelDetail)
          }
          to={tabItem.detail}
          value={tabItem.detail}
        />
        <Tab
          component={Link}
          label={formatMessage(messages.apartmentTabPanelDevices)}
          to={tabItem.devices}
          value={tabItem.devices}
        />
        <Tab
          component={Link}
          label={formatMessage(messages.apartmentTabPanelUsers)}
          to={tabItem.users}
          value={tabItem.users}
        />
        <Tab
          component={Link}
          label={formatMessage(messages.apartmentTabPanelAccess)}
          to={tabItem.access}
          value={tabItem.access}
        />
      </TabsContainer>
    )
  );
};

export default ApartmentTabPanel;
