import DoneIcon from '@mui/icons-material/Done';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { FormikFields, LoadingButton, Modal } from '../../components';
import actions from '../../modules/sites';
import messages from './messages';

const EditSiteModal = ({ isLoading, onClose, onSave, open, siteData }) => {
  const onSend = (values) => {
    onSave(values);
  };

  const getValidationSchema = () =>
    Yup.object().shape({
      name: Yup.string()
        .max(255, <FormattedMessage {...messages.addSiteModalVerificationMaxLength} />)
        .required(<FormattedMessage {...messages.addSiteModalVerificationRequired} />),
    });

  const getActions = (isValid, handleSubmit) => (
    <>
      <Button color="primary" onClick={onClose}>
        <FormattedMessage {...messages.editSiteModalCancel} />
      </Button>
      <LoadingButton
        color="primary"
        disabled={!isValid}
        id="submit-button"
        isLoading={isLoading}
        onClick={handleSubmit}
        startIcon={<DoneIcon />}
        type="submit"
        variant="contained"
      >
        <FormattedMessage {...messages.editSiteModalSubmit} />
      </LoadingButton>
    </>
  );

  if (!open) {
    return null;
  }

  return (
    open && (
      <Formik
        enableReinitialize
        initialValues={siteData}
        onSubmit={onSend}
        render={({ handleSubmit, isValid }) => (
          <Form autoComplete="off" noValidate>
            <Modal
              actions={getActions(isValid, handleSubmit)}
              maxWidth="xs"
              name="edit-site-modal"
              onClose={onClose}
              open={open}
              title={<FormattedMessage {...messages.editSiteModalTitle} />}
            >
              <Typography sx={{ mb: 3 }}>
                <FormattedMessage {...messages.editSiteModalDescription} />
              </Typography>
              <FormikFields.Input
                helperText={<FormattedMessage {...messages.editSiteModalHelperTextLabel} />}
                id="name-input"
                label={<FormattedMessage {...messages.editSiteModalLabel} />}
                margin="dense"
                name="name"
                required
                type="text"
                variant="outlined"
              />
            </Modal>
          </Form>
        )}
        validationSchema={getValidationSchema()}
      />
    )
  );
};

EditSiteModal.propTypes = {
  isLoading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool,
  siteData: PropTypes.shape({
    name: PropTypes.string,
  }),
};

EditSiteModal.defaultProps = {
  isLoading: false,
  open: false,
  siteData: {
    name: '',
  },
};

const mapStateToProps = ({ sites }) => ({
  isLoading: sites.editModal.isFetching,
  open: sites.editModal.show,
  siteData: sites.editModal.values,
});

export default connect(mapStateToProps, {
  onClose: actions.editSiteHideModal,
  onSave: actions.editSiteFormRequest,
})(EditSiteModal);
