import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Title } from '../../components';
import DeleteApartment from './DeleteApartment';
import messages from './messages';

const styles = {
  heading: (theme) => ({
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  }),
};

const ApartmentHeader = ({ apartment, companyId, onOpenDeleteApartmentModal, siteId }) => (
  <Title
    heading={
      <Box sx={styles.heading}>
        <Box sx={{ mr: 2 }}>
          <strong>{apartment.number}</strong>
          {apartment.name ? `—${apartment.name}` : ''}
        </Box>
        {apartment.floor?.name && (
          <Chip
            color="primary"
            label={
              <FormattedMessage values={{ floorName: apartment?.floor?.name }} {...messages.apartmentHeaderFloor} />
            }
            size="small"
            variant="outlined"
          />
        )}
      </Box>
    }
    rightSideActions={
      <DeleteApartment
        apartment={apartment}
        companyId={companyId}
        onOpenDeleteApartmentModal={onOpenDeleteApartmentModal}
        siteId={siteId}
      />
    }
  />
);

ApartmentHeader.propTypes = {
  apartment: PropTypes.shape({
    floor: PropTypes.shape({
      externalId: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    id: PropTypes.number,
    name: PropTypes.string,
    number: PropTypes.string,
  }).isRequired,
  companyId: PropTypes.number.isRequired,
  onOpenDeleteApartmentModal: PropTypes.func.isRequired,
  siteId: PropTypes.number.isRequired,
};

export default ApartmentHeader;
