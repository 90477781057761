import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from '../../components';
import ButtonDanger from '../../components/ButtonDanger';
import messages from './messages';

const ResetSettingsDialog = ({ onClose, onConfirm, show }) => (
  <Modal
    actions={
      <div>
        <Button color="inherit" onClick={onClose}>
          <FormattedMessage {...messages.resetSettingsDialogCancel} />
        </Button>
        <ButtonDanger onClick={onConfirm} startIcon={<DeleteIcon />}>
          <FormattedMessage {...messages.resetSettingsDialogConfirm} />
        </ButtonDanger>
      </div>
    }
    headerVariant="danger"
    maxWidth="xs"
    name="reset-settings-dialog"
    onClose={onClose}
    open={show}
    title={<FormattedMessage {...messages.resetSettingsDialogTitle} />}
  >
    <Typography>
      <FormattedMessage {...messages.resetSettingsDialogContent} />
    </Typography>
  </Modal>
);

ResetSettingsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default ResetSettingsDialog;
