import FolderSharedIcon from '@mui/icons-material/FolderShared';
import { Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PATHS, ROLES } from '../../config/consts';
import { SERVICE } from '../../config/devices';
import featureFlags from '../../config/featureFlags';
import { SITE_TYPE } from '../../config/sites';
import { BILLING_TYPE } from '../../config/subscription';
import { useDataGrid } from '../../helpers/dataGrid';
import useBreakpoints from '../../helpers/useBreakpoints';
import { useModal } from '../../hooks/useModal';
import { isFetchingMessages } from '../../modules/localization/selectors';
import ApartmentAccessGroupsModal from '../ApartmentAccessGroupsModal';
import ApartmentDeleteConfirmModal from '../ApartmentDeleteConfirmModal';
import AvailableCreditDialog from '../AvailableCreditModal';
import DataGrid from '../DataGrid';
import NameColumn from '../DataGrid/NameColumn';
import RowMenu from '../DataGrid/RowMenu';
import { useHasFeature } from '../Feature/Feature';
import AccessIcon from './AccessIcon/AccessIcon';
import ActionButton from './ActionButton';
import ApartmentRowMenu from './ApartmentRowMenu';
import DevicesColumn from './DevicesColumn';
import { getDevicesCountColumnWidth } from './DevicesColumn/columnWidth';
import messages from './messages';
import PaidServicesSwitch from './PaidServicesSwitch';
import ActivatePaidServicesDialog from './PaidServicesSwitch/ActivatePaidServicesModal';
import PurchaseButton from './PurchaseButton';
import Status from './Status';

const ApartmentDataGrid = ({
  companyId,
  data,
  didInvalid,
  filter,
  floors,
  isFetching,
  onLoadApartments,
  order,
  page,
  resultsFiltered,
  resultsTotal,
  rowsPerPage,
  site,
}) => {
  const { downSm, upLg, upMd, upSm } = useBreakpoints();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { onOrderBy, onRowsPerPageChange, onSelectPage } = useDataGrid(
    onLoadApartments,
    {
      companyId,
      filter,
      order,
      page,
      rowsPerPage,
      siteId: site?.id,
    },
    didInvalid,
    isFetching
  );

  const { Modal: AvailableCreditModal, onOpen: onOpenAvailableCreditModal } = useModal({
    Modal: AvailableCreditDialog,
  });
  const { Modal: ActivatePaidServicesModal, onOpen: onOpenActivatePaidServicesModal } = useModal({
    Modal: ActivatePaidServicesDialog,
  });

  const isLocalizationLoaded = useSelector(isFetchingMessages);
  const deviceCountColumnSize = getDevicesCountColumnWidth({
    isMDUSite: site?.type === SITE_TYPE.MDU,
    limit: site?.apartmentDeviceLimit,
  });

  const isPaidServicesApartmentFFEnabled = useHasFeature(featureFlags.PAID_SERVICES_APARTMENT);
  const hasPerApartmentBillingType =
    site?.services?.[SERVICE.MOBILE_VIDEO]?.licenceModel.billingType === BILLING_TYPE.PER_APARTMENT;
  const [apartmentValue, setApartmentValue] = useState({});

  return (
    <>
      <DataGrid
        columns={useMemo(
          () => [
            {
              disableColumnMenu: true,
              field: 'name',
              flex: 2,
              headerName: formatMessage(messages.ApartmentDataGridColumnsApartmentNameDesktop),
              renderCell: (cell) =>
                isFetching ? (
                  <Skeleton animation="wave" height={25} width="80%" />
                ) : (
                  <NameColumn
                    iconComponent={<FolderSharedIcon />}
                    name={cell.value.name ? `${cell.value.number} — ${cell.value.name}` : cell.value.number}
                  />
                ),
              sortable: true,
            },
            {
              disableColumnMenu: true,
              field: 'floor',
              flex: 1,
              headerName: formatMessage(messages.ApartmentDataGridColumnsFloor),
              minWidth: 85,
              renderCell: (cell) =>
                isFetching ? <Skeleton animation="wave" height={25} width="80%" /> : cell.value?.name,
              sortable: true,
            },
            {
              disableColumnMenu: true,
              field: 'access',
              flex: 1,
              headerName: formatMessage(messages.ApartmentDataGridColumnsAccess),
              renderCell: (cell) =>
                isFetching ? (
                  <Skeleton animation="wave" height={25} width="80%" />
                ) : (
                  <AccessIcon status={cell.value?.services?.[SERVICE.ACCESS_CONTROL]?.status} />
                ),
              sortable: false,
            },
            {
              disableColumnMenu: true,
              field: 'devicesCount',
              flex: deviceCountColumnSize ? undefined : 1,
              headerName: formatMessage(messages.ApartmentDataGridColumnsCountDevices),
              renderCell: (cell) =>
                isFetching ? (
                  <Skeleton animation="wave" height={25} width="80%" />
                ) : (
                  <DevicesColumn
                    apartmentDeviceLimit={cell.row.site?.apartmentDeviceLimit}
                    devicesCount={cell.value?.devicesCount || 0}
                    isMDU={cell.row.site?.type === SITE_TYPE.MDU}
                    usageDevices={cell.value?.services?.[SERVICE.MOBILE_VIDEO]?.requirePaymentCount || 0}
                  />
                ),
              sortable: false,
              width: deviceCountColumnSize ? deviceCountColumnSize : undefined,
            },
            {
              disableColumnMenu: true,
              field: 'usersCount',
              flex: 1,
              headerName: formatMessage(messages.ApartmentDataGridColumnsUsersCount),
              renderCell: (cell) =>
                isFetching ? <Skeleton animation="wave" height={25} width="80%" /> : cell.value || 0,
              sortable: false,
            },
            ...(isPaidServicesApartmentFFEnabled && hasPerApartmentBillingType
              ? [
                  {
                    disableColumnMenu: true,
                    field: 'paidServicesActive',
                    flex: 1,
                    headerName: formatMessage(messages.ApartmentDataGridColumnsPaidServices),
                    minWidth: 110,
                    renderCell: (cell) =>
                      isFetching ? (
                        <Skeleton animation="wave" height={25} width="80%" />
                      ) : (
                        <PaidServicesSwitch
                          licenceModel={site?.services?.[SERVICE.MOBILE_VIDEO]?.licenceModel}
                          companyId={companyId}
                          floors={floors}
                          siteId={site.id}
                          apartmentValue={cell.value}
                          setApartmentValue={setApartmentValue}
                          onOpenActivatePaidServicesModal={onOpenActivatePaidServicesModal}
                        />
                      ),
                    sortable: false,
                  },
                ]
              : []),
            {
              disableColumnMenu: true,
              field: 'status',
              flex: 2,
              headerName: formatMessage(
                downSm
                  ? messages.ApartmentDataGridColumnsApartmentStatusMobile
                  : messages.ApartmentDataGridColumnsApartmentStatus
              ),
              renderCell: (cell) =>
                isFetching ? <Skeleton animation="wave" height={25} width="80%" /> : <Status status={cell.value} />,
              sortable: false,
            },
            {
              align: 'right',
              disableColumnMenu: true,
              field: 'buttons',
              flex: 2,
              headerName: '',
              renderCell: (cell) =>
                cell.row?.site?.type === SITE_TYPE.MDU &&
                (isFetching ? (
                  <Skeleton animation="wave" height={25} width="80%" />
                ) : isPaidServicesApartmentFFEnabled ? (
                  <PurchaseButton
                    companyId={companyId}
                    siteId={cell.row.site.id}
                    licenceModel={site?.services?.[SERVICE.MOBILE_VIDEO]?.licenceModel}
                    apartment={cell.value}
                  />
                ) : (
                  <ActionButton
                    apartment={cell.value}
                    companyId={cell.row.companyId}
                    licenceModel={site?.services?.[SERVICE.MOBILE_VIDEO]?.licenceModel}
                    onOpenAvailableCreditModal={onOpenAvailableCreditModal}
                    site={cell.row.site}
                  />
                )),
              sortable: false,
            },
            {
              disableColumnMenu: true,
              field: 'rowMenu',
              headerName: '',
              renderCell: (cell) =>
                isFetching ? (
                  <Skeleton animation="wave" height={25} width="80%" />
                ) : (
                  <RowMenu
                    menuItems={
                      <ApartmentRowMenu
                        apartment={cell.value}
                        companyId={cell.row.companyId}
                        licenceModel={site?.services?.[SERVICE.MOBILE_VIDEO]?.licenceModel}
                        onOpenAvailableCreditModal={onOpenAvailableCreditModal}
                        showActionButton={cell.row.site?.type === SITE_TYPE.MDU && !upLg}
                        siteId={cell.row.site?.id}
                      />
                    }
                  />
                ),
              sortable: false,
              width: 65,
            },
          ],
          [isFetching, upSm, upMd, upLg, isLocalizationLoaded, site]
        )}
        columnVisibilityModel={{
          access: upMd,
          buttons: upLg,
          devicesCount: upSm && upMd,
          floor: upSm,
          paidServicesActive: upSm,
          usersCount: upLg,
        }}
        disableRowSelectionOnClick
        hideFooterPagination={isFetching}
        loading={isFetching}
        onCellClick={(params) => {
          if (params.field !== 'rowMenu' && params.field !== 'paidServicesActive' && params.field !== 'buttons') {
            navigate(
              PATHS.APARTMENT_DETAIL.replace(':companyId', params?.row?.companyId)
                .replace(':siteId', params?.row?.site?.id)
                .replace(':apartmentId', params?.row?.id)
            );
          }
        }}
        onOrderBy={onOrderBy}
        onRowsPerPageChange={onRowsPerPageChange}
        onSelectPage={onSelectPage}
        order={order}
        page={page}
        resultsFiltered={resultsFiltered}
        resultsTotal={resultsTotal}
        rows={useMemo(
          () =>
            data.map((item) => ({
              access: item,
              buttons: item,
              companyId,
              devicesCount: item,
              floor: item.floor,
              id: item.id,
              isClickable: true,
              name: item,
              paidServicesActive: item,
              rowMenu: item,
              site,
              status: item.services?.[SERVICE.MOBILE_VIDEO]?.status,
              usersCount: item?.roles?.[ROLES.RESIDENT]?.length,
            })),
          [companyId, data, site]
        )}
        rowsPerPage={rowsPerPage}
      />
      <AvailableCreditModal unlicensedCount={site?.services?.[SERVICE.MOBILE_VIDEO]?.licenceModel?.unlicensedCount} />
      <ApartmentDeleteConfirmModal />
      <ApartmentAccessGroupsModal />
      <ActivatePaidServicesModal
        apartmentValue={apartmentValue}
        companyId={companyId}
        siteId={site.id}
        floors={floors}
      />
    </>
  );
};

ApartmentDataGrid.propTypes = {
  companyId: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  didInvalid: PropTypes.bool.isRequired,
  filter: PropTypes.shape({ fulltext: PropTypes.string }).isRequired,
  floors: PropTypes.arrayOf(PropTypes.object),
  isFetching: PropTypes.bool.isRequired,
  onLoadApartments: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  resultsFiltered: PropTypes.number.isRequired,
  resultsTotal: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  site: PropTypes.shape({
    apartmentDeviceLimit: PropTypes.number,
    company: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    services: PropTypes.shape({}),
    type: PropTypes.string,
  }).isRequired,
};

export default ApartmentDataGrid;
