import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ApartmentDeleteConfirmModal } from '../../components';
import messages from './messages';

const DeleteApartment = ({ apartment, onOpenDeleteApartmentModal }) => (
  <Grid item>
    <Box display={{ lg: 'block', xs: 'none' }}>
      <Button
        color="secondary"
        disabled={apartment.devicesCount !== 0}
        onClick={() => onOpenDeleteApartmentModal(apartment.id)}
        startIcon={<DeleteIcon />}
      >
        <FormattedMessage {...messages.deleteApartmentDelete} />
      </Button>
    </Box>
    <Box display={{ lg: 'none', xs: 'block' }}>
      <IconButton
        color="secondary"
        disabled={apartment.devicesCount !== 0}
        onClick={() => onOpenDeleteApartmentModal(apartment.id)}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
    <ApartmentDeleteConfirmModal />
  </Grid>
);

DeleteApartment.propTypes = {
  apartment: PropTypes.shape({
    devicesCount: PropTypes.number,
    id: PropTypes.number,
  }).isRequired,
  onOpenDeleteApartmentModal: PropTypes.func.isRequired,
};

export default DeleteApartment;
