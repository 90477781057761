import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PATHS } from '../../../config/consts';
import { SERVICE } from '../../../config/devices';
import ButtonLink from '../../ButtonLink';
import ApartmentDataGridAction from '../ApartmentDataGridAction';
import messages from './messages';

const ActionButton = ({ apartment, companyId, licenceModel, onOpenAvailableCreditModal, site }) => {
  const onClick = (event, clickFun = null) => {
    event.stopPropagation();
    if (clickFun) {
      clickFun();
    }
  };

  return (
    <ApartmentDataGridAction
      apartment={apartment}
      licenceModel={licenceModel}
      purchaseComponent={
        <ButtonLink
          color="primary"
          onClick={(event) => onClick(event)}
          to={PATHS.SUBSCRIPTION_MOBILE_VIDEO.replace(':companyId', companyId).replace(':siteId', site?.id)}
        >
          <FormattedMessage {...messages.actionButtonPurchase} />
        </ButtonLink>
      }
      useAvailableCreditComponent={
        <Button color="primary" onClick={(event) => onClick(event, onOpenAvailableCreditModal)}>
          <FormattedMessage {...messages.actionButtonUseAvailableCredit} />
        </Button>
      }
    />
  );
};

ActionButton.propTypes = {
  apartment: PropTypes.shape({
    services: PropTypes.shape({
      [SERVICE.MOBILE_VIDEO]: PropTypes.shape({
        status: PropTypes.string,
      }),
    }),
  }).isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  licenceModel: PropTypes.shape({
    additional: PropTypes.number,
    licencesPool: PropTypes.number,
    status: PropTypes.string,
    unlicensedCount: PropTypes.number,
  }).isRequired,
  onOpenAvailableCreditModal: PropTypes.func.isRequired,
  site: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.string,
  }).isRequired,
};

export default ActionButton;
