import moment from 'moment';
import messages from '../modules/localization/messages';
import { apartmentComposedIdName } from '../routes/composedIds/config';

export const FORM = {
  HAS_FORM: 'hasForm',
  NEW_ITEM_ID: 'newItemId',
  NEW_ITEM_WITHOUT_LOGIN_ID: 'newItemWithoutLoginId',
};
export const FE_ROLES = {
  APARTMENT_ADMIN: 'APARTMENT_ADMIN',
  APARTMENT_GUEST: 'APARTMENT_GUEST',
  COMPANY_ADMIN: 'COMPANY_ADMIN',
  GUEST: 'GUEST',
  LOGIN_LESS: 'LOGIN_LESS',
  SITE_ADMIN: 'SITE_ADMIN',
  SITE_ADMIN_PAYMENT: 'SITE_ADMIN_PAYMENT',
  SITE_USER: 'SITE_USER',
};

export const ROLES = {
  ADMIN: 'ADMIN',
  ANY: 'ANY', // site role (default)
  APARTMENT_ADMIN: 'APARTMENT_ADMIN',
  COMPANY_ADMIN: 'COMPANY_ADMIN', // user role
  COMPANY_CREATOR: 'COMPANY_CREATOR', // user role
  CUSTOMER_CARE: 'CUSTOMER_CARE', // company role (default)
  GREEN_ADMIN: 'GREEN_ADMIN', // company role
  IP_INTERCOM_BUYER: 'IP_INTERCOM_BUYER', // user role
  MARKETING: 'MARKETING', // company role
  MOBILE_VIDEO_BUYER: 'MOBILE_VIDEO_BUYER', // company role
  RESIDENT: 'RESIDENT', // user role
  STANDARD: 'STANDARD', // company role
  SUPERADMIN: 'SUPERADMIN', // user role
  get SUPERADMIN_ROLES() {
    return [this.SUPERADMIN, this.CUSTOMER_CARE];
  }, // site role
  USER: 'USER',
};

export const COMPANY_ROLES = {
  COMPANY_ADMIN: 'COMPANY_ADMIN',
  GREEN_ADMIN: 'GREEN_ADMIN',
  IP_INTERCOM_BUYER: 'IP_INTERCOM_BUYER',
  MOBILE_VIDEO_BUYER: 'MOBILE_VIDEO_BUYER',
  STANDARD: 'STANDARD',
};

export const GRANTED_ROLES = {
  [FE_ROLES.SITE_USER]: {
    companyRoles: [ROLES.STANDARD],
    siteRoles: [ROLES.USER],
  },
  [FE_ROLES.SITE_ADMIN]: {
    companyRoles: [ROLES.STANDARD],
    siteRoles: [ROLES.USER, ROLES.ADMIN],
  },
  [FE_ROLES.SITE_ADMIN_PAYMENT]: {
    companyRoles: [ROLES.STANDARD, ROLES.MOBILE_VIDEO_BUYER],
    siteRoles: [ROLES.USER, ROLES.ADMIN],
  },
  [FE_ROLES.COMPANY_ADMIN]: {
    companyRoles: [ROLES.STANDARD, ROLES.COMPANY_ADMIN],
    siteRoles: [],
  },
};

export const SITE_ROLES_MAP = {
  [FE_ROLES.SITE_USER]: {
    [FE_ROLES.SITE_ADMIN]: {
      grantedRoles: {
        companyRoles: [],
        siteRoles: [ROLES.USER, ROLES.ADMIN],
      },
      revokedRoles: {
        companyRoles: [],
        siteRoles: [],
      },
    },
    [FE_ROLES.SITE_ADMIN_PAYMENT]: {
      grantedRoles: {
        companyRoles: [ROLES.MOBILE_VIDEO_BUYER],
        siteRoles: [ROLES.USER, ROLES.ADMIN],
      },
      revokedRoles: {
        companyRoles: [],
        siteRoles: [],
      },
    },
  },
  [FE_ROLES.SITE_ADMIN]: {
    [FE_ROLES.SITE_USER]: {
      grantedRoles: {
        companyRoles: [],
        siteRoles: [ROLES.USER],
      },
      revokedRoles: {
        companyRoles: [],
        siteRoles: [ROLES.ADMIN],
      },
    },
    [FE_ROLES.SITE_ADMIN_PAYMENT]: {
      grantedRoles: {
        companyRoles: [ROLES.MOBILE_VIDEO_BUYER],
        siteRoles: [],
      },
      revokedRoles: {
        companyRoles: [],
        siteRoles: [],
      },
    },
  },
  [FE_ROLES.SITE_ADMIN_PAYMENT]: {
    [FE_ROLES.SITE_ADMIN]: {
      grantedRoles: {
        companyRoles: [],
        siteRoles: [],
      },
      revokedRoles: {
        companyRoles: [ROLES.MOBILE_VIDEO_BUYER],
        siteRoles: [],
      },
    },
    [FE_ROLES.SITE_USER]: {
      grantedRoles: {
        companyRoles: [],
        siteRoles: [ROLES.USER],
      },
      revokedRoles: {
        companyRoles: [ROLES.MOBILE_VIDEO_BUYER],
        siteRoles: [ROLES.ADMIN],
      },
    },
  },
};

export const DEFAULT_USER_LANGUAGE = 'en_US';

export const LANGUAGES = {
  czech: {
    abbreviation: 'CES',
    locale: 'cs_CZ',
    nonTranslated: 'Čeština',
    translated: messages.localizationLanguagesCzech,
  },
  // eslint-disable-next-line sort/object-properties
  german: {
    abbreviation: 'DEU',
    locale: 'de_DE',
    nonTranslated: 'Deutsch',
    translated: messages.localizationLanguagesGerman,
  },
  english: {
    abbreviation: 'ENG',
    locale: 'en_US',
    nonTranslated: 'English',
    translated: messages.localizationLanguagesEnglish,
  },
  spanish: {
    abbreviation: 'SPA',
    locale: 'es_ES',
    nonTranslated: 'Español',
    translated: messages.localizationLanguagesSpanish,
  },
  french: {
    abbreviation: 'FRA',
    locale: 'fr_FR',
    nonTranslated: 'Français',
    translated: messages.localizationLanguagesFrench,
  },
  italian: {
    abbreviation: 'ITA',
    locale: 'it_IT',
    nonTranslated: 'Italiano',
    translated: messages.localizationLanguagesItalian,
  },
  dutch: {
    abbreviation: 'NLD',
    locale: 'nl_NL',
    nonTranslated: 'Nederlands',
    translated: messages.localizationLanguagesDutch,
  },
  finnish: {
    abbreviation: 'FIN',
    locale: 'fi_FI',
    nonTranslated: 'Suomi',
    translated: messages.localizationLanguagesFinnish,
  },
};

export const MOBILE_VIDEO_STATUS = {
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
  ENABLED: 'ENABLED',
  EXPIRED: 'EXPIRED',
  INITIAL: 'INITIAL',
  STANDARD: 'STANDARD',
  STANDARD_DEACTIVATED: 'STANDARD_DEACTIVATED',
  STANDARD_EXPIRED: 'STANDARD_EXPIRED',
  STANDARD_LICENCE_SHORTAGE: 'STANDARD_LICENCE_SHORTAGE',
  STANDARD_RENEWAL: 'STANDARD_RENEWAL',
  TEMPORARY: 'TEMPORARY',
  TRIAL_ACTIVE: 'TRIAL_ACTIVE',
  TRIAL_EXPIRED: 'TRIAL_EXPIRED',
};

export const MOBILE_VIDEO_TYPE = {
  ACTIVABLE: 'ACTIVABLE',
  EXPIRABLE: 'EXPIRABLE',
  STANDARD: 'STANDARD',
};

export const MOBILE_VIDEO_PRODUCTS_DAYS = {
  MOBILE_VIDEO_MONTH: 30,
  MOBILE_VIDEO_YEAR: 365,
};

export const APARTMENT_STATUS = {
  ACTIVE_FOR_FREE: 'ACTIVE_FOR_FREE',
  ACTIVE_SUBSCRIPTIONS: 'ACTIVE_SUBSCRIPTIONS',
  NO_SERVICE: 'NO_SERVICE',
  OTHER: 'OTHER',
  UNLICENSED: 'UNLICENSED',
};

export const CURRENCY = {
  EUR: 'EUR',
  USD: 'USD',
};

export const PRICING = {
  PRICE_PER_APARTMENT: 'PRICE_PER_APARTMENT',
  PRICE_PER_DEVICE: 'PRICE_PER_DEVICE',
};

export const PRODUCT = {
  IP_INTERCOM: 'IP_INTERCOM',
  MOBILE_VIDEO: 'MOBILE_VIDEO',
};

export const MOBILE_APP_LINKS = {
  APP_STORE_LINK: 'https://apps.apple.com/cz/app/2n-mobile-video/id1188403431',
  GOOGLE_PLAY_LINK: 'https://play.google.com/store/apps/details?id=com.nn.my2ncommunicator',
};

export const PATHS = {
  ABOUT: '/companies/:companyId/about',
  ABOUT_SITE: '/companies/:companyId/sites/:siteId/about',
  ACCESS_LOG: '/companies/:companyId/sites/:siteId/access-log',

  ACCESS_SETTINGS: '/companies/:companyId/sites/:siteId/access-settings',
  ACCESS_SETTINGS_APARTMENTS: '/companies/:companyId/sites/:siteId/access-settings/:groupId/apartments',
  ACCESS_SETTINGS_DETAIL: '/companies/:companyId/sites/:siteId/access-settings/:groupId/detail',
  ACCESS_SETTINGS_DEVICES: '/companies/:companyId/sites/:siteId/access-settings/:groupId/devices',
  ACCESS_SETTINGS_GROUP: '/companies/:companyId/sites/:siteId/access-settings/:groupId',
  ACCESS_SETTINGS_USERS: '/companies/:companyId/sites/:siteId/access-settings/:groupId/users',
  ADMIN_DEVICE_DETAIL: '/companies/admin/:userId/devices/:deviceId',

  APARTMENT: '/companies/:companyId/sites/:siteId/apartments/:apartmentId',
  APARTMENT_ACCESS: '/companies/:companyId/sites/:siteId/apartments/:apartmentId/access',
  APARTMENT_DETAIL: '/companies/:companyId/sites/:siteId/apartments/:apartmentId/detail',
  APARTMENT_DEVICES: '/companies/:companyId/sites/:siteId/apartments/:apartmentId/devices',
  APARTMENT_USERS: '/companies/:companyId/sites/:siteId/apartments/:apartmentId/users',

  APARTMENTS_OVERVIEW: '/companies/:companyId/sites/:siteId/apartments',
  BILLING_INFO: '/companies/:companyId/billing-info',
  CALL_SETTINGS: '/companies/:companyId/sites/:siteId/call-settings',

  CALL_SETTINGS_ANSWERING_UNITS: '/companies/:companyId/sites/:siteId/call-settings/answering-units',
  CALL_SETTINGS_INTERCOMS: '/companies/:companyId/sites/:siteId/call-settings/intercoms',

  CALL_SETTINGS_PHONEBOOKS: '/companies/:companyId/sites/:siteId/call-settings/phonebooks',
  CLOUD_AGREEMENT: '/legal/cloud-agreement',
  CLOUD_AGREEMENT_V1: '/legal/cloud-agreement/v1',

  CLOUD_AGREEMENT_WITH_COMPANY: '/companies/:companyId/legal/cloud-agreement',
  COMPANY_ADMIN: '/companies/:companyId/admins',
  COMPANY_ADMIN_DETAIL: '/companies/:companyId/admins/:userId',

  COMPANY_DASHBOARD: '/companies/:companyId',
  COMPANY_SITES: '/companies/:companyId/sites',

  COOKIE_POLICY: '/legal/cookie-policy',
  COOKIE_POLICY_V1: '/legal/cookie-policy/v1',

  COOKIE_POLICY_V2: '/legal/cookie-policy/v2',
  DASHBOARD: '/dashboard',

  DATA_PROCESSING_TERMS: '/legal/data-processing-terms',
  DATA_PROCESSING_TERMS_V1: '/legal/data-processing-terms/v1',
  DEVICE: '/companies/:companyId/sites/:siteId/devices/:deviceId',

  DEVICE_ACCESS_SETTINGS: '/companies/:companyId/sites/:siteId/devices/:deviceId/access-settings',
  DEVICE_CALL_SETTINGS: '/companies/:companyId/sites/:siteId/devices/:deviceId/call-settings',
  DEVICE_DETAIL: '/companies/:companyId/sites/:siteId/devices/:deviceId/detail',

  DEVICE_PHONEBOOKS: '/companies/:companyId/sites/:siteId/devices/:deviceId/phonebooks',
  DEVICE_SWITCH_CODES: '/companies/:companyId/sites/:siteId/devices/:deviceId/switch-codes',
  DEVICE_UNLOCK_SETTINGS: '/companies/:companyId/sites/:siteId/devices/:deviceId/unlock-settings',
  DEVICES_OVERVIEW: '/companies/:companyId/sites/:siteId/devices',
  ERROR: '/error',

  GTC_PAGE_COMPANY_CONTEXT_DETAIL: '/companies/:companyId/legal/:slug/:version',
  GTC_PAGE_COMPANY_CONTEXT_DETAIL_BASIC: '/companies/:companyId/legal/:slug',

  GTC_PAGE_DETAIL: '/legal/:slug/:version',
  GTC_PAGE_DETAIL_BASIC: '/legal/:slug',
  GTC_PAGES: '/legal',
  GTC_PAGES_COMPANY_CONTEXT: '/companies/:companyId/legal',

  HOMEPAGE: '/',
  INFORMATION_FOR_CONSUMERS: '/legal/information-for-consumers',
  INFORMATION_FOR_CONSUMERS_V1: '/legal/information-for-consumers/v1',
  INFORMATION_FOR_CONSUMERS_WITH_COMPANY: '/companies/:companyId/legal/information-for-consumers',

  INTEGRATOR_BUSINESS_TERMS: '/legal/integrator-business-terms',
  INTEGRATOR_BUSINESS_TERMS_V1: '/legal/integrator-business-terms/v1',
  LANDING_MOBILE_VIDEO: '/2n-mobile-video',
  LANDING_MY2N_APP: '/my2n-app',
  LANDING_REMOTE_CONFIGURATION: '/2n-remote-configuration',
  LOGIN: '/login',
  LOGOUT: '/logout',
  MARKETER_ACTIVATION_CODES: '/marketer/activation-codes',
  MV_SDK_SETTINGS: '/companies/:companyId/mv-sdk-settings',

  NON_ADMIN_ABOUT: '/about',
  NON_ADMIN_SUPPORT: '/help',

  NOT_VERIFIED_DEVICES: '/companies/:companyId/sites/:siteId/not-verified-devices',

  ORDER_FORM: '/legal/order-form',
  ORDER_FORM_V1: '/legal/order-form/v1',
  OTHER_PROCESSORS: '/legal/other-processors',
  OTHER_PROCESSORS_V1: '/legal/other-processors/v1',

  PASSWORD_RESET: '/reset-password',
  PAYMENTS_OVERVIEW: '/companies/:companyId/payments-overview',
  PHONEBOOK_DETAIL: '/companies/:companyId/sites/:siteId/phonebook/:phonebookId',
  PRIVACY_NOTICE: '/legal/privacy-notice',
  PRIVACY_NOTICE_V1: '/legal/privacy-notice/v1',

  PRIVACY_NOTICE_V2: '/legal/privacy-notice/v2',
  REGISTER: '/register',
  RESEND: '/login/resend',
  SITE_DASHBOARD: '/companies/:companyId/sites/:siteId',

  SITE_PAYMENTS: '/companies/:companyId/sites/:siteId/payments',
  SITE_SETTINGS: '/companies/:companyId/sites/:siteId/site-settings',
  SITE_SETTINGS_ACCESS: '/companies/:companyId/sites/:siteId/site-settings/access',
  SITE_SETTINGS_ELEVATOR_INTEGRATION: '/companies/:companyId/sites/:siteId/site-settings/elevator-integration',
  SITE_SETTINGS_SITE_LANGUAGE: '/companies/:companyId/sites/:siteId/site-settings/site-language',
  SITE_USER: '/companies/:companyId/sites/:siteId/users/:userId',
  SITE_USER_ACCESS: '/companies/:companyId/sites/:siteId/users/:userId/access',
  SITE_USER_DETAIL: '/companies/:companyId/sites/:siteId/users/:userId/detail',
  SITE_USER_DEVICES: '/companies/:companyId/sites/:siteId/users/:userId/devices',

  SITE_USERS: '/companies/:companyId/sites/:siteId/users',
  SITE_USERS_IMPORT: '/companies/:companyId/sites/:siteId/users/import',
  SUBSCRIPTION: '/companies/:companyId/sites/:siteId/subscription',
  SUBSCRIPTION_ACTIVATION_CODE: '/companies/:companyId/sites/:siteId/subscription/activation-code',
  SUBSCRIPTION_LICENSES: '/companies/:companyId/sites/:siteId/subscription/licenses',

  SUBSCRIPTION_MOBILE_VIDEO: '/companies/:companyId/sites/:siteId/subscription/mobile-video',
  SUBSCRIPTION_VOUCHERS: '/companies/:companyId/sites/:siteId/subscription/vouchers',
  SUPERADMIN_ACTIVATION_CODES: '/admin/activation-codes',

  SUPERADMIN_COMPANIES: '/admin/companies',
  SUPERADMIN_COMPANY_ADMIN_DETAIL: '/admin/companies/admins/:id',
  SUPERADMIN_COMPANY_ADMINS: '/admin/companies/admins',
  SUPERADMIN_COMPANY_BILLING_INFO: '/admin/companies/billing-info',

  SUPERADMIN_COMPANY_PAYMENTS: '/admin/companies/payments',

  SUPERADMIN_COMPANY_SETTINGS: '/admin/companies/:companyId/company-settings',
  // SUPERADMIN_SEARCH: '/admin/search',
  SUPERADMIN_COMPANY_SITES: '/admin/companies/:companyId/sites',
  SUPERADMIN_DASHBOARD: '/admin/dashboard',
  SUPERADMIN_DEBUG_TOOLS: '/admin/debug-tools',
  SUPERADMIN_MOBILE_VIDEO_DASHBOARD: '/admin/mobile-video/overview',
  SUPERADMIN_PAYMENTS: '/admin/payments',
  SUPERADMIN_REGISTRATIONS: '/admin/registrations',
  SUPERADMIN_REMOTE_CONFIGURATION: '/admin/remote-configuration',
  SUPERADMIN_SITE_DASHBOARD: '/admin/site/:id/dashboard',
  SUPERADMIN_SITE_PAYMENTS: '/admin/site/:id/payments',
  SUPERADMIN_SITE_USER_DETAIL: '/admin/site/:id/users/:userId',
  SUPERADMIN_SITE_USERS: '/admin/site/:id/users',
  SUPPORT: '/companies/:companyId/help',
  SUPPORT_SITE: '/companies/:companyId/sites/:siteId/help',
  SUSPICIOUS_LOGIN: '/unsubscribe-suspicious-login/:token',
  TERMS_OF_SERVICE: '/legal/terms-of-service',
  TERMS_OF_SERVICE_V1: '/legal/terms-of-service/v1',
  TERMS_OF_SERVICE_V2: '/legal/terms-of-service/v2',
  USER_DEVICE_DETAIL: '/profile/devices/:deviceId',
  USER_PROFILE: '/profile',

  USERS_IN_APARTMENTS: '/my-apartment',
  USERS_IN_APARTMENTS_DETAIL: `/my-apartment/:${apartmentComposedIdName}/user-detail/:userId`,
};

export const CALL_SETTINGS = {
  COMPACT: {
    DEFAULT_CONTACT_NAME: 'Contact',
    DEFAULT_LIST_COUNT: 6,
    DEFAULT_OBJECT: {
      index: null,
      name: '',
      selectedDevices: [],
    },
    LIST_TYPE: 'contacts',
    TYPE: 'COMPACT',
  },
  ID_TALK: {
    DEFAULT_LIST_COUNT: 2,
    DEFAULT_OBJECT: {
      index: null,
      name: '',
      selectedDevices: [],
    },
    TYPE: 'ID_TALK',
  },
  INTERCOM: {
    DEFAULT_CONTACT_NAME: 'Button',
    DEFAULT_LIST_COUNT: 6,
    DEFAULT_OBJECT: {
      button: null,
      contact: null,
      display: null,
      index: null,
      name: '',
      selectedDevices: [],
    },
    LIST_TYPE: 'buttons',
    TYPE: 'INTERCOM',
  },
};

export const MOBILE_VIDEO_CODE = {
  CREDIT: {
    EMPTY: 'EMPTY',
    POOL: 'POOL',
    REMAINING: 'REMAINING',
  },
  EXPIRATION: {
    NEXT_PAYMENT: 'NEXT_PAYMENT',
    POOL_EXPIRATION: 'POOL_EXPIRATION',
    VALIDITY: 'VALIDITY',
  },
  STATE: {
    NOT_FOUND: {
      CODE: 'ER05',
    },
    USED: 'USED',
  },
};

export const PASSWORD_REGEXP = /^(?=.*?\p{Lu})(?=.*?\p{Ll})(?=.*?[0-9]).{8,}$/u;
export const PASSWORD_REGEXP_LOWERCASE = /\p{Ll}/u;
export const PASSWORD_REGEXP_DIGITS = /[0-9]+/;
export const PASSWORD_REGEXP_UPPERCASE = /\p{Lu}/u;
export const PASSWORD_REGEXP_LENGTH = /.{8,}/;

export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const MULTIPLE_EMAIL_SEPARATOR = ',';
export const DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'Z'";

export const COOKIES = {
  ALERTBOX: 'ALERTBOX',
  CONSENT: 'consent_panel',
  FUNCTIONAL: 'FUNCTIONAL',
  HIPMO_APARTMENT: 'HIPMO_APARTMENT',
  MARKETING: 'MARKETING',
  SYSTEM: 'SYSTEM',
};

export const FUNCTIONAL_COOKIES = {
  INFO_PANEL: 'my2n_info_panel_',
};

export const MARKETING_COOKIES = {};

export const COOKIE_OPTIONS = {
  domain: process.env.COOKIE_DOMAIN !== 'localhost' ? `.${process.env.COOKIE_DOMAIN}` : '',
  expires: moment().add(2, 'year').toDate(),
  path: '/',
  secure: true,
};

export const USERS_CREDENTIALS = {
  BT: 'BT',
  PIN: 'PIN',
  RFID: 'RFID',
};

export const IMPORT_USERS_STATES = {
  ABORTED: 'ABORTED',
  FAILURE: 'FAILURE',
  PROCESSING: 'PROCESSING',
  SUCCESS: 'SUCCESS',
};

export const IMPORT_USERS_ROW_RESULT = {
  EMPTY: 'EMPTY',
  OK: 'OK',
  UNKNOWN: 'UNKNOWN',
  WRONG_FORMAT: 'WRONG_FORMAT',
};

export const IMPORT_USERS_ALERT_COOKIE = 'import_users_alert';
export const IMPORT_USERS_LIMIT = 500;

export const IMPORT_USERS_FAILED_ROW_RESULT = {
  ABORTED: 'ABORTED',
  EMPTY: 'EMPTY',
  INTERNAL_ERROR: 'INTERNAL_ERROR',
  OK: 'OK',
  UNKNOWN: 'UNKNOWN',
  WRONG_FORMAT: 'WRONG_FORMAT',
};

export const ACCESS_LOG_RESULT = {
  DENIED: 'DENIED',
  GRANTED: 'GRANTED',
  LOCKOUT: 'LOCKOUT',
  NOT_DETERMINATE: 'NOT_DETERMINATE',
  PUBLIC: 'PUBLIC',
  UNAUTHORIZED: 'UNAUTHORIZED',
};

export const ACCESS_LOG_FAILED_RESULTS = [
  ACCESS_LOG_RESULT.UNAUTHORIZED,
  ACCESS_LOG_RESULT.NOT_DETERMINATE,
  ACCESS_LOG_RESULT.DENIED,
  ACCESS_LOG_RESULT.LOCKOUT,
];

export const ACCESS_LOG_CREDENTIAL_TYPE = {
  CARD: 'CARD',
  CODE: 'CODE',
  FINGER_PRINT: 'FINGER_PRINT',
  LICENSE_PLATE: 'LICENSE_PLATE',
  MOBILE_KEY: 'MOBILE_KEY',
  QR_CODE: 'QR_CODE',
  REMOTE: 'REMOTE',
  REX: 'REX',
};

export const QR_CODE_READER_ERROR = {
  ALLOW_CAMERA: 'ALLOW_CAMERA',
  INVALID_QR_CODE: 'INVALID_QR_CODE',
  PERMISSION_DENIED: 'PERMISSION_DENIED',
  TIMEOUT: 'TIMEOUT',
};

export const QR_CODE_READER_PERMISSION_DENIED = /Permission denied/;

export const QR_CODE_READER_TIMEOUTS = {
  ALLOW_CAMERA: 2 * 1000,
  USER_INACTIVE: 20 * 1000,
};

export const AVQ_SUPPORT = '2.36';

export const BUILDING_ELEVATOR_GROUP = 'buildingElevatorGroup';
export const AREA_ID = 'areaId';
export const WALKING_TIME = 'walkingTime';
export const DEFAULT_DEVICE_STOP = {
  areaId: '',
  buildingId: '',
  elevatorGroupId: '',
  walkingTime: 5,
};

export const DEFAULT_DEVICE_DTO = {
  destinationCallEnabled: false,
  integrationActive: false,
  landingCallEnabled: false,
  stops: [],
};
