import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ElevatorGroupSelectItem } from '../FloorConfiguration';
import messages from './messages';

export interface IElevatorGroupSelect extends SelectProps {
  floorElevatorGroups: Array<ElevatorGroupSelectItem>;
  buildingId: string;
  elevatorGroupId: string;
}

export function ElevatorGroupSelect({
  buildingId,
  elevatorGroupId,
  floorElevatorGroups,
  ...other
}: IElevatorGroupSelect) {
  return (
    <FormControl size="small" style={{ width: '100%' }}>
      <Select {...other} defaultValue={elevatorGroupId}>
        <MenuItem value="null">
          <Typography color="secondary">
            <FormattedMessage {...messages.elevatorIntegrationMappingElevatorCoreNull} />
          </Typography>
        </MenuItem>

        {floorElevatorGroups.map((floorElevatorGroup, index) => (
          <MenuItem key={index} value={floorElevatorGroup.id}>
            <FormattedMessage
              {...messages.elevatorGroupSelectValue}
              values={{
                buildingId: floorElevatorGroup.buildingId,
                groupName: floorElevatorGroup.elevatorGroup?.name,
              }}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
