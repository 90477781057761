import { IntlFormatters } from 'react-intl';
import * as yup from 'yup';

export function getElevatorIntegrationValidationSchema(_formatMessage: IntlFormatters['formatMessage']) {
  return yup.object().shape({
    destinationCallEnabled: yup.bool().required(),
    elevatorIntegrationEnabled: yup.bool().required(),
    landingCallEnabled: yup.bool().required(),
  });
}
