import { Label, LocationOn, Person } from '@mui/icons-material';
import { Alert, Divider, Stack } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useGetApartmentDetailForAaQuery } from '../../../../../apiRtk/be4feApi';
import DevicesIcon from '../../../../../assets/devices.svg';
import InfoModal from '../../../../../components/_DesignSystemWrappers/Modal/InfoModal';
import { IApartmentIdsInfo } from '../../../../../modules/apartments/store';
import { getApartmentContactLabel, getApartmentNumberAndName } from '../../../../../modules/apartments/utils';
import { AccessGroupList } from '../../../ApartmentAdminUserDetailPage/components/UserAccessSettings/components/AccessGroupList';
import { AccessGroupItem } from './components/AccessGroupItem/AccessGroupItem';
import { Item } from './components/Item/Item';
import { MobileVideoDevicesLicenseIndicator } from './components/MobileVideoDevicesColumn/MobileVideoDevicesLicenseIndicator';
import { MobileVideoItem } from './components/MobileVideoItem/MobileVideoItem';
import { StyledIcon } from './components/StyledIcon/StyledIcon';
import messages from './messages';

const BoxLimit = 8;

interface IApartmentAdminApartmentDetailModal {
  apartmentIdsInfo: IApartmentIdsInfo;
  onClose: () => void;
  open: boolean;
}

function ApartmentAdminApartmentDetailModal({ apartmentIdsInfo, onClose, open }: IApartmentAdminApartmentDetailModal) {
  const { formatMessage } = useIntl();
  const apartmentData = useGetApartmentDetailForAaQuery({
    apartmentId: apartmentIdsInfo.apartmentId,
    companyId: apartmentIdsInfo.companyId,
    siteId: apartmentIdsInfo.siteId,
  });
  const accessGroups = apartmentData.data?.accessGroupNames;

  return (
    <InfoModal
      buttonText={formatMessage(messages.AAModalApartmentDetailClose)}
      muiDialogProps={{ maxWidth: 'xs' }}
      onClose={onClose}
      open={open}
      testId="aa-apartment-detail-modal"
      title={
        apartmentData.data
          ? getApartmentNumberAndName({
              name: apartmentData.data.name,
              number: apartmentData.data.number,
              separator: ' ',
            })
          : ''
      }
    >
      <Stack spacing={3}>
        {apartmentData.data && (
          <MobileVideoItem
            apartmentLimit={apartmentData.data.paidDevicesLimit}
            component={
              apartmentData.data.paidDevicesLimit <= BoxLimit ? (
                <MobileVideoDevicesLicenseIndicator
                  apartmentDeviceLimit={apartmentData.data.paidDevicesLimit}
                  usedDevices={apartmentData.data.paidDevicesCount}
                />
              ) : (
                <>{false}</>
              )
            }
            label={<FormattedMessage {...messages.AAModalApartmentDetailMobileVideo} />}
            value={
              <FormattedMessage
                {...messages.AAModalApartmentDetailMobileVideoHelper}
                values={{
                  count: apartmentData.data?.paidDevicesCount,
                  sum: apartmentData.data?.paidDevicesLimit,
                }}
              />
            }
          />
        )}
        <Item
          icon={<StyledIcon src={DevicesIcon} />}
          label={<FormattedMessage {...messages.AAModalApartmentDetailDevices} />}
          value={
            <FormattedMessage
              {...messages.AAModalApartmentDetailDevicesCount}
              values={{
                count: apartmentData.data?.devicesCount,
              }}
            />
          }
        />
        <Item
          icon={<Person color="secondary" />}
          label={<FormattedMessage {...messages.AAModalApartmentDetailUsers} />}
          value={
            <FormattedMessage
              {...messages.AAModalApartmentDetailUsersCount}
              values={{
                count: apartmentData.data?.usersCount,
              }}
            />
          }
        />
        <Item
          icon={<Label color="secondary" />}
          label={<FormattedMessage {...messages.AAModalApartmentDetailButtonLabel} />}
          value={getApartmentContactLabel({ formatMessage, labels: apartmentData.data?.contactLabels || [] })}
        />
        <Item
          icon={<LocationOn color="secondary" />}
          label={<FormattedMessage {...messages.AAModalApartmentDetailSite} />}
          value={apartmentData.data?.siteName || ''}
        />
        <Divider />
        <AccessGroupItem
          label={<FormattedMessage {...messages.AAModalApartmentDetailAccess} />}
          value={
            !accessGroups || !accessGroups.length ? (
              <Alert severity="warning">
                <FormattedMessage {...messages.AAModalApartmentDetailAccessNone} />
              </Alert>
            ) : (
              <AccessGroupList groups={accessGroups} />
            )
          }
        />
      </Stack>
    </InfoModal>
  );
}

export default ApartmentAdminApartmentDetailModal;
