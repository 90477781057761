import { Box } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { DestinationDto, DeviceStopDto } from '../../apiRtk/elevatorIntegrationApi';
import { AREA_ID, BUILDING_ELEVATOR_GROUP } from '../../config/consts';
import { getElevatorGroupSelectItems } from '../../containers/SiteSettings/ElevatorIntegration/FloorConfiguration/helpers';
import { selectors } from '../../modules/elevatorIntegration';
import Select from '../HookFormFields/Select';
import { useGetGlobalHooks } from './hooks/useGetGlobalHooks';
import messages from './messages';

function IndexedMenuItemContent({ name }: { name: string }) {
  return (
    <Stack direction="row" alignItems="center">
      {name}
    </Stack>
  );
}

interface ISettingsFormSelects {
  deviceStop: DeviceStopDto;
  deviceStopIndex: number;
}

function SettingsFormSelects({ deviceStop, deviceStopIndex }: ISettingsFormSelects) {
  const { formatMessage } = useGetGlobalHooks();
  const { control, setValue, watch } = useFormContext();
  const buildingElevatorGroup = watch(`${deviceStopIndex}${BUILDING_ELEVATOR_GROUP}`);
  const areaId = watch(`${deviceStopIndex}${AREA_ID}`);
  const buildingTopologyBuildings = useSelector(selectors.getBuildingTopologyBuildings);
  const elevatorGroupSelectItems = getElevatorGroupSelectItems(buildingTopologyBuildings);

  const destinations: DestinationDto[] = useMemo(() => {
    return getDestinationsForElevatorGroup(deviceStop.elevatorGroupId, deviceStop.buildingId) || [];
  }, [buildingTopologyBuildings, buildingElevatorGroup]);

  useEffect(() => {
    if (destinations.length && !destinations.flatMap((destination) => destination.areaId).includes(areaId)) {
      setValue(`${deviceStopIndex}${AREA_ID}`, '');
    }
  }, [buildingElevatorGroup, destinations]);

  function getDestinationsForElevatorGroup(elevatorGroupId: string, buildingId: string) {
    return elevatorGroupId && buildingId && buildingTopologyBuildings.length
      ? buildingTopologyBuildings
          ?.find((building) => building.id === buildingId)
          ?.elevatorGroups?.find((elevatorGroup) => elevatorGroup.id === elevatorGroupId)?.destinations
      : [];
  }

  return elevatorGroupSelectItems.length && destinations ? (
    <Stack direction="column" spacing={4}>
      <FormControl>
        <Select
          notched
          fullWidth
          displayEmpty
          control={control}
          name={`${deviceStopIndex}${BUILDING_ELEVATOR_GROUP}`}
          label={formatMessage(messages.settingsFormCoreLabel)}
          renderValue={(value: string) => {
            const selectedItem = elevatorGroupSelectItems.find(
              (elevatorGroupSelectItem) => elevatorGroupSelectItem.id === value
            );
            return value ? (
              <IndexedMenuItemContent name={`${selectedItem?.buildingId}, ${selectedItem?.elevatorGroup.name}`} />
            ) : (
              <Box color="text.disabled">{formatMessage(messages.settingsFormBuildingElevatorGroupPlaceholder)}</Box>
            );
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 280,
                maxWidth: 300,
                overflowX: 'auto',
              },
            },
          }}
        >
          {elevatorGroupSelectItems?.map((elevatorGroupSelectItem) => (
            <MenuItem key={elevatorGroupSelectItem.id} value={elevatorGroupSelectItem.id}>
              <IndexedMenuItemContent
                name={`${elevatorGroupSelectItem.buildingId}, ${elevatorGroupSelectItem.elevatorGroup.name}`}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl disabled={!buildingElevatorGroup}>
        <Select
          fullWidth
          control={control}
          name={`${deviceStopIndex}${AREA_ID}`}
          label={formatMessage(messages.settingsFormAreaLabel)}
          notched
          displayEmpty
          renderValue={(value) =>
            value ? (
              <DestinationMessage destination={destinations.find((destination) => destination.areaId === value)} />
            ) : (
              <Box color="text.disabled">{formatMessage(messages.settingsFormAreaPlaceholder)}</Box>
            )
          }
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 280,
                maxWidth: 300,
                overflowX: 'auto',
              },
            },
          }}
        >
          {destinations.map((destination, index) => (
            <MenuItem key={index} value={destination.areaId}>
              <DestinationMessage destination={destination} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  ) : (
    <>{false}</>
  );
}

interface IDestinationMessage {
  destination: DestinationDto | undefined;
}

function DestinationMessage({ destination }: IDestinationMessage) {
  return destination ? (
    <FormattedMessage
      {...messages.destinationSelectItem}
      values={{
        destinationArea: destination.areaId,
        shortName: destination.shortName,
        typography: (chunks) => (
          <Typography component="span" color="primary">
            <b>{chunks}</b>&nbsp;
          </Typography>
        ),
      }}
    />
  ) : (
    <></>
  );
}

export default SettingsFormSelects;
