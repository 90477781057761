import React from 'react';
import { FormattedMessage } from 'react-intl';
import { APARTMENT_STATUS, MOBILE_VIDEO_TYPE, PATHS } from '../../../config/consts';
import { SERVICE } from '../../../config/devices';
import { IApartmentDetail } from '../../../modules/apartments/store';
import { TId } from '../../../modules/commonTypes';
import { IMVLicenceModel } from '../../../modules/sites/store';
import ButtonLinkNew from '../../ButtonLinkNew';
import messages from '../ActionButton/messages';

interface IPurchaseButtonProps {
  apartment: IApartmentDetail;
  companyId: TId;
  licenceModel: IMVLicenceModel;
  siteId: TId;
}

function PurchaseButton({ apartment, companyId, licenceModel, siteId }: IPurchaseButtonProps) {
  return (
    <>
      {licenceModel?.type === MOBILE_VIDEO_TYPE.STANDARD &&
        apartment?.services?.[SERVICE.MOBILE_VIDEO]?.status === APARTMENT_STATUS.UNLICENSED &&
        apartment?.paidServicesActive && (
          <ButtonLinkNew
            color="primary"
            link={PATHS.SUBSCRIPTION.replace(':companyId', companyId.toString()).replace(':siteId', siteId.toString())}
            message={<FormattedMessage {...messages.actionButtonPurchase} />}
          />
        )}
    </>
  );
}

export default PurchaseButton;
