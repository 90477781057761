import { AxiosResponse } from 'axios';
import { BuildingDto, DeviceStopDto } from '../apiRtk/elevatorIntegrationApi';
import { TId, TUuid } from '../modules/commonTypes';
import { encode } from './index';
import { getSiteData, ISiteGenericRequestData, putSiteData } from './siteRequest';

export interface IDeviceElevatorIntegration {
  stops: DeviceStopDto[];
  landingCallEnabled: boolean;
  destinationCallEnabled: boolean;
  integrationActive: boolean;
}

export interface IDeviceElevatorIntegrationSet extends IDeviceElevatorIntegration {}

export interface IUserElevatorIntegration {
  landingCallEnabled: boolean;
  destinationCallEnabled: boolean;
}

export interface ISiteElevatorIntegration {
  buildingIds: BuildingDto[];
  elevatorIntegrationEnabled: boolean;
  landingCallEnabled: boolean;
  destinationCallEnabled: boolean;
}

export interface ISiteElevatorIntegrationSettings {
  elevatorIntegrationEnabled: boolean;
  landingCallEnabled: boolean;
  destinationCallEnabled: boolean;
}

export interface IElevatorIntegrationFloorMapping {
  buildingTopology: {
    buildings: {
      id: string;
      elevatorGroups: {
        id: string;
        name: string;
        destinations: {
          areaId: string;
          shortName: string;
        }[];
      }[];
    }[];
  };
  floors: {
    id: TUuid;
    name: string;
  }[];
  floorMapping: {
    floors: {
      floorId: string;
      mappings: {
        buildingId: string;
        elevatorGroupId: string;
        areaId: string;
      }[];
    }[];
  };
}

export const getSiteElevatorIntegration = async (
  siteData: ISiteGenericRequestData
): Promise<AxiosResponse<ISiteElevatorIntegration>> => getSiteData(siteData, `elevator-integration`, false);

export const setSiteElevatorIntegration = async (
  siteData: ISiteGenericRequestData,
  data: ISiteElevatorIntegrationSettings
): Promise<AxiosResponse> => putSiteData(siteData, `elevator-integration`, data, false);

export const getUserElevatorIntegration = async (
  siteData: ISiteGenericRequestData,
  userId: TUuid
): Promise<AxiosResponse<ISiteElevatorIntegrationSettings>> =>
  getSiteData(siteData, `elevator-integration/users/${encode(userId)}`, false);

export const setUserElevatorIntegration = async (
  siteData: ISiteGenericRequestData,
  userId: TUuid,
  data: IUserElevatorIntegration
): Promise<AxiosResponse> => putSiteData(siteData, `elevator-integration/users/${encode(userId)}`, data, false);

export const getDeviceElevatorIntegration = async (
  siteData: ISiteGenericRequestData,
  deviceId: TId
): Promise<AxiosResponse<IDeviceElevatorIntegration>> =>
  getSiteData(siteData, `elevator-integration/devices/${encode(deviceId.toString())}`, false);

export const setDeviceElevatorIntegration = async (
  siteData: ISiteGenericRequestData,
  deviceId: TId,
  data: IDeviceElevatorIntegrationSet
): Promise<AxiosResponse> =>
  putSiteData(siteData, `elevator-integration/devices/${encode(deviceId.toString())}`, data, false);

export const getElevatorIntegrationFloorMapping = async (
  siteData: ISiteGenericRequestData
): Promise<AxiosResponse<IElevatorIntegrationFloorMapping>> =>
  getSiteData(siteData, `elevator-integration/floor-mapping`, true);
