import { getDeviceDetail } from '../../../../api/deviceApiTS';
import { CALL_SETTINGS } from '../../../../config/consts';
import { IApartment } from '../../../../modules/apartments/store';
import {
  getButtonConfigurationFeature,
  getDeviceMaxButtonCount,
} from '../../../../modules/devices/helpers/callSettings/devicesCallSettingsHelper';
import {
  IDevice,
  isApartmentContactItem,
  isRingingGroupContactItem,
} from '../../../../modules/devices/store/deviceStore';
import { transformSelectedOption } from '../../../DeviceDetail/CallSettings/Intercom/helpers/renderHelpers';
import {
  IButtonRenderData,
  ICallSettingOption,
} from '../../../DeviceDetail/CallSettings/Intercom/hooks/useGetStateData';
import { IDeviceCallSettings, IState } from './types';

export interface IStateAction {
  state: IState;
  setState: (newState: IState) => void;
}

export function loadSelectedDevice(
  action: IStateAction,
  selectedDeviceId: number,
  onDeviceLoaded: (selectedDevice: IDevice) => void
): void {
  const state = deepCopyState(action);
  state.isFetchingSelectedDevice = true;
  action.setState(state);

  getDeviceDetail({ companyId: state.companyId, siteId: state.siteId }, selectedDeviceId)
    .then((response) => {
      if (response.status === 200) {
        const oldState = deepCopyState(action);
        state.isFetchingSelectedDevice = false;
        action.setState(oldState);
        onDeviceLoaded(response.data);
      } else {
        const stateRevert = deepCopyState(action);
        stateRevert.isFetchingSelectedDevice = false;
        action.setState(stateRevert);
      }
    })
    .catch(() => {
      const stateRevert = deepCopyState(action);
      stateRevert.isFetchingSelectedDevice = false;
      action.setState(stateRevert);
    });
}

export function selectDevice(
  action: IStateAction,
  availableDevices: IDevice[],
  availableApartments: IApartment[],
  selectedDevice: IDevice,
  previousDeviceId?: number
): void {
  const state = deepCopyState(action);

  if (previousDeviceId !== undefined) {
    const previousDeviceIndex = state.devicesCallSettings.findIndex((device) => device.device?.id === previousDeviceId);
    state.devicesCallSettings[previousDeviceIndex] = {
      buttons: getDeviceButtonsRenderData(selectedDevice, availableDevices, availableApartments),
      device: selectedDevice,
      selected: true,
    };
  } else {
    const emptyIndex = state.devicesCallSettings.findIndex((callSettings) => callSettings.device === undefined);
    state.devicesCallSettings[emptyIndex] = {
      buttons: getDeviceButtonsRenderData(selectedDevice, availableDevices, availableApartments),
      device: selectedDevice,
      selected: true,
    };
  }

  action.setState(state);
}

export function addDevice(action: IStateAction): void {
  const state = deepCopyState(action);
  state.devicesCallSettings.push({
    buttons: [],
    device: undefined,
    selected: false,
  });
  action.setState(state);
}

export function clearDevice(action: IStateAction, deviceId: number): void {
  const state = deepCopyState(action);
  if (state.devicesCallSettings.length === 1) {
    state.devicesCallSettings = [
      {
        buttons: [],
        device: undefined,
        selected: false,
      },
    ];
  } else {
    state.devicesCallSettings = state.devicesCallSettings.filter(
      (callSettings) => callSettings.device?.id !== deviceId
    );
  }
  action.setState(state);
}

export function addButton(action: IStateAction, deviceId: number): void {
  const state = deepCopyState(action);
  const deviceIndex = state.devicesCallSettings.findIndex((device) => device.device?.id === deviceId);
  const latestButtonIndex = getLastButtonIndex(state.devicesCallSettings, deviceIndex);
  state.devicesCallSettings[deviceIndex].buttons.push({
    buttonName: '',
    index: latestButtonIndex + 1,
    ringingGroup: '',
    selectedOptions: [],
  });
  action.setState(state);
}

export function changeButtonName(action: IStateAction, deviceId: number, buttonIndex: number, name: string): void {
  const state = deepCopyState(action);
  const deviceIndex = state.devicesCallSettings.findIndex((device) => device.device?.id === deviceId);
  const buttonArrayIndex = state.devicesCallSettings[deviceIndex].buttons.findIndex(
    (button) => button.index === buttonIndex
  );
  state.devicesCallSettings[deviceIndex].buttons[buttonArrayIndex].buttonName = name;
  action.setState(state);
}

export function addCallDestination(
  action: IStateAction,
  addedDevice: IDevice,
  deviceId: number,
  buttonIndex: number,
  availableApartments: IApartment[],
  isDevice: boolean
): void {
  const state = deepCopyState(action);
  const deviceIndex = state.devicesCallSettings.findIndex((device) => device.device?.id === deviceId);
  const buttonArrayIndex = state.devicesCallSettings[deviceIndex].buttons.findIndex(
    (button) => button.index === buttonIndex
  );

  if (isDevice) {
    const option = transformSelectedOption(addedDevice, true);
    state.devicesCallSettings[deviceIndex].buttons[buttonArrayIndex].selectedOptions?.push(option);
  } else {
    const apartment = availableApartments.find((apartment) => apartment.id === addedDevice.apartmentId);
    if (apartment) {
      const option = transformSelectedOption(apartment, true);
      state.devicesCallSettings[deviceIndex].buttons[buttonArrayIndex].selectedOptions?.push(option);
    }
  }

  action.setState(state);
}

export function clearCallDestination(
  action: IStateAction,
  deviceId: number,
  buttonIndex: number,
  optionKey: number
): void {
  const state = deepCopyState(action);
  const deviceIndex = state.devicesCallSettings.findIndex((device) => device.device?.id === deviceId);
  const buttonArrayIndex = state.devicesCallSettings[deviceIndex].buttons.findIndex(
    (button) => button.index === buttonIndex
  );
  state.devicesCallSettings[deviceIndex].buttons[buttonArrayIndex].selectedOptions = state.devicesCallSettings[
    deviceIndex
  ].buttons[buttonArrayIndex].selectedOptions?.filter((option) => option.optionKey !== optionKey);

  action.setState(state);
}

function deepCopyState(action: IStateAction): IState {
  return JSON.parse(JSON.stringify(action.state));
}

function getLastButtonIndex(callSettings: IDeviceCallSettings[], deviceIndex: number): number {
  if (callSettings[deviceIndex].buttons.length === 0) {
    return -1;
  }

  return callSettings[deviceIndex].buttons.sort((a, b) => (a.index < b.index ? -1 : 1))[
    callSettings[deviceIndex].buttons.length - 1
  ].index;
}

function getDeviceButtonsRenderData(
  device: IDevice,
  availableDevices: IDevice[],
  availableApartments: IApartment[]
): IButtonRenderData[] {
  const convertedButtonConfiguration: IButtonRenderData[] = [];
  const buttonConfiguration = getButtonConfigurationFeature(device.features);
  if (buttonConfiguration === undefined) {
    return [];
  }

  buttonConfiguration.buttons.forEach((buttonConfig) => {
    const contactItem = buttonConfig.contacts?.[0]?.contactItems?.find(
      (button) => button.type === 'RINGING_GROUP' || button.type === 'APARTMENT'
    );

    if (isApartmentContactItem(contactItem)) {
      const apartment = availableApartments.find((apartment) => apartment.id === contactItem.apartmentId);

      convertedButtonConfiguration.push({
        buttonName: buttonConfig.name,
        externalId: buttonConfig.externalId,
        index: buttonConfig.index - 1,
        ringingGroup: contactItem.sipNumber,
        selectedOptions: apartment ? [transformSelectedOption(apartment, false)] : undefined,
      });
    }

    if (isRingingGroupContactItem(contactItem)) {
      const selectedOptions: ICallSettingOption[] = [];
      contactItem.members.forEach((deviceMobileVideoId) => {
        const selectedDevice = availableDevices.find(
          (availableDevice) => availableDevice.services?.MOBILE_VIDEO?.id === deviceMobileVideoId
        );
        if (selectedDevice) {
          selectedOptions.push(transformSelectedOption(selectedDevice, false));
        }
      });

      convertedButtonConfiguration.push({
        buttonName: buttonConfig.name,
        externalId: buttonConfig.externalId,
        index: buttonConfig.index - 1,
        ringingGroup: contactItem.sipNumber,
        selectedOptions,
      });
    }
  });

  return fillUpWithEmptyButtons(convertedButtonConfiguration, device);
}

function fillUpWithEmptyButtons(alreadySetupButtons: IButtonRenderData[], device: IDevice): IButtonRenderData[] {
  const initialSetup = alreadySetupButtons;
  const deviceMaxButtonCount = getDeviceMaxButtonCount(device);
  const defaultButtonCount = deviceMaxButtonCount || CALL_SETTINGS.INTERCOM.DEFAULT_LIST_COUNT;

  for (let i = initialSetup.length; i < defaultButtonCount; i++) {
    initialSetup.push(createEmptyButton(i));
  }

  return initialSetup;
}

function createEmptyButton(index: number): IButtonRenderData {
  return {
    buttonName: '',
    index,
    ringingGroup: '',
    selectedOptions: [],
  };
}
