import { defineMessages } from 'react-intl';

export default defineMessages({
  actionButtonPurchase: {
    defaultMessage: 'Purchase',
    description: '',
    id: 'actionButton.purchase',
  },
  actionButtonUseAvailableCredit: {
    defaultMessage: 'Use available credit',
    description: '',
    id: 'actionButton.useAvailableCredit',
  },
});
