import Stack from '@mui/material/Stack';
import React from 'react';
import { Paper } from '../../components';
import { SaveChangesBtn } from '../../components/SaveChangesBtn';
import ApartmentDataForm from './ApartmentDataForm';
import { useGetApartmentInfoData } from './hooks/useGetApartmentInfoData';

function ApartmentInfo() {
  const { floors, formMethods, onSubmit, siteType } = useGetApartmentInfoData();

  return (
    <Paper tabContent>
      <ApartmentDataForm
        floors={floors}
        formMethods={formMethods}
        formWrapperProps={{
          item: true,
          lg: 8,
          xs: 12,
        }}
        onSubmit={onSubmit}
        siteType={siteType}
      />
      <Stack direction="row" justifyContent="flex-end">
        <SaveChangesBtn
          disabled={!Object.keys(formMethods.formState.dirtyFields).length || !formMethods.formState.isValid}
          onClick={() => onSubmit(formMethods.getValues())}
        />
      </Stack>
    </Paper>
  );
}

export default ApartmentInfo;
