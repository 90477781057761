import { api } from './api';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    activatePinForAccess: build.mutation<ActivatePinForAccessApiResponse, ActivatePinForAccessApiArg>({
      query: (queryArg) => ({
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/${queryArg.accessId}/credentials/pin/${queryArg.pinId}/activate`,
      }),
    }),

    activatePinForUser: build.mutation<ActivatePinForUserApiResponse, ActivatePinForUserApiArg>({
      query: (queryArg) => ({
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}/credentials/pin/${queryArg.pinId}/activate`,
      }),
    }),
    addGroupToApartments: build.mutation<AddGroupToApartmentsApiResponse, AddGroupToApartmentsApiArg>({
      query: (queryArg) => ({
        body: queryArg.body,
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/groups/${queryArg.groupId}/apartments`,
      }),
    }),
    assignAccess: build.mutation<AssignAccessApiResponse, AssignAccessApiArg>({
      query: (queryArg) => ({
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/groups/${queryArg.groupId}/accesses/${queryArg.accessId}`,
      }),
    }),
    assignDevice: build.mutation<AssignDeviceApiResponse, AssignDeviceApiArg>({
      query: (queryArg) => ({
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/groups/${queryArg.groupId}/devices/${queryArg.deviceId}`,
      }),
    }),
    assignGroupToApartment: build.mutation<AssignGroupToApartmentApiResponse, AssignGroupToApartmentApiArg>({
      query: (queryArg) => ({
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/groups/${queryArg.groupId}/apartments/${queryArg.apartmentId}`,
      }),
    }),
    assignGroupToApartment1: build.mutation<AssignGroupToApartment1ApiResponse, AssignGroupToApartment1ApiArg>({
      query: (queryArg) => ({
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/apartments/${queryArg.apartmentId}/groups/${queryArg.groupId}`,
      }),
    }),
    assignPinToGuest: build.mutation<AssignPinToGuestApiResponse, AssignPinToGuestApiArg>({
      query: (queryArg) => ({
        body: queryArg.pinAssignValidityDto,
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}/credentials/pin-with-validity`,
      }),
    }),
    assignPinToUserWithoutLogin: build.mutation<
      AssignPinToUserWithoutLoginApiResponse,
      AssignPinToUserWithoutLoginApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.pinAssignDto,
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}/credentials/pin/assign`,
      }),
    }),
    assignUser: build.mutation<AssignUserApiResponse, AssignUserApiArg>({
      query: (queryArg) => ({
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/groups/${queryArg.groupId}/users/${queryArg.userId}`,
      }),
    }),
    createAccess: build.mutation<CreateAccessApiResponse, CreateAccessApiArg>({
      query: (queryArg) => ({
        method: 'POST',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses`,
      }),
    }),
    createBluetooth: build.mutation<CreateBluetoothApiResponse, CreateBluetoothApiArg>({
      query: (queryArg) => ({
        method: 'POST',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}/credentials/bluetooth`,
      }),
    }),
    createBluetoothAccess: build.mutation<CreateBluetoothAccessApiResponse, CreateBluetoothAccessApiArg>({
      query: (queryArg) => ({
        method: 'POST',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/${queryArg.accessId}/credentials/bluetooth`,
      }),
    }),
    createDeviceService: build.mutation<CreateDeviceServiceApiResponse, CreateDeviceServiceApiArg>({
      query: (queryArg) => ({
        body: queryArg.createDeviceServiceDto,
        method: 'POST',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/devices`,
      }),
    }),
    createGroup: build.mutation<CreateGroupApiResponse, CreateGroupApiArg>({
      query: (queryArg) => ({
        body: queryArg.createUpdateGroupDto,
        method: 'POST',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/groups`,
      }),
    }),
    createGuestPinWithoutAccess: build.query<CreateGuestPinWithoutAccessApiResponse, CreateGuestPinWithoutAccessApiArg>(
      {
        query: (queryArg) => ({
          url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/credentials/available-guest-pin`,
        }),
      }
    ),
    createLprForAccess: build.mutation<CreateLprForAccessApiResponse, CreateLprForAccessApiArg>({
      query: (queryArg) => ({
        body: queryArg.createLprDto,
        method: 'POST',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/${queryArg.accessId}/credentials/lpr`,
      }),
    }),
    createPinForAccess: build.mutation<CreatePinForAccessApiResponse, CreatePinForAccessApiArg>({
      query: (queryArg) => ({
        body: queryArg.customPinRequestDto,
        method: 'POST',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/${queryArg.accessId}/credentials/pin`,
      }),
    }),
    createPinForUser: build.mutation<CreatePinForUserApiResponse, CreatePinForUserApiArg>({
      query: (queryArg) => ({
        body: queryArg.customPinRequestDto,
        method: 'POST',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}/credentials/pin`,
      }),
    }),
    createPinWithoutAccess: build.query<CreatePinWithoutAccessApiResponse, CreatePinWithoutAccessApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/credentials/available-pin`,
      }),
    }),
    createRfidForAccess: build.mutation<CreateRfidForAccessApiResponse, CreateRfidForAccessApiArg>({
      query: (queryArg) => ({
        body: queryArg.createRfidDto,
        method: 'POST',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/${queryArg.accessId}/credentials/rfid`,
      }),
    }),
    createRfidForUser: build.mutation<CreateRfidForUserApiResponse, CreateRfidForUserApiArg>({
      query: (queryArg) => ({
        body: queryArg.createRfidDto,
        method: 'POST',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}/credentials/rfid`,
      }),
    }),
    deleteAccess: build.mutation<DeleteAccessApiResponse, DeleteAccessApiArg>({
      query: (queryArg) => ({
        method: 'DELETE',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/${queryArg.accessId}`,
      }),
    }),
    deleteAllLprsForAccess: build.mutation<DeleteAllLprsForAccessApiResponse, DeleteAllLprsForAccessApiArg>({
      query: (queryArg) => ({
        method: 'DELETE',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/${queryArg.accessId}/credentials/lpr`,
      }),
    }),
    deleteBluetooth: build.mutation<DeleteBluetoothApiResponse, DeleteBluetoothApiArg>({
      query: (queryArg) => ({
        method: 'DELETE',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}/credentials/bluetooth`,
      }),
    }),
    deleteBluetoothAccess: build.mutation<DeleteBluetoothAccessApiResponse, DeleteBluetoothAccessApiArg>({
      query: (queryArg) => ({
        method: 'DELETE',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/${queryArg.accessId}/credentials/bluetooth`,
      }),
    }),
    deleteDeviceService: build.mutation<DeleteDeviceServiceApiResponse, DeleteDeviceServiceApiArg>({
      query: (queryArg) => ({
        method: 'DELETE',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/devices/${queryArg.deviceId}/services/ACCESS_CONTROL`,
      }),
    }),
    deleteGroup: build.mutation<DeleteGroupApiResponse, DeleteGroupApiArg>({
      query: (queryArg) => ({
        method: 'DELETE',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/groups/${queryArg.groupId}`,
      }),
    }),
    deleteLprForAccessAndLprId: build.mutation<DeleteLprForAccessAndLprIdApiResponse, DeleteLprForAccessAndLprIdApiArg>(
      {
        query: (queryArg) => ({
          method: 'DELETE',
          url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/${queryArg.accessId}/credentials/lpr/${queryArg.id}`,
        }),
      }
    ),
    deletePinForAccess: build.mutation<DeletePinForAccessApiResponse, DeletePinForAccessApiArg>({
      query: (queryArg) => ({
        method: 'DELETE',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/${queryArg.accessId}/credentials/pin`,
      }),
    }),
    deletePinForUser: build.mutation<DeletePinForUserApiResponse, DeletePinForUserApiArg>({
      query: (queryArg) => ({
        method: 'DELETE',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}/credentials/pin`,
      }),
    }),
    deleteRfidForAccessById: build.mutation<DeleteRfidForAccessByIdApiResponse, DeleteRfidForAccessByIdApiArg>({
      query: (queryArg) => ({
        method: 'DELETE',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/${queryArg.accessId}/credentials/rfid/${queryArg.rfidId}`,
      }),
    }),
    deleteRfidForUserById: build.mutation<DeleteRfidForUserByIdApiResponse, DeleteRfidForUserByIdApiArg>({
      query: (queryArg) => ({
        method: 'DELETE',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}/credentials/rfid/${queryArg.rfidId}`,
      }),
    }),
    deleteRfidsForAccess: build.mutation<DeleteRfidsForAccessApiResponse, DeleteRfidsForAccessApiArg>({
      query: (queryArg) => ({
        method: 'DELETE',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/${queryArg.accessId}/credentials/rfid`,
      }),
    }),
    deleteRfidsForUser: build.mutation<DeleteRfidsForUserApiResponse, DeleteRfidsForUserApiArg>({
      query: (queryArg) => ({
        method: 'DELETE',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}/credentials/rfid`,
      }),
    }),
    disableBluetooth: build.mutation<DisableBluetoothApiResponse, DisableBluetoothApiArg>({
      query: (queryArg) => ({
        method: 'POST',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}/credentials/bluetooth/disable`,
      }),
    }),
    enableBluetooth: build.mutation<EnableBluetoothApiResponse, EnableBluetoothApiArg>({
      query: (queryArg) => ({
        method: 'POST',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}/credentials/bluetooth/enable`,
      }),
    }),
    getAccess: build.query<GetAccessApiResponse, GetAccessApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/${queryArg.accessId}`,
      }),
    }),
    getAccessCredentialsValidity: build.query<
      GetAccessCredentialsValidityApiResponse,
      GetAccessCredentialsValidityApiArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/${queryArg.accessId}/credentials`,
      }),
    }),
    getAccessesInGroup: build.query<GetAccessesInGroupApiResponse, GetAccessesInGroupApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/groups/${queryArg.groupId}/accesses`,
      }),
    }),
    getAccessInSiteByRfidCode: build.query<GetAccessInSiteByRfidCodeApiResponse, GetAccessInSiteByRfidCodeApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/credentials/rfid/${queryArg.rfidCode}`,
      }),
    }),
    getAllAccessesInSite: build.query<GetAllAccessesInSiteApiResponse, GetAllAccessesInSiteApiArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses` }),
    }),
    getAllLprsForAccess: build.query<GetAllLprsForAccessApiResponse, GetAllLprsForAccessApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/${queryArg.accessId}/credentials/lpr`,
      }),
    }),
    getApartmentAdminPinSettings: build.query<
      GetApartmentAdminPinSettingsApiResponse,
      GetApartmentAdminPinSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/apartments/${queryArg.apartmentId}/credentials/guest-pin-settings`,
      }),
    }),
    getApartmentGroups: build.query<GetApartmentGroupsApiResponse, GetApartmentGroupsApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/apartments/${queryArg.apartmentId}/groups`,
      }),
    }),
    getApartmentsOnSite: build.query<GetApartmentsOnSiteApiResponse, GetApartmentsOnSiteApiArg>({
      query: (queryArg) => ({
        params: { coreId: queryArg.coreId },
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/apartments`,
      }),
    }),
    getBluetooth: build.query<GetBluetoothApiResponse, GetBluetoothApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}/credentials/bluetooth`,
      }),
    }),
    getBluetoothAccess: build.query<GetBluetoothAccessApiResponse, GetBluetoothAccessApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/${queryArg.accessId}/credentials/bluetooth`,
      }),
    }),
    getBluetoothStatus: build.query<GetBluetoothStatusApiResponse, GetBluetoothStatusApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}/credentials/bluetooth/status`,
      }),
    }),
    getCredentials: build.mutation<GetCredentialsApiResponse, GetCredentialsApiArg>({
      query: (queryArg) => ({ body: queryArg.body, method: 'POST', url: `/users/credentials` }),
    }),
    getCredentialsForCompany: build.mutation<GetCredentialsForCompanyApiResponse, GetCredentialsForCompanyApiArg>({
      query: (queryArg) => ({
        body: queryArg.body,
        method: 'POST',
        url: `/companies/${queryArg.companyId}/users/credentials`,
      }),
    }),
    getCredentialsForSite: build.mutation<GetCredentialsForSiteApiResponse, GetCredentialsForSiteApiArg>({
      query: (queryArg) => ({
        body: queryArg.body,
        method: 'POST',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/credentials`,
      }),
    }),
    getDeviceAccesses: build.query<GetDeviceAccessesApiResponse, GetDeviceAccessesApiArg>({
      query: (queryArg) => ({
        params: {
          credential: queryArg.credential,
          limit: queryArg.limit,
          offset: queryArg.offset,
          order: queryArg.order,
          pageable: queryArg.pageable,
          syncStatus: queryArg.syncStatus,
        },
        url: `/companies/${queryArg.companyId}/device-synchronization/device-accesses`,
      }),
    }),
    getDeviceServices: build.query<GetDeviceServicesApiResponse, GetDeviceServicesApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/devices/${queryArg.deviceId}/services/ACCESS_CONTROL`,
      }),
    }),
    getDeviceServicesInSite: build.query<GetDeviceServicesInSiteApiResponse, GetDeviceServicesInSiteApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/devices/${queryArg.deviceId}/services/ACCESS_CONTROL`,
      }),
    }),
    getDeviceServicesWithoutRole: build.query<
      GetDeviceServicesWithoutRoleApiResponse,
      GetDeviceServicesWithoutRoleApiArg
    >({
      query: (queryArg) => ({ url: `/devices/${queryArg.deviceId}/services/ACCESS_CONTROL` }),
    }),
    getDevicesInGroup: build.query<GetDevicesInGroupApiResponse, GetDevicesInGroupApiArg>({
      query: (queryArg) => ({
        params: { limit: queryArg.limit, offset: queryArg.offset, order: queryArg.order, pageable: queryArg.pageable },
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/groups/${queryArg.groupId}/devices`,
      }),
    }),
    getGroup: build.query<GetGroupApiResponse, GetGroupApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/groups/${queryArg.groupId}`,
      }),
    }),
    getGroupsInSiteByAccess: build.query<GetGroupsInSiteByAccessApiResponse, GetGroupsInSiteByAccessApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/${queryArg.accessId}/groups`,
      }),
    }),
    getGroupsInSiteByDevice: build.query<GetGroupsInSiteByDeviceApiResponse, GetGroupsInSiteByDeviceApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/devices/${queryArg.deviceId}/groups`,
      }),
    }),
    getGroupsInSiteByUser: build.query<GetGroupsInSiteByUserApiResponse, GetGroupsInSiteByUserApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}/groups`,
      }),
    }),
    getGroupsOfUsersInSite: build.mutation<GetGroupsOfUsersInSiteApiResponse, GetGroupsOfUsersInSiteApiArg>({
      query: (queryArg) => ({
        body: queryArg.body,
        method: 'POST',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/groups`,
      }),
    }),
    getGroupsOfUsersInSite1: build.mutation<GetGroupsOfUsersInSite1ApiResponse, GetGroupsOfUsersInSite1ApiArg>({
      query: (queryArg) => ({
        body: queryArg.body,
        method: 'POST',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users-by-groups`,
      }),
    }),
    getLprByAccessAndLprId: build.query<GetLprByAccessAndLprIdApiResponse, GetLprByAccessAndLprIdApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/${queryArg.accessId}/credentials/lpr/${queryArg.id}`,
      }),
    }),
    getPinByIdForAccess: build.query<GetPinByIdForAccessApiResponse, GetPinByIdForAccessApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/${queryArg.accessId}/credentials/pin/${queryArg.pinId}`,
      }),
    }),
    getPinByIdForUser: build.query<GetPinByIdForUserApiResponse, GetPinByIdForUserApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}/credentials/pin/${queryArg.pinId}`,
      }),
    }),
    getPinForAccess: build.query<GetPinForAccessApiResponse, GetPinForAccessApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/${queryArg.accessId}/credentials/pin`,
      }),
    }),
    getPinForUser: build.query<GetPinForUserApiResponse, GetPinForUserApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}/credentials/pin`,
      }),
    }),
    getRfidForAccessById: build.query<GetRfidForAccessByIdApiResponse, GetRfidForAccessByIdApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/${queryArg.accessId}/credentials/rfid/${queryArg.rfidId}`,
      }),
    }),
    getRfidForUserById: build.query<GetRfidForUserByIdApiResponse, GetRfidForUserByIdApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}/credentials/rfid/${queryArg.rfidId}`,
      }),
    }),
    getRfidsForAccess: build.query<GetRfidsForAccessApiResponse, GetRfidsForAccessApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/${queryArg.accessId}/credentials/rfid`,
      }),
    }),
    getRfidsForUser: build.query<GetRfidsForUserApiResponse, GetRfidsForUserApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}/credentials/rfid`,
      }),
    }),
    getServices: build.mutation<GetServicesApiResponse, GetServicesApiArg>({
      query: (queryArg) => ({
        body: queryArg.body,
        method: 'POST',
        url: `/companies/${queryArg.companyId}/devices/services`,
      }),
    }),
    getServicesInSite: build.query<GetServicesInSiteApiResponse, GetServicesInSiteApiArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/devices/services` }),
    }),
    getServicesInSiteByDeviceIds: build.mutation<
      GetServicesInSiteByDeviceIdsApiResponse,
      GetServicesInSiteByDeviceIdsApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.body,
        method: 'POST',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/devices/services`,
      }),
    }),
    getServicesWithoutRole: build.mutation<GetServicesWithoutRoleApiResponse, GetServicesWithoutRoleApiArg>({
      query: (queryArg) => ({ body: queryArg.body, method: 'POST', url: `/devices/services` }),
    }),
    getSiteGuestPinSettings: build.query<GetSiteGuestPinSettingsApiResponse, GetSiteGuestPinSettingsApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/credentials/guest-pin-settings`,
      }),
    }),
    getSiteUsersCredentials: build.mutation<GetSiteUsersCredentialsApiResponse, GetSiteUsersCredentialsApiArg>({
      query: (queryArg) => ({ body: queryArg.body, method: 'POST', url: `/api/private/v1/users-credentials-by-site` }),
    }),
    getSiteUsersCredentials1: build.mutation<GetSiteUsersCredentials1ApiResponse, GetSiteUsersCredentials1ApiArg>({
      query: (queryArg) => ({ body: queryArg.body, method: 'POST', url: `/api/private/v1/site-user-credentials` }),
    }),
    getUserApartments: build.query<GetUserApartmentsApiResponse, GetUserApartmentsApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}/apartments`,
      }),
    }),
    getUserCredentialsValidity: build.query<GetUserCredentialsValidityApiResponse, GetUserCredentialsValidityApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}/credentials`,
      }),
    }),
    getUsersInGroup: build.query<GetUsersInGroupApiResponse, GetUsersInGroupApiArg>({
      query: (queryArg) => ({
        params: { limit: queryArg.limit, offset: queryArg.offset, order: queryArg.order, pageable: queryArg.pageable },
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/groups/${queryArg.groupId}/users`,
      }),
    }),
    listAllApartments: build.query<ListAllApartmentsApiResponse, ListAllApartmentsApiArg>({
      query: (queryArg) => ({
        params: { limit: queryArg.limit, offset: queryArg.offset, order: queryArg.order, pageable: queryArg.pageable },
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/groups/${queryArg.groupId}/apartments`,
      }),
    }),
    listAllGroups1: build.query<ListAllGroups1ApiResponse, ListAllGroups1ApiArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/groups-all` }),
    }),
    listAllGroups2: build.query<ListAllGroups2ApiResponse, ListAllGroups2ApiArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/groups` }),
    }),
    listAllGroupsPaged: build.query<ListAllGroupsPagedApiResponse, ListAllGroupsPagedApiArg>({
      query: (queryArg) => ({
        params: { limit: queryArg.limit, offset: queryArg.offset, order: queryArg.order, pageable: queryArg.pageable },
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/groups-paged`,
      }),
    }),
    putAccessesIntoGroup: build.mutation<PutAccessesIntoGroupApiResponse, PutAccessesIntoGroupApiArg>({
      query: (queryArg) => ({
        body: queryArg.body,
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/groups/${queryArg.groupId}/accesses`,
      }),
    }),
    putAccessIntoGroups: build.mutation<PutAccessIntoGroupsApiResponse, PutAccessIntoGroupsApiArg>({
      query: (queryArg) => ({
        body: queryArg.body,
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/${queryArg.accessId}/groups`,
      }),
    }),
    putDeviceIntoGroups: build.mutation<PutDeviceIntoGroupsApiResponse, PutDeviceIntoGroupsApiArg>({
      query: (queryArg) => ({
        body: queryArg.body,
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/devices/${queryArg.deviceId}/groups`,
      }),
    }),
    putDevicesIntoGroup: build.mutation<PutDevicesIntoGroupApiResponse, PutDevicesIntoGroupApiArg>({
      query: (queryArg) => ({
        body: queryArg.body,
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/groups/${queryArg.groupId}/devices`,
      }),
    }),
    putUserIntoGroups: build.mutation<PutUserIntoGroupsApiResponse, PutUserIntoGroupsApiArg>({
      query: (queryArg) => ({
        body: queryArg.body,
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}/groups`,
      }),
    }),
    putUsersIntoGroup: build.mutation<PutUsersIntoGroupApiResponse, PutUsersIntoGroupApiArg>({
      query: (queryArg) => ({
        body: queryArg.body,
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/groups/${queryArg.groupId}/users`,
      }),
    }),
    recreateBluetooth: build.mutation<RecreateBluetoothApiResponse, RecreateBluetoothApiArg>({
      query: (queryArg) => ({
        method: 'POST',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}/credentials/bluetooth/authid`,
      }),
    }),
    recreateBluetoothAccess: build.mutation<RecreateBluetoothAccessApiResponse, RecreateBluetoothAccessApiArg>({
      query: (queryArg) => ({
        method: 'POST',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/${queryArg.accessId}/credentials/bluetooth/authid`,
      }),
    }),
    removeAccess: build.mutation<RemoveAccessApiResponse, RemoveAccessApiArg>({
      query: (queryArg) => ({
        method: 'DELETE',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/groups/${queryArg.groupId}/accesses/${queryArg.accessId}`,
      }),
    }),
    removeDevice: build.mutation<RemoveDeviceApiResponse, RemoveDeviceApiArg>({
      query: (queryArg) => ({
        method: 'DELETE',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/groups/${queryArg.groupId}/devices/${queryArg.deviceId}`,
      }),
    }),
    removeGroupFromApartment: build.mutation<RemoveGroupFromApartmentApiResponse, RemoveGroupFromApartmentApiArg>({
      query: (queryArg) => ({
        method: 'DELETE',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/groups/${queryArg.groupId}/apartments/${queryArg.apartmentId}`,
      }),
    }),
    removeGroupFromApartment1: build.mutation<RemoveGroupFromApartment1ApiResponse, RemoveGroupFromApartment1ApiArg>({
      query: (queryArg) => ({
        method: 'DELETE',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/apartments/${queryArg.apartmentId}/groups/${queryArg.groupId}`,
      }),
    }),
    removeUser: build.mutation<RemoveUserApiResponse, RemoveUserApiArg>({
      query: (queryArg) => ({
        method: 'DELETE',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/groups/${queryArg.groupId}/users/${queryArg.userId}`,
      }),
    }),
    setAccessLogActive: build.mutation<SetAccessLogActiveApiResponse, SetAccessLogActiveApiArg>({
      query: (queryArg) => ({
        body: queryArg.accessLogActiveDto,
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/devices/${queryArg.deviceId}/services/ACCESS_CONTROL/accesslog`,
      }),
    }),
    setAcmActive: build.mutation<SetAcmActiveApiResponse, SetAcmActiveApiArg>({
      query: (queryArg) => ({
        body: queryArg.serviceActiveDto,
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/devices/${queryArg.deviceId}/services/ACCESS_CONTROL`,
      }),
    }),
    setGroupsToApartment: build.mutation<SetGroupsToApartmentApiResponse, SetGroupsToApartmentApiArg>({
      query: (queryArg) => ({
        body: queryArg.body,
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/apartments/${queryArg.apartmentId}/groups`,
      }),
    }),
    updateAccessCredentialsValidity: build.mutation<
      UpdateAccessCredentialsValidityApiResponse,
      UpdateAccessCredentialsValidityApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.credentialsValidityDto,
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/accesses/${queryArg.accessId}/credentials`,
      }),
    }),
    updateGroup: build.mutation<UpdateGroupApiResponse, UpdateGroupApiArg>({
      query: (queryArg) => ({
        body: queryArg.createUpdateGroupDto,
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/groups/${queryArg.groupId}`,
      }),
    }),
    updateSiteGuestPinSettings: build.mutation<UpdateSiteGuestPinSettingsApiResponse, UpdateSiteGuestPinSettingsApiArg>(
      {
        query: (queryArg) => ({
          body: queryArg.guestPinSettingsDto,
          method: 'PUT',
          url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/credentials/guest-pin-settings`,
        }),
      }
    ),
    updateUserCredentialsValidity: build.mutation<
      UpdateUserCredentialsValidityApiResponse,
      UpdateUserCredentialsValidityApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.credentialsValidityDto,
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/users/${queryArg.userId}/credentials`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as accessControlApi };
export type GetGroupsInSiteByUserApiResponse = /** status 200 User groups */ GroupDto[];
export type GetGroupsInSiteByUserApiArg = {
  siteId: string;
  userId: string;
  companyId: string;
};
export type PutUserIntoGroupsApiResponse = /** status 204 User assigned */ void;
export type PutUserIntoGroupsApiArg = {
  siteId: string;
  userId: string;
  companyId: string;
  body: string[];
};
export type ActivatePinForUserApiResponse = /** status 204 PIN activated */ void;
export type ActivatePinForUserApiArg = {
  siteId: string;
  userId: string;
  pinId: string;
  companyId: string;
};
export type AssignPinToUserWithoutLoginApiResponse = /** status 200 PIN assigned */ PinAssignedDto;
export type AssignPinToUserWithoutLoginApiArg = {
  siteId: string;
  userId: string;
  companyId: string;
  pinAssignDto: PinAssignDto;
};
export type AssignPinToGuestApiResponse = /** status 200 PIN assigned */ PinAssignedValidityDto;
export type AssignPinToGuestApiArg = {
  siteId: string;
  userId: string;
  companyId: string;
  pinAssignValidityDto: PinAssignValidityDto;
};
export type GetUserCredentialsValidityApiResponse =
  /** status 200 User Credentials validity in site */ CredentialsValidityResponseDto;
export type GetUserCredentialsValidityApiArg = {
  siteId: string;
  userId: string;
  companyId: string;
};
export type UpdateUserCredentialsValidityApiResponse =
  /** status 204 Access Credentials validity in site updated */ void;
export type UpdateUserCredentialsValidityApiArg = {
  siteId: string;
  userId: string;
  companyId: string;
  credentialsValidityDto: CredentialsValidityDto;
};
export type AssignUserApiResponse = /** status 204 User assigned */ void;
export type AssignUserApiArg = {
  siteId: string;
  groupId: string;
  userId: string;
  companyId: string;
};
export type RemoveUserApiResponse = /** status 204 User was removed from Group */ void;
export type RemoveUserApiArg = {
  siteId: string;
  groupId: string;
  userId: string;
  companyId: string;
};
export type GetUsersInGroupApiResponse = /** status 200 All Users assigned */ UserPageResponseDto;
export type GetUsersInGroupApiArg = {
  siteId: string;
  groupId: string;
  companyId: string;
  /** Zero-based offset (0..N), where to start a page */
  offset?: number;
  /** The size of the page to be returned */
  limit?: number;
  /** Sorting criteria in the format: (,-)property. Default sort order is ascending. */
  order?: string;
  pageable: Pageable;
};
export type PutUsersIntoGroupApiResponse = /** status 204 Users set */ void;
export type PutUsersIntoGroupApiArg = {
  siteId: string;
  groupId: string;
  companyId: string;
  body: string[];
};
export type AssignDeviceApiResponse = /** status 204 Device assigned */ void;
export type AssignDeviceApiArg = {
  siteId: string;
  groupId: string;
  deviceId: string;
  companyId: string;
};
export type RemoveDeviceApiResponse = /** status 204 Device was removed from Group */ void;
export type RemoveDeviceApiArg = {
  siteId: string;
  groupId: string;
  deviceId: string;
  companyId: string;
};
export type GetDevicesInGroupApiResponse = /** status 200 All Devices assigned */ DevicePageResponseDto;
export type GetDevicesInGroupApiArg = {
  siteId: string;
  groupId: string;
  companyId: string;
  /** Zero-based offset (0..N), where to start a page */
  offset?: number;
  /** The size of the page to be returned */
  limit?: number;
  /** Sorting criteria in the format: (,-)property. Default sort order is ascending. */
  order?: string;
  pageable: Pageable;
};
export type PutDevicesIntoGroupApiResponse = /** status 204 Devices set */ void;
export type PutDevicesIntoGroupApiArg = {
  siteId: string;
  groupId: string;
  companyId: string;
  body: string[];
};
export type AssignGroupToApartmentApiResponse = /** status 204 Group was assigned to an apartment */ void;
export type AssignGroupToApartmentApiArg = {
  siteId: string;
  groupId: string;
  apartmentId: string;
  companyId: string;
};
export type RemoveGroupFromApartmentApiResponse = /** status 204 Group was removed from apartment */ void;
export type RemoveGroupFromApartmentApiArg = {
  siteId: string;
  groupId: string;
  apartmentId: string;
  companyId: string;
};
export type ListAllApartmentsApiResponse = /** status 200 OK */ ApartmentPageResponseDto;
export type ListAllApartmentsApiArg = {
  siteId: string;
  groupId: string;
  companyId: string;
  /** List of field names for sorting returned list of apartments. For descending order use the prefix '-' before given field name."  */
  order?: string[];
  /** Zero-based offset (0..N), where to start a page */
  offset?: number;
  /** The size of the page to be returned */
  limit?: number;
  pageable: Pageable;
};
export type AddGroupToApartmentsApiResponse = /** status 204 Group was added to apartments */ void;
export type AddGroupToApartmentsApiArg = {
  companyId: string;
  siteId: string;
  groupId: string;
  body: string[];
};
export type AssignAccessApiResponse = /** status 204 Access assigned */ void;
export type AssignAccessApiArg = {
  siteId: string;
  groupId: string;
  accessId: string;
  companyId: string;
};
export type RemoveAccessApiResponse = /** status 204 Access was removed from Group */ void;
export type RemoveAccessApiArg = {
  siteId: string;
  groupId: string;
  accessId: string;
  companyId: string;
};
export type GetAccessesInGroupApiResponse = /** status 200 All Accesses assigned */ AccessDto[];
export type GetAccessesInGroupApiArg = {
  siteId: string;
  groupId: string;
  companyId: string;
};
export type PutAccessesIntoGroupApiResponse = /** status 204 Accesses set */ void;
export type PutAccessesIntoGroupApiArg = {
  siteId: string;
  groupId: string;
  companyId: string;
  body: string[];
};
export type GetGroupApiResponse = /** status 200 Group */ GroupUsersDevicesDto;
export type GetGroupApiArg = {
  siteId: string;
  groupId: string;
  companyId: string;
};
export type UpdateGroupApiResponse = /** status 204 Group updated */ void;
export type UpdateGroupApiArg = {
  siteId: string;
  groupId: string;
  companyId: string;
  createUpdateGroupDto: CreateUpdateGroupDto;
};
export type DeleteGroupApiResponse = /** status 204 Group was deleted */ void;
export type DeleteGroupApiArg = {
  siteId: string;
  groupId: string;
  companyId: string;
};
export type SetAccessLogActiveApiResponse = /** status 200 Device service was updated */ ServiceDto;
export type SetAccessLogActiveApiArg = {
  siteId: string;
  deviceId: string;
  companyId: string;
  accessLogActiveDto: AccessLogActiveDto;
};
export type GetDeviceServicesInSiteApiResponse = /** status 200 Device detail */ ServiceDto;
export type GetDeviceServicesInSiteApiArg = {
  siteId: string;
  deviceId: string;
  companyId: string;
};
export type SetAcmActiveApiResponse = /** status 200 Device service was updated */ ServiceDto;
export type SetAcmActiveApiArg = {
  siteId: string;
  deviceId: string;
  companyId: string;
  serviceActiveDto: ServiceActiveDto;
};
export type DeleteDeviceServiceApiResponse = /** status 204 Device service was deleted */ void;
export type DeleteDeviceServiceApiArg = {
  siteId: string;
  deviceId: string;
  companyId: string;
};
export type GetGroupsInSiteByDeviceApiResponse = /** status 200 Device groups */ GroupDto[];
export type GetGroupsInSiteByDeviceApiArg = {
  siteId: string;
  deviceId: string;
  companyId: string;
};
export type PutDeviceIntoGroupsApiResponse = /** status 204 Device set */ void;
export type PutDeviceIntoGroupsApiArg = {
  siteId: string;
  deviceId: string;
  companyId: string;
  body: string[];
};
export type GetSiteGuestPinSettingsApiResponse = /** status 200 guest pin settings */ GuestPinSettingsDto;
export type GetSiteGuestPinSettingsApiArg = {
  siteId: string;
  companyId: string;
};
export type GetApartmentAdminPinSettingsApiResponse = /** status 200 guest pin settings */ GuestPinSettingsDto;
export type GetApartmentAdminPinSettingsApiArg = {
  siteId: string;
  companyId: string;
  apartmentId: string;
};
export type UpdateSiteGuestPinSettingsApiResponse = /** status 200 guest pin settings */ GuestPinSettingsDto;
export type UpdateSiteGuestPinSettingsApiArg = {
  siteId: string;
  companyId: string;
  guestPinSettingsDto: GuestPinSettingsDto;
};
export type AssignGroupToApartment1ApiResponse = /** status 204 Group was assigned to an apartment */ void;
export type AssignGroupToApartment1ApiArg = {
  companyId: string;
  siteId: string;
  apartmentId: string;
  groupId: string;
};
export type RemoveGroupFromApartment1ApiResponse = /** status 204 Group was removed from apartment */ void;
export type RemoveGroupFromApartment1ApiArg = {
  siteId: string;
  apartmentId: string;
  groupId: string;
  companyId: string;
};
export type GetApartmentGroupsApiResponse = /** status 200 Apartment group */ GroupDto[];
export type GetApartmentGroupsApiArg = {
  siteId: string;
  apartmentId: string;
  companyId: string;
};
export type SetGroupsToApartmentApiResponse = /** status 204 Groups were set to an apartment */ void;
export type SetGroupsToApartmentApiArg = {
  companyId: string;
  siteId: string;
  apartmentId: string;
  body: string[];
};
export type GetGroupsInSiteByAccessApiResponse = /** status 200 Access groups */ GroupDto[];
export type GetGroupsInSiteByAccessApiArg = {
  siteId: string;
  accessId: string;
  companyId: string;
};
export type PutAccessIntoGroupsApiResponse = /** status 204 Access set */ void;
export type PutAccessIntoGroupsApiArg = {
  siteId: string;
  accessId: string;
  companyId: string;
  body: string[];
};
export type ActivatePinForAccessApiResponse = /** status 204 PIN activated */ void;
export type ActivatePinForAccessApiArg = {
  siteId: string;
  accessId: string;
  pinId: string;
  companyId: string;
};
export type GetAccessCredentialsValidityApiResponse =
  /** status 200 Access Credentials validity */ CredentialsValidityResponseDto;
export type GetAccessCredentialsValidityApiArg = {
  siteId: string;
  accessId: string;
  companyId: string;
};
export type UpdateAccessCredentialsValidityApiResponse = /** status 204 Access Credentials validity updated */ void;
export type UpdateAccessCredentialsValidityApiArg = {
  siteId: string;
  accessId: string;
  companyId: string;
  credentialsValidityDto: CredentialsValidityDto;
};
export type GetCredentialsApiResponse = /** status 200 User credentials */ UserCredentialsDto[];
export type GetCredentialsApiArg = {
  body: string[];
};
export type GetServicesWithoutRoleApiResponse =
  /** status 200 List of all services for queried devices */ DeviceServiceDto[];
export type GetServicesWithoutRoleApiArg = {
  body: string[];
};
export type GetCredentialsForCompanyApiResponse = /** status 200 User credentials */ UserCredentialsDto[];
export type GetCredentialsForCompanyApiArg = {
  companyId: string;
  body: string[];
};
export type GetRfidsForUserApiResponse = /** status 200 RFID */ RfidDto[];
export type GetRfidsForUserApiArg = {
  siteId: string;
  userId: string;
  companyId: string;
};
export type CreateRfidForUserApiResponse = /** status 200 created RFID */ RfidDto;
export type CreateRfidForUserApiArg = {
  siteId: string;
  userId: string;
  companyId: string;
  createRfidDto: CreateRfidDto;
};
export type DeleteRfidsForUserApiResponse = /** status 204 RFIDs were deleted */ void;
export type DeleteRfidsForUserApiArg = {
  siteId: string;
  userId: string;
  companyId: string;
};
export type GetPinForUserApiResponse = /** status 200 Active PIN */ PinDto;
export type GetPinForUserApiArg = {
  siteId: string;
  userId: string;
  companyId: string;
};
export type CreatePinForUserApiResponse = /** status 200 Generated PIN */ PinDto;
export type CreatePinForUserApiArg = {
  siteId: string;
  userId: string;
  companyId: string;
  customPinRequestDto: CustomPinRequestDto;
};
export type DeletePinForUserApiResponse = /** status 204 PIN was deleted */ void;
export type DeletePinForUserApiArg = {
  siteId: string;
  userId: string;
  companyId: string;
};
export type EnableBluetoothApiResponse = /** status 204 Bluetooth enabled */ void;
export type EnableBluetoothApiArg = {
  siteId: string;
  userId: string;
  companyId: string;
};
export type DisableBluetoothApiResponse = /** status 204 Bluetooth disabled */ void;
export type DisableBluetoothApiArg = {
  siteId: string;
  userId: string;
  companyId: string;
};
export type RecreateBluetoothApiResponse = /** status 200 Bluetooth recreated */ BluetoothDto;
export type RecreateBluetoothApiArg = {
  siteId: string;
  userId: string;
  companyId: string;
};
export type GetBluetoothApiResponse = /** status 200 Bluetooth User Credentials */ BluetoothDto;
export type GetBluetoothApiArg = {
  siteId: string;
  userId: string;
  companyId: string;
};
export type CreateBluetoothApiResponse = /** status 204 Bluetooth created */ void;
export type CreateBluetoothApiArg = {
  siteId: string;
  userId: string;
  companyId: string;
};
export type DeleteBluetoothApiResponse = /** status 204 Bluetooth was deleted */ void;
export type DeleteBluetoothApiArg = {
  siteId: string;
  userId: string;
  companyId: string;
};
export type GetGroupsOfUsersInSiteApiResponse = /** status 200 User groups */ GroupsByUserUuidsDto;
export type GetGroupsOfUsersInSiteApiArg = {
  siteId: string;
  companyId: string;
  body: string[];
};
export type GetCredentialsForSiteApiResponse = /** status 200 User credentials */ UserCredentialsDto[];
export type GetCredentialsForSiteApiArg = {
  siteId: string;
  companyId: string;
  body: string[];
};
export type GetGroupsOfUsersInSite1ApiResponse = /** status 200 User groups */ GroupsByUserUuidsDto;
export type GetGroupsOfUsersInSite1ApiArg = {
  siteId: string;
  companyId: string;
  body: string[];
};
export type ListAllGroups2ApiResponse = /** status 200 OK */ CustomPageDtoGroupUsersDevicesDto | GroupDto[];
export type ListAllGroups2ApiArg = {
  siteId: string;
  companyId: string;
};
export type CreateGroupApiResponse = /** status 200 Group created */ GroupDto;
export type CreateGroupApiArg = {
  siteId: string;
  companyId: string;
  createUpdateGroupDto: CreateUpdateGroupDto;
};
export type GetServicesInSiteApiResponse =
  /** status 200 List of all services for devices in site */ DeviceServiceDto[];
export type GetServicesInSiteApiArg = {
  siteId: string;
  companyId: string;
};
export type GetServicesInSiteByDeviceIdsApiResponse =
  /** status 200 List of all services for queried devices */ DeviceServiceDto[];
export type GetServicesInSiteByDeviceIdsApiArg = {
  siteId: string;
  companyId: string;
  body: string[];
};
export type CreateDeviceServiceApiResponse = /** status 201 Device service was created */ CreateDeviceServiceDto;
export type CreateDeviceServiceApiArg = {
  siteId: string;
  companyId: string;
  createDeviceServiceDto: CreateDeviceServiceDtoWrite;
};
export type GetRfidsForAccessApiResponse = /** status 200 RFID */ RfidDto[];
export type GetRfidsForAccessApiArg = {
  siteId: string;
  accessId: string;
  companyId: string;
};
export type CreateRfidForAccessApiResponse = /** status 200 created RFID */ RfidDto;
export type CreateRfidForAccessApiArg = {
  siteId: string;
  accessId: string;
  companyId: string;
  createRfidDto: CreateRfidDto;
};
export type DeleteRfidsForAccessApiResponse = /** status 204 RFIDs were deleted */ void;
export type DeleteRfidsForAccessApiArg = {
  siteId: string;
  accessId: string;
  companyId: string;
};
export type GetPinForAccessApiResponse = /** status 200 Active PIN */ PinDto;
export type GetPinForAccessApiArg = {
  siteId: string;
  accessId: string;
  companyId: string;
};
export type CreatePinForAccessApiResponse = /** status 200 Generated PIN */ PinDto;
export type CreatePinForAccessApiArg = {
  siteId: string;
  accessId: string;
  companyId: string;
  customPinRequestDto: CustomPinRequestDto;
};
export type DeletePinForAccessApiResponse = /** status 204 PIN was deleted */ void;
export type DeletePinForAccessApiArg = {
  siteId: string;
  accessId: string;
  companyId: string;
};
export type GetAllLprsForAccessApiResponse = /** status 200 All LPR Credentials for Access */ LprDto[];
export type GetAllLprsForAccessApiArg = {
  siteId: string;
  accessId: string;
  companyId: string;
};
export type CreateLprForAccessApiResponse = /** status 200 LPR Credentials for Access created */ LprDto;
export type CreateLprForAccessApiArg = {
  siteId: string;
  accessId: string;
  companyId: string;
  createLprDto: CreateLprDto;
};
export type DeleteAllLprsForAccessApiResponse = /** status 204 All LPR credentials for Access deleted */ void;
export type DeleteAllLprsForAccessApiArg = {
  siteId: string;
  accessId: string;
  companyId: string;
};
export type RecreateBluetoothAccessApiResponse = /** status 204 Bluetooth recreated */ void;
export type RecreateBluetoothAccessApiArg = {
  siteId: string;
  accessId: string;
  companyId: string;
};
export type GetBluetoothAccessApiResponse = /** status 200 Bluetooth Access Credentials */ BluetoothDto;
export type GetBluetoothAccessApiArg = {
  siteId: string;
  accessId: string;
  companyId: string;
};
export type CreateBluetoothAccessApiResponse = /** status 204 Bluetooth created */ void;
export type CreateBluetoothAccessApiArg = {
  siteId: string;
  accessId: string;
  companyId: string;
};
export type DeleteBluetoothAccessApiResponse = /** status 204 Bluetooth was deleted */ void;
export type DeleteBluetoothAccessApiArg = {
  siteId: string;
  accessId: string;
  companyId: string;
};
export type GetAllAccessesInSiteApiResponse = /** status 200 All Accesses in site */ AccessDto[];
export type GetAllAccessesInSiteApiArg = {
  siteId: string;
  companyId: string;
};
export type CreateAccessApiResponse = /** status 200 created Access */ AccessDto;
export type CreateAccessApiArg = {
  siteId: string;
  companyId: string;
};
export type GetServicesApiResponse = /** status 200 List of all services for queried devices */ DeviceServiceDto[];
export type GetServicesApiArg = {
  companyId: string;
  body: string[];
};
export type GetSiteUsersCredentialsApiResponse =
  /** status 200 Site Users credentials from multiple sites */ SiteUsersCredentialsResponseDto;
export type GetSiteUsersCredentialsApiArg = {
  body: SiteUsersCredentialsRequestDto[];
};
export type GetSiteUsersCredentials1ApiResponse =
  /** status 200 Site Users credentials from multiple sites */ UserCredentialsDto;
export type GetSiteUsersCredentials1ApiArg = {
  body: SiteUsersCredentialsRequestDto[];
};
export type GetDeviceServicesWithoutRoleApiResponse = /** status 200 Device detail */ ServiceDto[];
export type GetDeviceServicesWithoutRoleApiArg = {
  deviceId: string;
};
export type GetRfidForUserByIdApiResponse = /** status 200 RFID */ RfidDto;
export type GetRfidForUserByIdApiArg = {
  siteId: string;
  userId: string;
  rfidId: string;
  companyId: string;
};
export type DeleteRfidForUserByIdApiResponse = /** status 204 RFID was deleted */ void;
export type DeleteRfidForUserByIdApiArg = {
  siteId: string;
  userId: string;
  rfidId: string;
  companyId: string;
};
export type GetPinByIdForUserApiResponse = /** status 200 PIN */ PinDto;
export type GetPinByIdForUserApiArg = {
  siteId: string;
  userId: string;
  pinId: string;
  companyId: string;
};
export type GetBluetoothStatusApiResponse =
  /** status 200 Status of Bluetooth for a User with enabled status and hash of actual authId */ BluetoothUserSiteStatusDto;
export type GetBluetoothStatusApiArg = {
  siteId: string;
  userId: string;
  companyId: string;
};
export type GetUserApartmentsApiResponse = /** status 200 User apartments in site */ UserApartmentDto[];
export type GetUserApartmentsApiArg = {
  siteId: string;
  userId: string;
  companyId: string;
};
export type ListAllGroupsPagedApiResponse = /** status 200 OK */ GroupPageResponseDto;
export type ListAllGroupsPagedApiArg = {
  siteId: string;
  companyId: string;
  /** Zero-based offset (0..N), where to start a page */
  offset?: number;
  /** The size of the page to be returned */
  limit?: number;
  /** Sorting criteria in the format: (,-)property. Default sort order is ascending. */
  order?: string;
  pageable: Pageable;
};
export type ListAllGroups1ApiResponse = /** status 200 OK */ GroupDto[];
export type ListAllGroups1ApiArg = {
  siteId: string;
  companyId: string;
};
export type CreatePinWithoutAccessApiResponse = /** status 200 created Pin */ PinValueDto;
export type CreatePinWithoutAccessApiArg = {
  siteId: string;
  companyId: string;
};
export type CreateGuestPinWithoutAccessApiResponse = /** status 200 created Pin */ PinValueDto;
export type CreateGuestPinWithoutAccessApiArg = {
  siteId: string;
  companyId: string;
};
export type GetApartmentsOnSiteApiResponse =
  /** status 200 For list of apartments is getting number of devices assigned to particular apartment and number of groups particular apartment is assigned to */ SiteApartmentDto[];
export type GetApartmentsOnSiteApiArg = {
  siteId: string;
  coreId: string[];
  companyId: string;
};
export type GetRfidForAccessByIdApiResponse = /** status 200 RFID */ RfidDto;
export type GetRfidForAccessByIdApiArg = {
  siteId: string;
  accessId: string;
  rfidId: string;
  companyId: string;
};
export type DeleteRfidForAccessByIdApiResponse = /** status 204 RFID was deleted */ void;
export type DeleteRfidForAccessByIdApiArg = {
  siteId: string;
  accessId: string;
  rfidId: string;
  companyId: string;
};
export type GetPinByIdForAccessApiResponse = /** status 200 PIN */ PinDto;
export type GetPinByIdForAccessApiArg = {
  siteId: string;
  accessId: string;
  pinId: string;
  companyId: string;
};
export type GetLprByAccessAndLprIdApiResponse = /** status 200 Credentials for LPR and Access */ LprDto;
export type GetLprByAccessAndLprIdApiArg = {
  siteId: string;
  accessId: string;
  id: string;
  companyId: string;
};
export type DeleteLprForAccessAndLprIdApiResponse = /** status 204 Credentials for LPR and Access deleted */ void;
export type DeleteLprForAccessAndLprIdApiArg = {
  siteId: string;
  accessId: string;
  id: string;
  companyId: string;
};
export type GetAccessApiResponse = /** status 200 Access */ AccessDto;
export type GetAccessApiArg = {
  siteId: string;
  accessId: string;
  companyId: string;
};
export type DeleteAccessApiResponse = /** status 204 Access was deleted */ void;
export type DeleteAccessApiArg = {
  siteId: string;
  accessId: string;
  companyId: string;
};
export type GetAccessInSiteByRfidCodeApiResponse = /** status 200 Access ID and User ID */ AccessUserDto;
export type GetAccessInSiteByRfidCodeApiArg = {
  siteId: string;
  rfidCode: string;
  companyId: string;
};
export type GetDeviceServicesApiResponse = /** status 200 Device detail */ ServiceDto[];
export type GetDeviceServicesApiArg = {
  deviceId: string;
  companyId: string;
};
export type GetDeviceAccessesApiResponse = /** status 200 OK */ DeviceAccessPageResponseDto;
export type GetDeviceAccessesApiArg = {
  /** Id of the company */
  companyId: string;
  /** List of field names for sorting returned results. For descending order use the prefix '-' before given field name. */
  order?: string[];
  /** List of credential types to find. */
  credential?: string[];
  /** List of sync statuses to find. */
  syncStatus?: string[];
  /** Zero-based offset (0..N), where to start a page */
  offset?: number;
  /** The size of the page to be returned */
  limit?: number;
  pageable: Pageable;
};
export type GroupDto = {
  id?: string;
  name?: string;
  description?: string;
  siteId?: string;
};
export type ServerErrorResponse = {
  message?: string;
  detail?: object;
  uuid?: string;
};
export type PinAssignedDto = {
  pinId?: string;
  active?: boolean;
};
export type PinAssignDto = {
  active?: boolean;
  pin: string;
};
export type PinAssignedValidityDto = {
  pinId?: string;
  validTo?: string;
  validFrom?: string;
  apartmentAccess?: boolean;
};
export type PinAssignValidityDto = {
  pin: string;
  validFrom?: string;
  validTo?: string;
  site?: string;
  emails?: string[];
  apartmentAccess?: boolean;
};
export type CredentialsValidityResponseDto = {
  validFrom?: string;
  validTo?: string;
  /** Whether the guest user is allowed access to the apartment. The property is not relevant for another user type. */
  apartmentAccess?: boolean;
};
export type CredentialsValidityDto = {
  validFrom?: string;
  validTo?: string;
};
export type CredentialsDto = 'PIN' | 'RFID' | 'BT' | 'LPR';
export type GroupUserApartmentDto = {
  id?: string;
  coreId?: string;
  name?: string;
  number?: string;
  siteId?: string;
};
export type GroupUserDto = {
  credentials?: CredentialsDto[];
  groups?: GroupDto[];
  apartments?: GroupUserApartmentDto[];
  id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
};
export type UserPageResponseDto = {
  results?: GroupUserDto[];
  resultsTotal?: number;
};
export type Pageable = {
  page?: number;
  size?: number;
  sort?: string[];
};
export type DeviceGroupsDto = {
  accessControl?: boolean;
  accessLog?: boolean;
  groups?: GroupDto[];
  deviceId?: string;
  deviceName?: string;
  deviceType?: string;
};
export type DevicePageResponseDto = {
  results?: DeviceGroupsDto[];
  resultsTotal?: number;
};
export type ApartmentDto = {
  id?: string;
  coreId?: string;
  name?: string;
  number?: string;
  siteId?: string;
  floor?: string;
  userCount?: number;
  groups?: GroupDto[];
};
export type ApartmentPageResponseDto = {
  results?: ApartmentDto[];
  resultsTotal?: number;
};
export type AccessDto = {
  id?: string;
};
export type DeviceAcServiceDto = {
  accessControl?: boolean;
  deviceId?: string;
  deviceName?: string;
  deviceType?: string;
};
export type GroupUsersDevicesDto = {
  id?: string;
  name?: string;
  description?: string;
  siteId?: string;
  users?: number;
  apartments?: number;
  devices?: DeviceAcServiceDto[];
};
export type CreateUpdateGroupDto = {
  name: string;
  description?: string;
};
export type ServiceState = 'CREATING';
export type ServiceDto = {
  active?: boolean;
  type?: string;
  dateCreated?: string;
  lastUpdated?: string;
  status?: ServiceState;
  accessLog?: boolean;
};
export type ServiceDtoWrite = {
  active?: boolean;
  type?: string;
  dateCreated?: string;
  lastUpdated?: string;
  status?: ServiceState;
  accessLog?: boolean;
  additionalProperties?: {
    [key: string]: object;
  };
};
export type AccessLogActiveDto = {
  active: boolean;
};
export type ServiceActiveDto = {
  active: boolean;
};
export type GuestPinSettingsDto = {
  guestPinLength?: number;
  guestShowQr?: boolean;
};
export type UserCredentialsDto = {
  userId?: string;
  credentials?: CredentialsDto[];
  validFrom?: string;
  validTo?: string;
};
export type DeviceServiceDto = {
  deviceId?: string;
  services?: ServiceDto[];
};
export type DeviceServiceDtoWrite = {
  deviceId?: string;
  services?: ServiceDtoWrite[];
};
export type RfidDto = {
  id?: string;
  rfidCode?: string;
};
export type CreateRfidDto = {
  rfidCode: string;
};
export type PinDto = {
  id?: string;
  pin?: string;
  active?: boolean;
  link?: string;
};
export type CustomPinRequestDto = {
  pin: string;
  active?: boolean;
};
export type BluetoothDto = {
  id?: string;
  authId?: string;
  authIdHash?: string;
  locationId?: string;
  publicKey?: string;
  keyId?: string;
};
export type GroupsByUserUuidsDto = {
  result?: {
    [key: string]: GroupDto[];
  };
};
export type CustomPageDtoGroupUsersDevicesDto = {
  results?: GroupUsersDevicesDto[];
  resultsTotal?: number;
};
export type CreateDeviceServiceDto = {
  id?: string;
  serialNumber?: string;
  deviceType?: string;
  services?: ServiceDto[];
};
export type CreateDeviceServiceDtoWrite = {
  id?: string;
  serialNumber?: string;
  deviceType?: string;
  services?: ServiceDtoWrite[];
  additionalProperties?: {
    [key: string]: object;
  };
};
export type LprDto = {
  id?: string;
  licensePlate?: string;
};
export type CreateLprDto = {
  licensePlate?: string;
};
export type SiteUsersCredentialsResponseDto = {
  result?: {
    [key: string]: UserCredentialsDto[];
  };
};
export type SiteUsersCredentialsRequestDto = {
  siteId?: string;
  userUuids?: string[];
};
export type BluetoothUserSiteStatusDto = {
  enabled?: boolean;
  authIdHash?: string;
};
export type UserApartmentDto = {
  id?: string;
  coreId?: string;
  name?: string;
  number?: string;
  siteId?: string;
  devicesCount?: number;
  groups?: GroupDto[];
};
export type GroupPageResponseDto = {
  results?: GroupUsersDevicesDto[];
  resultsTotal?: number;
};
export type PinValueDto = {
  pin?: string;
};
export type SiteApartmentDto = {
  id?: string;
  coreId?: string;
  devicesCount?: number;
  groupsCount?: number;
};
export type AccessUserDto = {
  accessId?: string;
  userId?: string;
};
export type DirectoryUserOperation = 'CREATE' | 'UPDATE' | 'DELETE';
export type UserSynchronizationStatus = 'REQUESTED' | 'SYNCHRONIZED' | 'SYNCHRONIZED_WITH_ERROR';
export type DeviceAccessDto = {
  sn?: string;
  accessId?: string;
  userOperation?: DirectoryUserOperation;
  syncStatus?: UserSynchronizationStatus;
  lastUpdated?: string;
  credentials?: CredentialsDto[];
};
export type DeviceAccessPageResponseDto = {
  results: DeviceAccessDto[];
  resultsFilteredCount: number;
  resultsTotalCount: number;
};
export const {
  useActivatePinForAccessMutation,
  useActivatePinForUserMutation,
  useAddGroupToApartmentsMutation,
  useAssignAccessMutation,
  useAssignDeviceMutation,
  useAssignGroupToApartment1Mutation,
  useAssignGroupToApartmentMutation,
  useAssignPinToGuestMutation,
  useAssignPinToUserWithoutLoginMutation,
  useAssignUserMutation,
  useCreateAccessMutation,
  useCreateBluetoothAccessMutation,
  useCreateBluetoothMutation,
  useCreateDeviceServiceMutation,
  useCreateGroupMutation,
  useCreateGuestPinWithoutAccessQuery,
  useCreateLprForAccessMutation,
  useCreatePinForAccessMutation,
  useCreatePinForUserMutation,
  useCreatePinWithoutAccessQuery,
  useCreateRfidForAccessMutation,
  useCreateRfidForUserMutation,
  useDeleteAccessMutation,
  useDeleteAllLprsForAccessMutation,
  useDeleteBluetoothAccessMutation,
  useDeleteBluetoothMutation,
  useDeleteDeviceServiceMutation,
  useDeleteGroupMutation,
  useDeleteLprForAccessAndLprIdMutation,
  useDeletePinForAccessMutation,
  useDeletePinForUserMutation,
  useDeleteRfidForAccessByIdMutation,
  useDeleteRfidForUserByIdMutation,
  useDeleteRfidsForAccessMutation,
  useDeleteRfidsForUserMutation,
  useDisableBluetoothMutation,
  useEnableBluetoothMutation,
  useGetAccessCredentialsValidityQuery,
  useGetAccessesInGroupQuery,
  useGetAccessInSiteByRfidCodeQuery,
  useGetAccessQuery,
  useGetAllAccessesInSiteQuery,
  useGetAllLprsForAccessQuery,
  useGetApartmentAdminPinSettingsQuery,
  useGetApartmentGroupsQuery,
  useGetApartmentsOnSiteQuery,
  useGetBluetoothAccessQuery,
  useGetBluetoothQuery,
  useGetBluetoothStatusQuery,
  useGetCredentialsForCompanyMutation,
  useGetCredentialsForSiteMutation,
  useGetCredentialsMutation,
  useGetDeviceAccessesQuery,
  useGetDeviceServicesInSiteQuery,
  useGetDeviceServicesQuery,
  useGetDeviceServicesWithoutRoleQuery,
  useGetDevicesInGroupQuery,
  useGetGroupQuery,
  useGetGroupsInSiteByAccessQuery,
  useGetGroupsInSiteByDeviceQuery,
  useGetGroupsInSiteByUserQuery,
  useGetGroupsOfUsersInSite1Mutation,
  useGetGroupsOfUsersInSiteMutation,
  useGetLprByAccessAndLprIdQuery,
  useGetPinByIdForAccessQuery,
  useGetPinByIdForUserQuery,
  useGetPinForAccessQuery,
  useGetPinForUserQuery,
  useGetRfidForAccessByIdQuery,
  useGetRfidForUserByIdQuery,
  useGetRfidsForAccessQuery,
  useGetRfidsForUserQuery,
  useGetServicesInSiteByDeviceIdsMutation,
  useGetServicesInSiteQuery,
  useGetServicesMutation,
  useGetServicesWithoutRoleMutation,
  useGetSiteGuestPinSettingsQuery,
  useGetSiteUsersCredentials1Mutation,
  useGetSiteUsersCredentialsMutation,
  useGetUserApartmentsQuery,
  useGetUserCredentialsValidityQuery,
  useGetUsersInGroupQuery,
  useListAllApartmentsQuery,
  useListAllGroups1Query,
  useListAllGroups2Query,
  useListAllGroupsPagedQuery,
  usePutAccessesIntoGroupMutation,
  usePutAccessIntoGroupsMutation,
  usePutDeviceIntoGroupsMutation,
  usePutDevicesIntoGroupMutation,
  usePutUserIntoGroupsMutation,
  usePutUsersIntoGroupMutation,
  useRecreateBluetoothAccessMutation,
  useRecreateBluetoothMutation,
  useRemoveAccessMutation,
  useRemoveDeviceMutation,
  useRemoveGroupFromApartment1Mutation,
  useRemoveGroupFromApartmentMutation,
  useRemoveUserMutation,
  useSetAccessLogActiveMutation,
  useSetAcmActiveMutation,
  useSetGroupsToApartmentMutation,
  useUpdateAccessCredentialsValidityMutation,
  useUpdateGroupMutation,
  useUpdateSiteGuestPinSettingsMutation,
  useUpdateUserCredentialsValidityMutation,
} = injectedRtkApi;
