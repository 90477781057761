import { DeviceStopDto } from '../../../apiRtk/elevatorIntegrationApi';
import { AREA_ID, BUILDING_ELEVATOR_GROUP, DEFAULT_DEVICE_STOP, WALKING_TIME } from '../../../config/consts';

export function walkingTimeToSliderValue(time: number) {
  if (time < 5) {
    return 0;
  } else if (time < 10) {
    return 1;
  } else if (time < 20) {
    return 2;
  }
  return 3;
}

export function sliderValueToWalkingTime(distance: number) {
  switch (distance) {
    case 0:
      return 0;
    case 1:
      return 5;
    case 2:
      return 15;
    default:
      return 30;
  }
}

export function getDeviceStops(formData: Record<string, any>) {
  const deviceStops: DeviceStopDto[] = [];
  const deviceStopPattern = new RegExp('^\\d' + BUILDING_ELEVATOR_GROUP);
  const deviceStopItems = Object.keys(formData).filter((formDataKey) => deviceStopPattern.test(formDataKey));

  deviceStopItems.forEach((_deviceStopItem, index) => {
    const buildingElevatorGroupArray = formData[`${index}${BUILDING_ELEVATOR_GROUP}`]
      ? formData[`${index}${BUILDING_ELEVATOR_GROUP}`].split('|')
      : ['', ''];
    const walkingTime = formData[`${index}${WALKING_TIME}`];

    deviceStops.push({
      areaId: formData[`${index}${AREA_ID}`],
      buildingId: buildingElevatorGroupArray[0],
      elevatorGroupId: buildingElevatorGroupArray[1],
      walkingTime:
        walkingTime || walkingTime === 0
          ? sliderValueToWalkingTime(Number(walkingTime))
          : DEFAULT_DEVICE_STOP.walkingTime,
    });
  });

  return deviceStops;
}

export function getStopDefaultValues(stops: DeviceStopDto[]) {
  const stopsDefaultValues: Record<string, string | number> = {};

  stops.forEach((stop, index) => {
    stopsDefaultValues[`${index}${BUILDING_ELEVATOR_GROUP}`] =
      stop.buildingId && stop.elevatorGroupId ? `${stop.buildingId}|${stop.elevatorGroupId}` : '';
    stopsDefaultValues[`${index}${AREA_ID}`] = stop.areaId ? stop.areaId : '';
    stopsDefaultValues[`${index}${WALKING_TIME}`] = walkingTimeToSliderValue(
      stop.walkingTime ?? DEFAULT_DEVICE_STOP.walkingTime
    );
  });

  return stopsDefaultValues;
}
