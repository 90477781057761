import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useHasFeature } from '../components/Feature/Feature';
import { PATHS } from '../config/consts';
import featureFlags from '../config/featureFlags';
import ApartmentDetail from '../containers/ApartmentDetail';
import ApartmentAccess from '../containers/ApartmentDetail/ApartmentAccess';
import ApartmentDevices from '../containers/ApartmentDetail/ApartmentDevices';
import ApartmentInfo from '../containers/ApartmentDetail/ApartmentInfo';
import ApartmentUsers from '../containers/ApartmentDetail/ApartmentUsers';
import ApartmentDetailNew from '../containers/ApartmentDetailNew';
import ApartmentAccessNew from '../containers/ApartmentDetailNew/ApartmentAccess';
import ApartmentDevicesNew from '../containers/ApartmentDetailNew/ApartmentDevices';
import ApartmentInfoNew from '../containers/ApartmentDetailNew/ApartmentInfo';
import ApartmentUsersNew from '../containers/ApartmentDetailNew/ApartmentUsers';
import { useUrlParams } from '../helpers/urlParams';
import { useGetLoadedParams } from '../hooks/paramsHooks';
import { getApartmentDetail } from '../modules/apartments/actions';
import { getIsApartmentDetailFetching } from '../modules/apartments/selectors';
import { ApplicationContext, Roles } from '../modules/commonTypes';
import { PrivateRoute } from './components/PrivateRoute';

export function ApartmentDetailRoutes() {
  const { companyId, siteId } = useGetLoadedParams(ApplicationContext.Site);
  const { apartmentId } = useUrlParams();
  const dispatch = useDispatch();
  const isApartmentDetailFetching = useSelector(getIsApartmentDetailFetching);
  const isPaidServicesApartmentFFEnabled = useHasFeature(featureFlags.PAID_SERVICES_APARTMENT);

  useEffect(() => {
    if (apartmentId !== undefined) {
      dispatch(getApartmentDetail(companyId, siteId, apartmentId));
    }
  }, [companyId, siteId, apartmentId]);

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.ADMIN, Roles.STANDARD] }}>
            {isPaidServicesApartmentFFEnabled ? <ApartmentDetailNew /> : <ApartmentDetail />}
          </PrivateRoute>
        }
      >
        <Route
          element={
            !isApartmentDetailFetching && (isPaidServicesApartmentFFEnabled ? <ApartmentInfoNew /> : <ApartmentInfo />)
          }
          path={'detail'}
        />
        <Route
          element={isPaidServicesApartmentFFEnabled ? <ApartmentDevicesNew /> : <ApartmentDevices />}
          path={'devices'}
        />
        <Route element={isPaidServicesApartmentFFEnabled ? <ApartmentUsersNew /> : <ApartmentUsers />} path={'users'} />
        <Route
          element={isPaidServicesApartmentFFEnabled ? <ApartmentAccessNew /> : <ApartmentAccess />}
          path={'access'}
        />
      </Route>
      <Route
        path={PATHS.APARTMENT}
        element={<Navigate replace to={getFallbackPath(companyId, siteId, apartmentId)} />}
      />
    </Routes>
  );
}

function getFallbackPath(companyId: number, siteId: number, apartmentId?: number): string {
  if (apartmentId !== undefined) {
    return PATHS.APARTMENT_DETAIL.replace(':companyId', companyId.toString())
      .replace(':siteId', siteId.toString())
      .replace(':apartmentId', apartmentId.toString());
  }

  return PATHS.APARTMENTS_OVERVIEW.replace(':companyId', companyId.toString()).replace(':siteId', siteId.toString());
}
