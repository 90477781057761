import { uniqBy } from 'lodash';
import { ElIntBuilding } from '../../../../apiRtk/be4feApi';
import { ElevatorGroupSelectItem } from './FloorConfiguration';

export function getElevatorGroupSelectItems(buildingTopologyBuildings: ElIntBuilding[]) {
  const floorElevatorGroups: Array<ElevatorGroupSelectItem> = [];
  buildingTopologyBuildings.forEach((buildingTopologyBuilding) => {
    buildingTopologyBuilding.elevatorGroups.forEach((buildingTopologyBuildingElevatorGroup) => {
      floorElevatorGroups.push({
        buildingId: buildingTopologyBuilding.id,
        elevatorGroup: buildingTopologyBuildingElevatorGroup,
        id: `${buildingTopologyBuilding.id}|${buildingTopologyBuildingElevatorGroup.id}`,
      });
    });
  });
  return uniqBy(floorElevatorGroups, 'id');
}
