import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { CustomDate, Status } from '../../../../components';
import ButtonLinkNew from '../../../../components/ButtonLinkNew';
import { useHasFeature } from '../../../../components/Feature/Feature';
import { PATHS } from '../../../../config/consts';
import featureFlags from '../../../../config/featureFlags';
import { BILLING_TYPE } from '../../../../config/subscription';
import { useUrlParams } from '../../../../helpers/urlParams';
import { MobileVideoStatus } from '../../../../modules/commonTypes';
import actions from '../../../../modules/sites';
import { IMVLicenceModel } from '../../../../modules/sites/store';
import { ICodeData } from '../../../../modules/subscription/store';
import messages from './messages';

interface IActivationStatusSuccess {
  mobileVideoLicence: IMVLicenceModel;
  codeData: ICodeData;
}

function ActivationStatusSuccess({ codeData, mobileVideoLicence }: IActivationStatusSuccess) {
  const dispatch = useDispatch();
  const { companyId, siteId } = useUrlParams();
  const isPaidServicesApartmentFFEnabled = useHasFeature(featureFlags.PAID_SERVICES_APARTMENT);

  useEffect(() => {
    dispatch(actions.getCurrentSiteLicenseModel());
  }, []);

  const { billingType, status } = mobileVideoLicence;
  const { expiration, initialConsumption, licencesCount, licencesPool, nextPayment } = codeData.activation;
  const { credit } = codeData.configuration;

  const successInfo = () => {
    const infoMessages = [];

    if (
      isPaidServicesApartmentFFEnabled &&
      status === MobileVideoStatus.STANDARD &&
      billingType === BILLING_TYPE.PER_APARTMENT
    ) {
      infoMessages.push(
        <FormattedMessage
          {...messages.activationStatusSuccessInfoPoolInfoReceivedCredit}
          values={{
            br: <br />,
            credit: credit.toFixed(1),
            creditPool: '',
            strong: (chunks) => <strong>{chunks}</strong>,
          }}
        />,
        <FormattedMessage
          {...messages.activationStatusSuccessInfoPoolInfoNewlyLicencedApartments}
          values={{
            count: licencesCount.toFixed(),
            strong: (chunks) => <strong>{chunks}</strong>,
          }}
        />,
        <FormattedMessage
          {...messages.activationStatusSuccessInfoCreditPool}
          values={{
            pool: licencesPool.toFixed(1),
            strong: (chunks) => <strong>{chunks}</strong>,
          }}
        />
      );

      return infoMessages;
    }

    if (billingType && initialConsumption > 0 && initialConsumption <= credit) {
      infoMessages.push(
        <FormattedMessage
          {...creditsUsedMap[billingType]}
          values={{
            br: <br />,
            consumption: initialConsumption.toFixed(1),
            credit: credit.toFixed(1),
            pool: licencesPool.toFixed(1),
            strong: (chunks) => <strong>{chunks}</strong>,
          }}
        />
      );
    }

    if (initialConsumption === 0) {
      infoMessages.push(
        <FormattedMessage
          {...messages.activationStatusSuccessInfoPoolInfoReceivedCredit}
          values={{
            br: <br />,
            credit: credit.toFixed(1),
            creditPool: (
              <FormattedMessage
                {...messages.activationStatusSuccessInfoCreditPool}
                values={{
                  pool: licencesPool.toFixed(1),
                  strong: (chunks) => <strong>{chunks}</strong>,
                }}
              />
            ),
            strong: (chunks) => <strong>{chunks}</strong>,
          }}
        />
      );
    }

    if (billingType && initialConsumption > credit) {
      infoMessages.push(
        <FormattedMessage
          {...messages.activationStatusSuccessInfoPoolInfoReceivedCredit}
          values={{
            br: <br />,
            credit: credit.toFixed(1),
            creditPool: null,
            strong: (chunks) => <strong>{chunks}</strong>,
          }}
        />,
        <FormattedMessage
          {...creditPoolMap[billingType]}
          values={{
            br: <br />,
            credit: credit.toFixed(1),
            strong: (chunks) => <strong>{chunks}</strong>,
          }}
        />,
        <FormattedMessage
          {...messages.activationStatusSuccessInfoCreditPool}
          values={{
            credit: credit.toFixed(1),
            pool: licencesPool.toFixed(1),
            strong: (chunks) => <strong>{chunks}</strong>,
          }}
        />
      );
    }

    if (nextPayment) {
      infoMessages.push(
        <FormattedMessage
          {...messages.activationStatusSuccessInfoNextPayment}
          values={{
            expiration: <CustomDate dateValue={expiration} />,
            strong: (chunks) => <strong>{chunks}</strong>,
          }}
        />
      );
    }

    return infoMessages;
  };

  return (
    <>
      <Status
        info={
          <Grid container direction="column" justifyContent="center" spacing={2}>
            {successInfo().map((item, index) => (
              <Grid item key={index.toString()} xs={12}>
                {item}
              </Grid>
            ))}
          </Grid>
        }
        label={<FormattedMessage {...messages.activationStatusSuccessInfoLabel} />}
        type="success"
      />
      {companyId && siteId && (
        <Box py={2}>
          <Grid container justifyContent="center">
            <Grid item>
              <ButtonLinkNew
                link={
                  billingType === BILLING_TYPE.PER_APARTMENT
                    ? PATHS.APARTMENTS_OVERVIEW.replace(':companyId', companyId.toString()).replace(
                        ':siteId',
                        siteId.toString()
                      )
                    : PATHS.DEVICES_OVERVIEW.replace(':companyId', companyId.toString()).replace(
                        ':siteId',
                        siteId.toString()
                      )
                }
                message={
                  <FormattedMessage
                    {...(billingType === BILLING_TYPE.PER_APARTMENT
                      ? messages.activationStatusSuccessApartmentsButton
                      : messages.activationStatusSuccessDevicesButton)}
                  />
                }
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}

export default ActivationStatusSuccess;

const creditsUsedMap = {
  [BILLING_TYPE.PER_APARTMENT]: messages.activationStatusSuccessInfoCreditsUsedPER_APARTMENT,
  [BILLING_TYPE.PER_DEVICE]: messages.activationStatusSuccessInfoCreditsUsedPER_DEVICE,
};

const creditPoolMap = {
  [BILLING_TYPE.PER_APARTMENT]: messages.activationStatusSuccessInfoPoolInfoPER_APARTMENT,
  [BILLING_TYPE.PER_DEVICE]: messages.activationStatusSuccessInfoPoolInfoPER_DEVICE,
};
