import React from 'react';
import { ElIntSingleFloorMapping } from '../../../../../apiRtk/be4feApi';
import { ElevatorGroupSelectItem } from '../FloorConfiguration';
import FloorMapping from './FloorMapping';

interface IFloorItem extends ElIntSingleFloorMapping {
  name: string;
  elevatorGroups: Array<ElevatorGroupSelectItem>;
}

export function FloorItem({ elevatorGroups, floorId, mappings, name }: IFloorItem) {
  return (
    <>
      {mappings.map((mapping, index) => {
        return (
          <>
            <FloorMapping
              key={`${mapping.buildingId}${mapping.elevatorGroupId}`}
              context={index + 1 === mappings.length ? 'add' : 'delete'}
              index={index}
              floorMapping={mapping}
              floorName={name}
              floorId={floorId}
              elevatorGroups={elevatorGroups}
            />
          </>
        );
      })}
    </>
  );
}
