import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import apartmentActions from '../../../modules/apartments';
import UseAvailableCreditMenuItem from '../../UseAvailableCreditMenuItem';
import ApartmentDataGridAction from '../ApartmentDataGridAction';
import DeleteApartment from './DeleteApartment';
import EditApartment from './EditApartment';
import PurchaseSubscription from './PurchaseSubscription';
import SetApartmentGroup from './SetApartmentGroup';

function ApartmentRowMenu({
  apartment,
  companyId,
  licenceModel,
  onDeleteApartment,
  onOpenAvailableCreditModal,
  onSetApartmentGroup,
  showActionButton,
  siteId,
}) {
  return (
    <Box>
      {showActionButton && (
        <ApartmentDataGridAction
          apartment={apartment}
          licenceModel={licenceModel}
          purchaseComponent={<PurchaseSubscription companyId={companyId} siteId={siteId} />}
          useAvailableCreditComponent={
            <UseAvailableCreditMenuItem onOpenAvailableCreditModal={onOpenAvailableCreditModal} />
          }
        />
      )}
      <EditApartment apartmentId={apartment.id} companyId={companyId} siteId={siteId} />
      <SetApartmentGroup onClick={() => onSetApartmentGroup(apartment.id)} />
      <Divider />
      <DeleteApartment
        apartmentId={apartment.id}
        disabled={apartment.devicesCount !== 0}
        onDeleteApartment={onDeleteApartment}
      />
    </Box>
  );
}

ApartmentRowMenu.propTypes = {
  apartment: PropTypes.shape({
    devicesCount: PropTypes.number,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  licenceModel: PropTypes.shape({
    unlicensedCount: PropTypes.number,
  }).isRequired,
  onDeleteApartment: PropTypes.func.isRequired,
  onOpenAvailableCreditModal: PropTypes.func.isRequired,
  onSetApartmentGroup: PropTypes.func.isRequired,
  showActionButton: PropTypes.bool.isRequired,
  siteId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

const mapDispatchToProps = {
  onDeleteApartment: apartmentActions.deleteApartmentShowModal,
  onSetApartmentGroup: apartmentActions.setApartmentAccessGroupShowModal,
};

export default connect(null, mapDispatchToProps)(ApartmentRowMenu);
