/* eslint-disable sort/destructuring-properties */
/* eslint-disable sort/object-properties */
import { api } from './api';
import {
  ICreateIntercomButtonsArg,
  ICreateIntercomButtonsResponse,
} from './legacyApi/callSettings/createIntercomButtons';
import {
  ICreateIntercomContactArg,
  ICreateIntercomContactResponse,
} from './legacyApi/callSettings/createIntercomContact';
import {
  ICreateIntercomFeatureArg,
  ICreateIntercomFeatureResponse,
} from './legacyApi/callSettings/createIntercomFeature';
import { IDeleteIntercomContactArg, IDeleteIntercomFeatureArg } from './legacyApi/callSettings/deleteApi';
import { IEditIntercomButtonResponse, IEditIntercomButtonsArg } from './legacyApi/callSettings/editIntercomButtons';
import {
  IEditIntercomContactListArg,
  IEditIntercomContactListResponse,
} from './legacyApi/callSettings/editIntercomContactList';
import { IStateResponse } from './legacyApi/legacyApiCommon';

export enum RequestMethod {
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    // Creates intercom feature https://my2nv2resources1.docs.apiary.io/#reference/site-ip-intercom-features/create-ip-intercom-device-feature/post
    createIntercomFeature: build.mutation<ICreateIntercomFeatureResponse, ICreateIntercomFeatureArg>({
      query: (queryArg) => ({
        body: queryArg.body,
        method: RequestMethod.POST,
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/devices/${queryArg.deviceId}/features`,
      }),
    }),
    // create intercom contact https://my2nv2resources1.docs.apiary.io/#reference/site-ip-intercom-contact_list-contacts/create-a-new-ip-intercom-contact-and-edit-all-contactlist-contacts/post
    createIntercomContact: build.mutation<ICreateIntercomContactResponse, ICreateIntercomContactArg>({
      query: (queryArg) => ({
        body: queryArg.body,
        method: RequestMethod.POST,
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/devices/${queryArg.deviceId}/features/CONTACT_LIST/${queryArg.featureId}/contacts`,
      }),
    }),
    // create intercom button configuration https://my2nv2resources1.docs.apiary.io/#reference/site-ip-intercom-button_configuration-and-display-feature-buttons/create-a-new-button-and-edit-all-buttons-of-a-button-holder/post
    createIntercomButtons: build.mutation<ICreateIntercomButtonsResponse, ICreateIntercomButtonsArg>({
      query: (queryArg) => ({
        body: queryArg.body,
        method: RequestMethod.POST,
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/devices/${queryArg.deviceId}/features/${queryArg.featureType}/${queryArg.featureId}/buttons`,
      }),
    }),
    // Edit intercom contact list https://my2nv2resources1.docs.apiary.io/#reference/site-ip-intercom-contact_list-contacts/get-edit-and-delete-an-ip-intercom-contact/put
    editIntercomContactList: build.mutation<IEditIntercomContactListResponse, IEditIntercomContactListArg>({
      query: (queryArg) => ({
        body: queryArg.body,
        method: RequestMethod.PUT,
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/devices/${queryArg.deviceId}/features/CONTACT_LIST/${queryArg.featureId}/contacts/${queryArg.contactId}`,
      }),
    }),
    // Edit intercom buttons https://my2nv2resources1.docs.apiary.io/#reference/site-ip-intercom-button_configuration-and-display-feature-buttons/get-edit-and-delete-button/put
    editIntercomButtons: build.mutation<IEditIntercomButtonResponse, IEditIntercomButtonsArg>({
      query: (queryArg) => ({
        body: queryArg.body,
        method: RequestMethod.PUT,
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/devices/${queryArg.deviceId}/features/${queryArg.type}/${queryArg.featureId}/buttons/${queryArg.buttonId}`,
      }),
    }),
    // Delete intercom contact https://my2nv2resources1.docs.apiary.io/#reference/site-ip-intercom-contact_list-contacts/get-edit-and-delete-an-ip-intercom-contact/delete
    deleteIntercomContact: build.mutation<IStateResponse, IDeleteIntercomContactArg>({
      query: (queryArg) => ({
        body: undefined,
        method: RequestMethod.DELETE,
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/devices/${queryArg.deviceId}/features/CONTACT_LIST/${queryArg.featureId}/contacts/${queryArg.contactId}`,
      }),
    }),
    // Delete intercom feature button https://my2nv2resources1.docs.apiary.io/#reference/site-ip-intercom-button_configuration-and-display-feature-buttons/get-edit-and-delete-button/delete
    deleteIntercomFeature: build.mutation<IStateResponse, IDeleteIntercomFeatureArg>({
      query: (queryArg) => ({
        body: undefined,
        method: RequestMethod.DELETE,
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/devices/${queryArg.deviceId}/features/${queryArg.type}/${queryArg.featureId}/buttons/${queryArg.buttonId}`,
      }),
    }),
  }),
  overrideExisting: false,
});

export { injectedRtkApi as legacyFeApi };

export const {
  useCreateIntercomFeatureMutation,
  useCreateIntercomContactMutation,
  useCreateIntercomButtonsMutation,
  useEditIntercomContactListMutation,
  useEditIntercomButtonsMutation,
  useDeleteIntercomContactMutation,
  useDeleteIntercomFeatureMutation,
} = injectedRtkApi;
