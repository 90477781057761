import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import DialpadIcon from '@mui/icons-material/Dialpad';
import ReportProblem from '@mui/icons-material/ReportProblem';
import SendIcon from '@mui/icons-material/Send';
import { Box } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  CredentialsValidityResponseDto,
  useCreateGuestPinWithoutAccessQuery,
  useGetPinForUserQuery,
} from '../../../../../apiRtk/accessControlApi';
import {
  CreateGuestAssignPinRequest,
  EditGuestRequest,
  useCreateGuestAndAssignPinInApartmentMutation,
  useCreateGuestAndAssignPinInSiteMutation,
  useEditGuestInApartmentMutation,
  useEditGuestInSiteMutation,
} from '../../../../../apiRtk/be4feApi';
import { useCreateApartmentGuestPinWithoutAccessQuery } from '../../../../../apiRtk/gatewayApi';
import { ActionModal } from '../../../../../components/_DesignSystemWrappers/Modal';
import Alert from '../../../../../components/Alert';
import Checkbox from '../../../../../components/HookFormFields/Checkbox';
import DateTimePicker from '../../../../../components/HookFormFields/DateTimePicker';
import { OutlinedTextField } from '../../../../../components/HookFormFields/TextField';
import { PinPanel } from '../../../../../components/PinPanel';
import { EnrichedIUser } from '../../../../../components/UserAccessForm/SectionPin';
import { DATE_TIME_FORMAT, MULTIPLE_EMAIL_SEPARATOR } from '../../../../../config/consts';
import { useGetGlobalHooks } from '../../../../../containers/ApartmentDetailNew/ApartmentUsers/hooks/getGlobalHooks';
import { getDataGridSelector } from '../../../../../helpers/dataGrid';
import useBreakpoints from '../../../../../helpers/useBreakpoints';
import { useGetGlobalInfo } from '../../../../../hooks/useGetGlobalInfo';
import { getUserPin, getUsersInApartments } from '../../../../../modules/apartmentAdmin/actions';
import { ApartmentUser, EnrichedApartmentUser } from '../../../../../modules/apartmentAdmin/data';
import { getUserPinSelector } from '../../../../../modules/apartmentAdmin/selectors';
import actions, { NAME } from '../../../../../modules/apartments';
import { getApartmentDetail, getApartmentDevicesList } from '../../../../../modules/apartments/actions';
import { getApartmentData } from '../../../../../modules/apartments/selectors';
import { IModalBase, TId, VisitorModalContext } from '../../../../../modules/commonTypes';
import { TYPE_DESCRIPTOR } from '../../../../../modules/notifications';
import { addSnackbarError, addSnackbarSuccess } from '../../../../../modules/notifications/actions';
import { CONTEXT, selectors } from '../../../../../modules/user';
import { getUserPin as refetchUserPin } from '../../../../../modules/user/actions';
import { detailUserRequest, getSiteUsersList } from '../../../../../modules/user/actionsTS';
import { getMessage, VisitorModalMsgContext } from './helpers';
import { FormFieldNames, getDefaultValues, getValidationSchema } from './validationSchema';

interface IVisitorModal extends IModalBase {
  msgContext: VisitorModalMsgContext;
  user?: ApartmentUser | EnrichedApartmentUser | EnrichedIUser;
  userCredentialsValidity?: CredentialsValidityResponseDto;
  apartmentInfo: {
    siteId: TId;
    apartmentId?: TId;
    companyId: TId;
  };
  siteName?: string;
  isUserList?: boolean;
  apartmentDevices?: [];
  isRegenerate?: boolean;
}

function VisitorModal({
  apartmentDevices,
  apartmentInfo,
  isRegenerate,
  isUserList,
  msgContext,
  onClose,
  open,
  siteName,
  user,
  userCredentialsValidity,
}: IVisitorModal) {
  const { upSm } = useBreakpoints();
  const isApartment = apartmentInfo.apartmentId !== undefined;
  const isEdit = user !== undefined && !isRegenerate;
  const isRegenerateEdit = user !== undefined && isRegenerate;
  const { dispatch, formatMessage } = useGetGlobalInfo();
  const { urlParams } = useGetGlobalHooks();
  const { apartmentId } = urlParams;
  const isApartmentDefined = apartmentId && apartmentInfo.companyId && apartmentInfo.siteId;
  const isSiteDefined = !apartmentId && apartmentInfo.companyId && apartmentInfo.siteId;
  const { siteUsersDataGrid } = {
    siteUsersDataGrid: useSelector(selectors.getSiteUsersDataGridProps),
  };
  const apartmentUsersDataGrid = useSelector((state) => getDataGridSelector(state, NAME, 'apartmentUsers.list'));
  const [createGuestAndAssignPinInApartment, { isLoading: createGuestAndAssignPinInApartmentIsLoading }] =
    useCreateGuestAndAssignPinInApartmentMutation();
  const [createGuestAndAssignPinInSite, { isLoading: createGuestAndAssignPinInSiteIsLoading }] =
    useCreateGuestAndAssignPinInSiteMutation();
  const [editGuestInApartment, { isLoading: editGuestInApartmentIsLoading }] = useEditGuestInApartmentMutation();
  const [editGuestInSite, { isLoading: editGuestInSiteIsLoading }] = useEditGuestInSiteMutation();
  const {
    data: pinForUser,
    error: pinForUserError,
    isLoading: pinForUserIsLoading,
    refetch: pinForUserRefetch,
  } = useGetPinForUserQuery(
    {
      companyId: apartmentInfo.companyId.toString(),
      siteId: apartmentInfo.siteId.toString(),
      userId: user?.id.toString() || '',
    },
    { skip: !isEdit || isApartment }
  );
  const {
    data: pinWithoutAccess,
    error: pinWithoutAccessError,
    isLoading: pinWithoutAccessIsLoading,
    refetch: pinWithoutAccessRefetch,
  } = useCreateGuestPinWithoutAccessQuery(
    {
      companyId: apartmentInfo.companyId.toString(),
      siteId: apartmentInfo.siteId.toString(),
    },
    { skip: isApartment || isEdit }
  );

  const {
    data: apartmentPinWithoutAccess,
    error: apartmentPinWithoutAccessError,
    isLoading: apartmentPinWithoutAccessIsLoading,
    refetch: apartmentPinWithoutAccessRefetch,
  } = useCreateApartmentGuestPinWithoutAccessQuery(
    {
      apartmentId: apartmentInfo.apartmentId?.toString() || '',
      companyId: apartmentInfo.companyId.toString(),
      siteId: apartmentInfo.siteId.toString(),
    },
    { skip: !isApartment || isEdit }
  );
  const {
    data: apartmentAdminPin,
    error: apartmentAdminPinError,
    isFetching: apartmentAdminPinIsLoading,
  } = useSelector(getUserPinSelector);

  const formMethods = useForm({
    defaultValues: getDefaultValues(siteName, user, isRegenerate, userCredentialsValidity),
    mode: 'onChange',
    resolver: yupResolver(getValidationSchema(formatMessage, isEdit, msgContext)),
  });
  const sendInfoEmail = !!formMethods.watch(FormFieldNames.SendInfoEmail);
  const emailAddressesValue = !!formMethods.watch(FormFieldNames.EmailAddresses);
  const [apartmentAccessPossible, setApartmentAccessPossible] = useState(false);
  const apartment = useSelector(getApartmentData);

  useEffect(() => {
    if (apartmentInfo.apartmentId && !isEdit) {
      dispatch(getApartmentDetail(apartmentInfo.companyId, apartmentInfo.siteId, apartmentInfo.apartmentId));
    }
  }, []);

  useEffect(() => {
    if (isApartment && isEdit) {
      apartmentAdminPin?.pin && formMethods.setValue(FormFieldNames.Pin, apartmentAdminPin.pin);
    } else if (isApartment && !isEdit) {
      apartmentPinWithoutAccess && formMethods.setValue(FormFieldNames.Pin, apartmentPinWithoutAccess.pin);
    } else if (!isApartment && isEdit) {
      pinForUser?.pin && formMethods.setValue(FormFieldNames.Pin, pinForUser.pin);
    } else {
      pinWithoutAccess?.pin && formMethods.setValue(FormFieldNames.Pin, pinWithoutAccess.pin);
    }
  }, [pinWithoutAccess, apartmentPinWithoutAccess, apartmentAdminPin, pinForUser]);

  useEffect(() => {
    formMethods.formState.isValid && formMethods.clearErrors();
  }, [formMethods.formState.isValid]);

  function generatePin() {
    if (isApartment && !isEdit) {
      apartmentPinWithoutAccessRefetch();
    } else if (isApartment && isEdit) {
      dispatch(getUserPin(apartmentInfo.companyId, apartmentInfo.siteId, Number(apartmentInfo?.apartmentId), user.id));
    } else if (!isApartment && isEdit) {
      pinForUserRefetch();
    } else {
      pinWithoutAccessRefetch();
    }
  }

  function createApartmentGuest(createGuestAssignPinRequest: CreateGuestAssignPinRequest) {
    createGuestAndAssignPinInApartment({
      apartmentId: apartmentInfo.apartmentId!,
      companyId: apartmentInfo.companyId,
      createGuestAssignPinRequest,
      siteId: apartmentInfo.siteId,
    })
      .unwrap()
      .then(() => {
        dispatch(
          addSnackbarSuccess(
            formMethods.watch(FormFieldNames.EmailAddresses)?.length
              ? { ...getMessage(msgContext, 'MsgSucc2') }
              : { ...getMessage(msgContext, 'MsgSucc1') }
          )
        );
      })
      .catch(() => dispatch(addSnackbarError({ ...getMessage(msgContext, 'MsgErr') }, TYPE_DESCRIPTOR)))
      .finally(() => {
        apartmentInfo.apartmentId && dispatch(getUsersInApartments());
        onClose();
      });
  }

  function createGuest(createGuestAssignPinRequest: CreateGuestAssignPinRequest) {
    createGuestAndAssignPinInSite({
      companyId: apartmentInfo.companyId,
      createGuestAssignPinRequest,
      siteId: apartmentInfo.siteId,
    })
      .unwrap()
      .then(() => {
        dispatch(
          addSnackbarSuccess(
            formMethods.watch(FormFieldNames.EmailAddresses)?.length
              ? { ...getMessage(msgContext, 'MsgSucc2') }
              : { ...getMessage(msgContext, 'MsgSucc1') }
          )
        );
      })
      .catch(() => dispatch(addSnackbarError({ ...getMessage(msgContext, 'MsgErr') }, TYPE_DESCRIPTOR)))
      .finally(() => {
        apartmentInfo.apartmentId && dispatch(getUsersInApartments());
        onClose();
      });
  }

  function editGuest(user: ApartmentUser | EnrichedApartmentUser | EnrichedIUser, isApartmentGuest: boolean) {
    const formValues = formMethods.getValues();
    const editGuestRequest: EditGuestRequest = {
      apartmentAccess: formValues.apartmentAccess,
      emails:
        formValues.sendInfoEmail && formValues.emailAddresses
          ? formValues.emailAddresses.split(MULTIPLE_EMAIL_SEPARATOR)
          : [],
      lastName: formValues.lastName,
      pin: formValues.pin,
      site: formValues.siteNameInEmail && formValues.siteNameInEmail,
      validFrom: formValues.startOfValidity && format(formValues.startOfValidity, DATE_TIME_FORMAT),
      validTo: formValues.endOfValidity && format(formValues.endOfValidity, DATE_TIME_FORMAT),
    };

    isApartmentGuest
      ? editGuestInApartment({
          apartmentId: apartmentInfo.apartmentId!,
          companyId: apartmentInfo.companyId,
          editGuestRequest,
          siteId: apartmentInfo.siteId,
          userId: user.id,
        })
          .unwrap()
          .then(() => {
            dispatch(addSnackbarSuccess({ ...getMessage(msgContext, 'MsgSucc1') }));
          })
          .catch(() => dispatch(addSnackbarError({ ...getMessage(msgContext, 'MsgErr') }, TYPE_DESCRIPTOR)))
          .finally(() => {
            apartmentInfo.apartmentId && dispatch(getUsersInApartments());
            onClose();
          })
      : editGuestInSite({
          companyId: apartmentInfo.companyId,
          editGuestRequest,
          siteId: apartmentInfo.siteId,
          userId: user.id,
        })
          .unwrap()
          .then(() => {
            dispatch(addSnackbarSuccess({ ...getMessage(msgContext, 'MsgSucc1') }));
          })
          .catch(() => dispatch(addSnackbarError({ ...getMessage(msgContext, 'MsgErr') }, TYPE_DESCRIPTOR)))
          .finally(() => {
            if (user.id) {
              dispatch(refetchUserPin(apartmentInfo.companyId, apartmentInfo.siteId, user.id)),
                dispatch(detailUserRequest(CONTEXT.SITE_USERS)(apartmentInfo.companyId, apartmentInfo.siteId, user.id));
            }
            if (isSiteDefined) {
              dispatch(
                getSiteUsersList(
                  apartmentInfo.companyId,
                  apartmentInfo.siteId,
                  siteUsersDataGrid.page,
                  siteUsersDataGrid.rowsPerPage,
                  siteUsersDataGrid.filter,
                  siteUsersDataGrid.order
                )
              );
            }
            if (isApartmentDefined) {
              dispatch(
                actions.getApartmentUsers(
                  apartmentId,
                  apartmentInfo.companyId,
                  apartmentInfo.siteId,
                  apartmentUsersDataGrid.page,
                  apartmentUsersDataGrid.rowsPerPage,
                  apartmentUsersDataGrid.filter,
                  apartmentUsersDataGrid.order
                )
              );
            }
            onClose();
          });
  }

  function handleSubmit() {
    const formValues = formMethods.getValues();
    const createGuestAssignPinRequest: CreateGuestAssignPinRequest = {
      apartmentAccess: formValues.apartmentAccess,
      emails: formValues.emailAddresses ? formValues.emailAddresses.split(MULTIPLE_EMAIL_SEPARATOR) : [],
      lastName: formValues.lastName,
      pin: formValues.pin,
      site: formValues.siteNameInEmail && formValues.siteNameInEmail,
      validFrom: formValues.startOfValidity && format(formValues.startOfValidity, DATE_TIME_FORMAT),
      validTo: formValues.endOfValidity && format(formValues.endOfValidity, DATE_TIME_FORMAT),
    };

    isApartment
      ? isEdit || isRegenerateEdit
        ? editGuest(user, true)
        : createApartmentGuest(createGuestAssignPinRequest)
      : isEdit || isRegenerateEdit
      ? editGuest(user, false)
      : createGuest(createGuestAssignPinRequest);
  }

  useEffect(() => {
    const enrichedUser = user as EnrichedApartmentUser;
    const isApartmentEmpty = enrichedUser?.apartments?.length === 0 && apartmentInfo.apartmentId === undefined;
    const isNewGuestModal = msgContext === VisitorModalContext.USER_LIST_NEW_GUESTMODAL;

    if (isApartmentEmpty || isNewGuestModal) {
      setApartmentAccessPossible(false);
      return;
    }
    const apartmentId = isApartment ? apartmentInfo.apartmentId! : enrichedUser?.apartments[0]?.id;
    dispatch(getApartmentDevicesList(apartmentId, apartmentInfo.companyId, apartmentInfo.siteId));
  }, [open]);

  useEffect(() => {
    if (apartmentDevices?.length) {
      setApartmentAccessPossible(
        apartmentDevices.some(
          (device: { services: Record<string, { active: boolean }> }) => device.services?.ACCESS_CONTROL?.active
        )
      );
      return;
    }
    setApartmentAccessPossible(false);
  }, [apartmentDevices]);

  return (
    <FormProvider {...formMethods}>
      <ActionModal
        icon={sendInfoEmail ? <SendIcon /> : <CheckIcon />}
        muiDialogProps={{ maxWidth: 'xs' }}
        isPrimaryButtonDisabled={
          !isRegenerate &&
          (!formMethods.formState.isDirty ||
            !formMethods.formState.isValid ||
            createGuestAndAssignPinInApartmentIsLoading ||
            createGuestAndAssignPinInSiteIsLoading ||
            editGuestInApartmentIsLoading ||
            editGuestInSiteIsLoading)
        }
        onClose={onClose}
        primaryButtonAction={handleSubmit}
        primaryButtonText={formatMessage(
          emailAddressesValue
            ? isUserList
              ? getMessage(msgContext, 'Submit2')
              : getMessage(msgContext, 'Submit2')
            : getMessage(msgContext, 'Submit')
        )}
        secondaryButtonText={formatMessage(getMessage(msgContext, 'Cancel'))}
        open={open}
        title={formatMessage(getMessage(msgContext, 'Title'))}
      >
        <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
          {isUserList && (
            <Box pt={2}>
              <Typography>
                <FormattedMessage {...getMessage(msgContext, 'Text1')}></FormattedMessage>
              </Typography>
              <Alert sx={{ py: 2 }} severity="warning" icon={upSm ? <ReportProblem /> : false}>
                <FormattedMessage {...getMessage(msgContext, 'Alert1')}></FormattedMessage>
              </Alert>
            </Box>
          )}
          {isRegenerate && (
            <>
              <Box pt={2} pb={2}>
                <FormattedMessage {...getMessage(msgContext, 'Text1')}></FormattedMessage>
              </Box>
              {isApartment && (
                <Box pb={2}>
                  <FormattedMessage
                    values={{
                      apartment: `${apartment?.number} ${apartment?.name ? apartment?.name : ''}`,
                    }}
                    {...getMessage(msgContext, 'Apartment')}
                  ></FormattedMessage>
                </Box>
              )}
            </>
          )}
          {!isRegenerate && !isEdit && isApartment && apartment?.number && (
            <>
              <Box pt={2} pb={2}>
                <FormattedMessage {...getMessage(msgContext, 'Text1')}></FormattedMessage>
              </Box>
              <Box pb={2}>
                <FormattedMessage
                  values={{
                    apartment: `${apartment?.number} ${apartment?.name ? apartment?.name : ''}`,
                  }}
                  {...getMessage(msgContext, 'Apartment')}
                ></FormattedMessage>
              </Box>
            </>
          )}
          {isEdit && (
            <Box pt={2} pb={2}>
              <Typography>
                <FormattedMessage {...getMessage(msgContext, 'Text1')}></FormattedMessage>
              </Typography>
            </Box>
          )}
          {!isEdit && (
            <Box pt={2}>
              <OutlinedTextField
                fullWidth
                required
                name={FormFieldNames.LastName}
                placeholder={formatMessage(getMessage(msgContext, 'PinnamePlaceholder'))}
                helperText={formatMessage(getMessage(msgContext, 'PinnameHelper'))}
                label={formatMessage(getMessage(msgContext, 'PinnameLabel'))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DialpadIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          )}
          <Box pt={2} pb={2}>
            <PinPanel
              isReadOnly={isEdit}
              isFetching={
                apartmentAdminPinIsLoading ||
                apartmentPinWithoutAccessIsLoading ||
                pinForUserIsLoading ||
                pinWithoutAccessIsLoading
              }
              pin={
                isEdit
                  ? isApartment
                    ? apartmentAdminPin?.pin
                    : pinForUser?.pin
                  : isApartment
                  ? apartmentPinWithoutAccess?.pin
                  : pinWithoutAccess?.pin
              }
              onGenerate={generatePin}
              onInit={generatePin}
              hasError={
                !!apartmentAdminPinError ||
                !!apartmentPinWithoutAccessError ||
                !!pinForUserError ||
                !!pinWithoutAccessError
              }
            />
          </Box>
          {apartmentAccessPossible && (
            <Box pb={2}>
              <Checkbox
                name="apartmentAccess"
                label={
                  <Typography variant="body1">
                    <FormattedMessage {...getMessage(msgContext, 'AprtmDirectLabel')} />
                  </Typography>
                }
                color="warning"
              />
            </Box>
          )}
          <Box pt={2}>
            <DateTimePicker
              format="dd.M.yyyy H:mm"
              sx={{ width: '100%' }}
              name={FormFieldNames.StartOfValidity}
              placeholder={formatMessage(getMessage(msgContext, 'StartPlaceholder'))}
              slotProps={{
                textField: {
                  inputProps: {
                    disabled: upSm,
                  },
                },
              }}
              label={formatMessage(getMessage(msgContext, 'StartLabel'))}
              ampm={false}
            />
          </Box>
          <Box pt={2}>
            <DateTimePicker
              format="dd.M.yyyy H:mm"
              sx={{ width: '100%' }}
              placeholder={formatMessage(getMessage(msgContext, 'EndPlaceholder'))}
              name={FormFieldNames.EndOfValidity}
              label={formatMessage(getMessage(msgContext, 'EndLabel'))}
              slotProps={{
                textField: {
                  inputProps: {
                    disabled: upSm,
                  },
                },
              }}
              ampm={false}
            />
          </Box>
          {isEdit && (
            <Box pt={1}>
              <Checkbox name="sendInfoEmail" label={formatMessage(getMessage(msgContext, 'SendEmailLabel'))} />
            </Box>
          )}
          {formMethods.watch('sendInfoEmail') && (
            <Box pt={2}>
              <OutlinedTextField
                fullWidth
                type="email"
                name={FormFieldNames.EmailAddresses}
                placeholder={formatMessage(getMessage(msgContext, 'EmailsPlaceholder'))}
                helperText={formatMessage(getMessage(msgContext, 'EmailsHelper'))}
                label={formatMessage(getMessage(msgContext, 'EmailsLabel'))}
              />
            </Box>
          )}
          {formMethods.watch('sendInfoEmail') && (
            <Box pt={2} mb={4}>
              <OutlinedTextField
                fullWidth
                name={FormFieldNames.SiteNameInEmail}
                helperText={formatMessage(getMessage(msgContext, 'SitenameHelper'))}
                label={formatMessage(getMessage(msgContext, 'SitenameLabel'))}
                placeholder={formatMessage(getMessage(msgContext, 'SitenamePlaceholder'))}
                inputProps={{
                  autoComplete: 'site-name-in-email',
                }}
              />
            </Box>
          )}
        </form>
      </ActionModal>
    </FormProvider>
  );
}

export default VisitorModal;
