import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomDate } from '../../../../components';
import messages from './messages';

const CreditSummary = ({ codeData }) => (
  <>
    <Typography>
      <FormattedMessage
        {...messages.creditSummaryAmount}
        values={{
          pool: codeData.activation.licencesPool.toFixed(1),
          strong: (chunks) => <strong>{chunks}</strong>,
        }}
      />
    </Typography>
    <Typography>
      <FormattedMessage
        {...messages.creditSummaryInfo}
        values={{
          expiration: codeData.activation.expiration ? (
            <CustomDate dateValue={codeData.activation.expiration} />
          ) : (
            <FormattedMessage {...messages.creditSummaryNoExpirationDate} />
          ),
        }}
      />
    </Typography>
  </>
);

CreditSummary.propTypes = {
  codeData: PropTypes.shape({
    activation: PropTypes.shape({
      expiration: PropTypes.string,
      initialConsumption: PropTypes.number,
      licencesPool: PropTypes.number,
    }),
    configuration: PropTypes.shape({
      credit: PropTypes.number,
    }),
  }).isRequired,
};

export default CreditSummary;
