import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { ElIntElevatorGroup, useElevatorIntegrationFloorMappingQuery } from '../../../../apiRtk/be4feApi';
import { useGetLoadedParams } from '../../../../hooks/paramsHooks';
import { useGetGlobalInfo } from '../../../../hooks/useGetGlobalInfo';
import { useTypedSelector } from '../../../../modules/configureStore';
import {
  elevatorIntegrationSlice,
  selectElevatorIntegration,
} from '../../../../modules/my2nApi/elevatorIntegrationSlice';
import styles from '../../../SiteUserDetail/Detail/UserInfoForm/styles/UserInfoForm';
import FloorItem from './FloorItem';
import { getElevatorGroupSelectItems } from './helpers';
import messages from './messages';

export interface ElevatorGroupSelectItem {
  id: string;
  elevatorGroup: ElIntElevatorGroup;
  buildingId: string;
}

export function FloorConfiguration() {
  const { dispatch } = useGetGlobalInfo();
  const { companyId, siteId } = useGetLoadedParams();
  const { data: siteElevatorIntegrationFloorMappingData, isSuccess: siteElevatorIntegrationFloorMappingSuccess } =
    useElevatorIntegrationFloorMappingQuery({ companyId: companyId!, siteId: siteId! });
  const elevatorIntegrationFloorMappingStore = useTypedSelector(selectElevatorIntegration);

  useEffect(() => {
    if (siteElevatorIntegrationFloorMappingSuccess) {
      dispatch(elevatorIntegrationSlice.actions.addElevatorIntegration(siteElevatorIntegrationFloorMappingData));
    }
  }, [siteElevatorIntegrationFloorMappingSuccess]);

  function getFloorName(floorId: string) {
    return siteElevatorIntegrationFloorMappingData?.floors.find((floor) => floor.id === floorId)?.name || '';
  }

  return (
    <>
      <Typography sx={styles.formTitle} variant="h6">
        <FormattedMessage {...messages.elevatorIntegrationMappingTitle} />
      </Typography>
      <Grid container spacing={1} rowSpacing={0} sx={{ mt: 2 }}>
        <Grid item xs={2}>
          <Typography variant="body2" color="secondary">
            <FormattedMessage {...messages.elevatorIntegrationMappingHeaderMy2Nfloor} />
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" color="secondary">
            <FormattedMessage {...messages.elevatorIntegrationMappingHeaderElevatorCore} />
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" color="secondary">
            <FormattedMessage {...messages.elevatorIntegrationMappingHeaderDestination} />
          </Typography>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {siteElevatorIntegrationFloorMappingSuccess &&
          elevatorIntegrationFloorMappingStore.floorMapping.floors.map((floor, index) => {
            return (
              <>
                <FloorItem
                  key={index}
                  floorId={floor.floorId}
                  name={getFloorName(floor.floorId)}
                  mappings={floor.mappings}
                  elevatorGroups={getElevatorGroupSelectItems(
                    siteElevatorIntegrationFloorMappingData.buildingTopology.buildings
                  )}
                />
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </>
            );
          })}
      </Grid>
    </>
  );
}
