import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import ActionModal from '../../../components/_DesignSystemWrappers/Modal/ActionModal';
import { SITE_TYPE } from '../../../config/sites';
import { IFloor, INewApartment } from '../../../modules/apartments/store';
import { IModalBase, TId } from '../../../modules/commonTypes';
import ApartmentDataForm from '../../ApartmentDetailNew/ApartmentInfo/ApartmentDataForm';
import { useGetApartmentFormData } from '../hooks/useGetApartmentFormData';
import messages from './messages';

interface IApartmentCreateModal extends IModalBase {
  floors: Array<IFloor>;
  onClose: () => void;
  onCreateApartment: (companyId: TId, siteId: TId, floors: Array<IFloor>, values: INewApartment) => void;
  siteType: SITE_TYPE;
}

function ApartmentCreateModal({ floors, onClose, onCreateApartment, open, siteType }: IApartmentCreateModal) {
  const { companyId, formatMessage, formMethods, licenceModel, siteId } = useGetApartmentFormData();

  const onSubmit = (values: INewApartment) => {
    if (companyId && siteId) {
      onCreateApartment(companyId, siteId, floors, values);
    }
    onClose();
  };

  return (
    <ActionModal
      icon={<AddIcon />}
      isPrimaryButtonDisabled={!formMethods.formState.isValid}
      muiDialogProps={{ maxWidth: 'xs' }}
      onClose={onClose}
      open={open}
      primaryButtonAction={formMethods.handleSubmit(onSubmit)}
      primaryButtonText={formatMessage(messages.apartmentCreateModalCreate)}
      secondaryButtonText={formatMessage(messages.apartmentCreateModalCancel)}
      title={formatMessage(messages.apartmentCreateModalTitle)}
    >
      <ApartmentDataForm
        floors={floors}
        formMethods={formMethods}
        isSiteMdu={siteType === SITE_TYPE.MDU}
        isModal
        licenceModel={licenceModel}
        onSubmit={onSubmit}
      />
    </ActionModal>
  );
}

export default ApartmentCreateModal;
