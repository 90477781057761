import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import mvideoSvg from '../../../assets/mvideo.svg';
import { ServicePanel } from '../../../components';
import { ANSWERING_MODE, CONFIGURATION_MODE, DEVICE_TYPE, SERVICE } from '../../../config/devices';
import { getIs2nDevice } from '../../../helpers/devices';
import { helpers } from '../../../modules/devices';
import { hasSupportedFirmware, isFirmwareStatusUnsupportedOrDeprecated } from '../helpers';
import messages from './messages';
import MobileVideoAppConnection from './MobileVideoAppConnection';
import { MobileVideoRenewSettings } from './MobileVideoRenewSettings';
import MobileVideoSettings from './MobileVideoSettings';
import MobileVideoSipProxy from './MobileVideoSipProxy';
import MobileVideoSiteStatus from './MobileVideoSiteStatus';
import MobileVideoServiceInfo from './MobileVideoStatusInfo';
import types from './types';
import useServiceBoxProps from './useServiceBoxProps';

const MobileVideoService = ({
  device,
  firmware,
  isMvActivable,
  onChangeMobileVideoStatus,
  onCreateServiceConfiguration,
  onRenewConfiguration,
  onUpdateFeatureFaceTracking,
  onUpdateService,
  site,
}) => {
  const [state, serviceBoxDispatch] = useServiceBoxProps(device);
  const active =
    helpers.isActiveService(device, SERVICE.MOBILE_VIDEO) || helpers.isActiveService(device, SERVICE.LOCAL_CALLS);
  const VIDEO_QUALITY_LIMIT_FIRMWARE = '2.32.4.41.2';
  const isIntercom = () =>
    [DEVICE_TYPE.IP_INTERCOM, DEVICE_TYPE.IP_INTERCOM_V2, DEVICE_TYPE.IP_STYLE, DEVICE_TYPE.IP_ONE_INTERCOM].includes(
      device.type
    );
  const isACSDisabled = isIntercom() && !device?.services?.[SERVICE.ACS].active;

  const onToggleService = () => {
    if (!device?.services?.[SERVICE.MOBILE_VIDEO] && isMvActivable) {
      onCreateServiceConfiguration(device.id, SERVICE.MOBILE_VIDEO);
    } else {
      onChangeMobileVideoStatus({ active: !active });
    }
  };

  const isFirmwareStatusOutdated = useMemo(
    () => isFirmwareStatusUnsupportedOrDeprecated(device?.services?.[SERVICE.ACS]?.firmwareStatus),
    [device?.services?.[SERVICE.ACS]?.firmwareStatus]
  );

  const handleOnChange = (value, type) => serviceBoxDispatch({ payload: value, type });

  const handleSave = () => {
    let acsValue;
    let mobileVideoValue;
    if (state.configurationModeChanged) {
      acsValue = {
        active: state.configurationMode === CONFIGURATION_MODE.AUTOMATIC,
      };
    }

    if (state.cameraSwitchAllowedChanged || state.adaptiveVideoQualityChanged || state.answerModeChanged) {
      mobileVideoValue = {};
      if (state.answerModeChanged) {
        mobileVideoValue.answerMode = state.answerMode === true ? ANSWERING_MODE.AUTO : ANSWERING_MODE.OFF;
      }
      mobileVideoValue.adaptiveVideoQuality = state.adaptiveVideoQuality;
      mobileVideoValue.cameraSwitchAllowed = state.cameraSwitchAllowed;
    }
    onUpdateService({ acsValue, mobileVideoValue });

    if (state.faceTrackingModeChanged) {
      onUpdateFeatureFaceTracking({
        faceTrackingMode: state.faceTrackingMode,
      });
    }
    serviceBoxDispatch({ type: types.RESET });
  };

  const getConfirmInfo = () => {
    if (active) {
      return (
        <>
          <Typography>
            <FormattedMessage {...messages.mobileVideoServiceModalDeactivate} />
          </Typography>
          {isIntercom() && (
            <Typography sx={{ mt: 1.5 }}>
              <FormattedMessage {...messages.mobileVideoServiceModalDeactivateIPIntercom} />
            </Typography>
          )}
        </>
      );
    }

    return (
      <>
        <Typography>
          <FormattedMessage {...messages.mobileVideoServiceModalActivate} />
        </Typography>
        {isIntercom() && (
          <Typography sx={{ mt: 1.5 }}>
            <FormattedMessage {...messages.mobileVideoServiceModalActivateIPIntercom} />
          </Typography>
        )}
      </>
    );
  };

  const getConfirmTitle = () =>
    active ? (
      <FormattedMessage {...messages.mobileVideoServiceModalTitleDisable} />
    ) : (
      <FormattedMessage {...messages.mobileVideoServiceModalTitleEnable} />
    );

  return (
    <ServicePanel
      active={active}
      disabled={device.services?.[SERVICE.MOBILE_VIDEO] === undefined}
      icon={<img alt="Mobile Video" src={mvideoSvg} />}
      isServiceAvailable={isMvActivable}
      name={
        <FormattedMessage
          tagName="strong"
          values={{ sup: (chunks) => <sup>{chunks}</sup> }}
          {...messages.mobileVideoServiceMobileVideo}
        />
      }
      onToggleConfirm={!device.services?.[SERVICE.LOCAL_CALLS]?.active ? onToggleService : null}
      state={
        <MobileVideoSiteStatus
          active={active}
          is2nDevice={getIs2nDevice(device.type)}
          licenceModel={site.services?.[SERVICE.MOBILE_VIDEO]?.licenceModel}
          subscribed={!!site.services?.[SERVICE.MOBILE_VIDEO]?.licenceModel?.nextPayment?.date}
        />
      }
      toggleConfirmInfo={getConfirmInfo()}
      toggleConfirmTitle={getConfirmTitle()}
    >
      <Grid container spacing={4}>
        <Grid container direction="column" item lg={4} md={6} xs={12}>
          <Grid item>
            <MobileVideoServiceInfo device={device} />
          </Grid>
          <Box pt={4}>
            <Grid item>
              <MobileVideoRenewSettings device={device} onRenewConfiguration={onRenewConfiguration} />
            </Grid>
          </Box>
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <MobileVideoSipProxy device={device} site={site} />
        </Grid>
        {device.type === DEVICE_TYPE.HIPMO && device?.services?.[SERVICE.NOTIFICATION]?.qrCodeUrl && (
          <Grid item lg={4} md={6} xs={12}>
            <MobileVideoAppConnection device={device} />
          </Grid>
        )}
        {isIntercom() && (
          <>
            <Grid item lg={4} md={6} xs={12}>
              <MobileVideoSettings
                adaptiveVideoQuality={state.adaptiveVideoQuality}
                adaptiveVideoQualitySupported={state.adaptiveVideoQualitySupported}
                answerMode={state.answerMode}
                cameraSwitchAllowed={state.cameraSwitchAllowed}
                companyId={device?.site?.company?.id}
                firmware={firmware}
                handleOnChange={handleOnChange}
                isACSDisabled={isACSDisabled}
                isAdaptiveQuality={hasSupportedFirmware(
                  device?.services?.[SERVICE.ACS]?.firmware,
                  VIDEO_QUALITY_LIMIT_FIRMWARE
                )}
                faceTrackingMode={state.faceTrackingMode}
              />
            </Grid>
            <Grid container item justifyContent="flex-end">
              <Grid item>
                <Button
                  color="primary"
                  disabled={
                    ![
                      state.cameraSwitchAllowedChanged,
                      state.adaptiveVideoQualityChanged,
                      state.faceTrackingModeChanged,
                      state.answerModeChanged,
                      state.configurationModeChanged,
                    ].includes(true) || isFirmwareStatusOutdated
                  }
                  onClick={handleSave}
                  startIcon={<DoneIcon />}
                  variant="contained"
                >
                  <FormattedMessage {...messages.buttonSave} />
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </ServicePanel>
  );
};

MobileVideoService.propTypes = {
  device: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    services: PropTypes.shape({
      [SERVICE.MOBILE_VIDEO]: PropTypes.shape({
        active: PropTypes.bool,
        adaptiveVideoQuality: PropTypes.bool,
        adaptiveVideoQualitySupported: PropTypes.bool,
        answerMode: PropTypes.string,
        cameraSwitchAllowed: PropTypes.bool,
      }),
      [SERVICE.LOCAL_CALLS]: PropTypes.shape({
        active: PropTypes.bool,
      }),
      [SERVICE.NOTIFICATION]: PropTypes.shape({
        qrCodeUrl: PropTypes.string,
      }),
      [SERVICE.ACS]: PropTypes.shape({
        firmware: PropTypes.string,
      }),
    }),
    site: PropTypes.shape({
      company: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    }),
    type: PropTypes.string,
  }),
  firmware: PropTypes.shape({
    info: PropTypes.shape({
      haveUpdate: PropTypes.bool,
      newFirmware: PropTypes.shape({
        version: PropTypes.string,
      }),
    }),
  }),
  isMvActivable: PropTypes.bool.isRequired,
  onChangeMobileVideoStatus: PropTypes.func.isRequired,
  onCreateServiceConfiguration: PropTypes.func.isRequired,
  onRenewConfiguration: PropTypes.func.isRequired,
  onUpdateFeatureFaceTracking: PropTypes.func.isRequired,
  onUpdateService: PropTypes.func.isRequired,
  site: PropTypes.shape({
    services: PropTypes.shape({
      [SERVICE.MOBILE_VIDEO]: PropTypes.shape({
        licenceModel: PropTypes.shape({
          nextPayment: PropTypes.shape({
            date: PropTypes.string,
          }),
          status: PropTypes.string,
          validityFrom: PropTypes.string,
          validityTo: PropTypes.string,
        }),
      }),
    }),
  }),
};

MobileVideoService.defaultProps = {
  device: {},
  firmware: {
    info: {
      haveUpdate: false,
      newFirmware: {
        releaseNotes: [],
        version: '',
      },
    },
    isUpdating: false,
  },
  site: {},
};

export default MobileVideoService;
