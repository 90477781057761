import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { BillingType } from '../../../../modules/commonTypes';
import { ICodeData } from '../../../../modules/subscription/store';
import messages from './messages';

interface ICurrentMonthSummary {
  billingType: BillingType;
  codeData: ICodeData;
  isPaidServicesApartmentFFEnabled: boolean;
}

function CurrentMonthSummary({ billingType, codeData, isPaidServicesApartmentFFEnabled }: ICurrentMonthSummary) {
  const apartmentBillingType = billingType === BillingType.PER_APARTMENT;
  const infoMessage = useMemo(() => {
    if (apartmentBillingType) {
      if (isPaidServicesApartmentFFEnabled) {
        return {
          label: messages.CurrentMonthSummaryInfoApartmentPaidServicesActive,
          title: messages.CurrentMonthSummaryConsumptionAdditional,
        };
      } else {
        return {
          label: messages.currentMonthSummaryInfoApartment,
          title: messages.CurrentMonthSummaryConsumption,
        };
      }
    } else {
      return {
        label: messages.currentMonthSummaryInfoDevices,
        title: messages.CurrentMonthSummaryConsumption,
      };
    }
  }, [apartmentBillingType, isPaidServicesApartmentFFEnabled]);

  return (
    <>
      <Typography>
        <FormattedMessage
          {...infoMessage.title}
          values={{
            credits: codeData.activation.initialConsumption.toFixed(1),
          }}
        />
      </Typography>
      <Typography color="textSecondary" variant="body2">
        <FormattedMessage {...infoMessage.label} />
      </Typography>
    </>
  );
}

export default CurrentMonthSummary;
