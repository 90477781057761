import LockIcon from '@mui/icons-material/Lock';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import { isObject } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Paper, Title } from '../../components';
import FormikFields from '../../components/FormikFields';
import actions, { selectors } from '../../modules/company';
import messages from './messages';
import ResetSettingsDialog from './ResetSettingsDialog';

const styles = {
  actions: {
    pb: 0,
    pt: 2,
    px: 0,
    textAlign: 'right',
  },
  adornment: {
    color: 'grey.500',
  },
  buttonWrapper: {
    mt: 1,
    textAlign: 'center',
  },
};

const MvSdkSettings = ({ data, isDefault, isLoading, onLoadSettings, onResetSettings, onSaveSettings }) => {
  const [isCustom, setIsCustom] = useState(!isDefault);
  const [isOpenResetDialog, setIsOpenResetDialog] = useState(false);

  useEffect(() => {
    onLoadSettings();
  }, []);

  useEffect(() => {
    setIsCustom(!isDefault);
  }, [isDefault]);

  const submitForm = (values, formActions) => {
    const val = {
      apnConfiguration: {
        authKey: values.authKey,
        bundleId: values.bundleId,
        keyId: values.keyId,
        teamId: values.teamId,
      },
      fcmConfiguration: {
        databaseName: values.databaseName,
        serviceJson: JSON.parse(values.serviceJson),
      },
    };
    onSaveSettings(val, formActions);
  };

  const handleResetSetting = () => {
    setIsOpenResetDialog(false);
    onResetSettings();
  };

  const getValidationSchema = () =>
    Yup.object().shape({
      authKey: Yup.string().required(<FormattedMessage {...messages.mvSdkSettingsRequired} />),
      bundleId: Yup.string().required(<FormattedMessage {...messages.mvSdkSettingsRequired} />),
      databaseName: Yup.string().required(<FormattedMessage {...messages.mvSdkSettingsRequired} />),
      keyId: Yup.string().required(<FormattedMessage {...messages.mvSdkSettingsRequired} />),
      serviceJson: Yup.string()
        .required(<FormattedMessage {...messages.mvSdkSettingsRequired} />)
        .test('json', <FormattedMessage {...messages.mvSdkSettingsInvalidFormat} />, (value) => {
          let valueObject;
          try {
            valueObject = JSON.parse(value);
          } catch (e) {
            return false;
          }
          return isObject(valueObject);
        }),
      teamId: Yup.string().required(<FormattedMessage {...messages.mvSdkSettingsRequired} />),
    });

  return (
    <>
      <Title heading={<FormattedMessage {...messages.mvSdkSettingsTitle} />} />
      <Paper>
        {!isLoading &&
          (isCustom ? (
            <div>
              <Typography variant="h6">
                <FormattedMessage {...messages.mvSdkSettingsAppleApn} />
              </Typography>
              <Formik
                enableReinitialize
                initialValues={data}
                isInitialValid={false}
                onSubmit={submitForm}
                render={({ isValid, values }) => (
                  <Form autoComplete="off" noValidate sx={styles.form}>
                    <FormikFields.Input
                      label={<FormattedMessage {...messages.mvSdkSettingsBundleId} />}
                      name="bundleId"
                      required
                    />
                    <FormikFields.Input
                      label={<FormattedMessage {...messages.mvSdkSettingsKeyId} />}
                      name="keyId"
                      required
                    />
                    <FormikFields.Input
                      label={<FormattedMessage {...messages.mvSdkSettingsTeamId} />}
                      name="teamId"
                      required
                    />
                    <FormikFields.Input
                      helperText={!values.authKey && <FormattedMessage {...messages.mvSdkSettingsHiddenValue} />}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start" sx={styles.adornment}>
                            <LockIcon />
                          </InputAdornment>
                        ),
                      }}
                      label={<FormattedMessage {...messages.mvSdkSettingsAuthKey} />}
                      multiline
                      name="authKey"
                      required
                    />
                    <Typography variant="h6">
                      <FormattedMessage {...messages.mvSdkSettingsGoogleFcm} />
                    </Typography>
                    <FormikFields.Input
                      label={<FormattedMessage {...messages.mvSdkSettingsDatabaseName} />}
                      name="databaseName"
                      required
                    />
                    <FormikFields.Input
                      helperText={!values.serviceJson && <FormattedMessage {...messages.mvSdkSettingsHiddenValue} />}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start" sx={styles.adornment}>
                            <LockIcon />
                          </InputAdornment>
                        ),
                      }}
                      label={<FormattedMessage {...messages.mvSdkSettingsServiceJson} />}
                      multiline
                      name="serviceJson"
                      required
                    />
                    <Box sx={styles.actions}>
                      <Button color="primary" disabled={isDefault} onClick={() => setIsOpenResetDialog(true)}>
                        <FormattedMessage {...messages.mvSdkSettingsResetToDefault} />
                      </Button>
                      <Button color="primary" disabled={!isValid} type="submit" variant="contained">
                        <FormattedMessage {...messages.mvSdkSettingsSave} />
                      </Button>
                    </Box>
                    <ResetSettingsDialog
                      onClose={() => setIsOpenResetDialog(false)}
                      onConfirm={handleResetSetting}
                      show={isOpenResetDialog}
                    />
                  </Form>
                )}
                validationSchema={getValidationSchema()}
              />
            </div>
          ) : (
            <div>
              <Typography variant="subtitle1">
                <FormattedMessage {...messages.mvSdkSettingsDefaultValues} />
              </Typography>
              <Box sx={styles.buttonWrapper}>
                <Button color="primary" onClick={() => setIsCustom(true)} variant="contained">
                  <FormattedMessage {...messages.mvSdkSettingsSetCustom} />
                </Button>
              </Box>
            </div>
          ))}
      </Paper>
    </>
  );
};

MvSdkSettings.propTypes = {
  data: PropTypes.shape({
    authKey: PropTypes.string,
    bundleId: PropTypes.string,
    databaseName: PropTypes.string,
    keyId: PropTypes.string,
    serviceJson: PropTypes.string,
    teamId: PropTypes.string,
  }),
  isDefault: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onLoadSettings: PropTypes.func.isRequired,
  onResetSettings: PropTypes.func.isRequired,
  onSaveSettings: PropTypes.func.isRequired,
};

MvSdkSettings.defaultProps = {
  data: {
    authKey: '',
    bundleId: '',
    databaseName: '',
    keyId: '',
    serviceJson: '',
    teamId: '',
  },
};

const mapStateToProps = (state) => ({
  data: selectors.getMvSdkSettingsSelector(state),
  isDefault: selectors.getMvSdkSettingsIsDefault(state),
  isLoading: selectors.getMvSdkSettingsIsLoading(state),
});

const mapDispatchToProps = {
  onLoadSettings: actions.getMvSdkSettings,
  onResetSettings: actions.resetMvSdkSettings,
  onSaveSettings: actions.updateMvSdkSetings,
};

export default connect(mapStateToProps, mapDispatchToProps)(MvSdkSettings);
