import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { headSiteData } from '../../../api/siteRequest';
import { FormikFields, LoadingButton } from '../../../components';
import { DEVICE_TYPE } from '../../../config/devices';
import actions, { selectors } from '../../../modules/devices';
import { addAxisDoorStationDevice } from '../actions';
import AddDeviceModal from '../AddDeviceModal';
import DeviceTitle from '../DeviceControl/DeviceTitle';
import FinishedStep from '../FinishedStep';
import messagesFinished from '../FinishedStep/messages';
import { getValidationParameters } from '../helpers';
import StatusMessage from '../StatusMessage';
import MacAddressField from './MacAddressField';
import messages from './messages';

const AxisDoorStationForm = ({
  finishedData,
  isFinished,
  onClearModal,
  onClearModalData,
  onClose,
  onSelectDevice,
  onSubmit,
  open,
  validationExistName,
}) => {
  const [step, setStep] = useState(1);
  useEffect(() => {
    if (isFinished && step !== 2) {
      setStep(2);
    }
  }, [isFinished, step]);

  const { companyId, siteId } = validationExistName;

  const getValidationSchema = () =>
    Yup.object().shape({
      macAddress: Yup.string()
        .required(<FormattedMessage {...messages.axisDoorStationFormRequired} />)
        .test('isRegExp', <FormattedMessage {...messages.axisDoorStationFormMacAddressNoValid} />, (value) =>
          new RegExp(/^(00-?40-?8C-?|AC-?CC-?8E-?|B8-?A4-?4F-?)([A-F0-9]{2})-?([A-F0-9]{2})-?([A-F0-9]{2})$/i).test(
            value
          )
        ),
      name: Yup.string()
        .required(<FormattedMessage {...messages.axisDoorStationFormRequired} />)
        .max(50, <FormattedMessage {...messages.axisDoorStationFormTooLong} />)
        .test({
          message: <FormattedMessage {...messages.axisDoorStationFormNameExist} />,
          name: 'isExistName',
          test: async (name) => {
            try {
              let state = true;
              if (!name || name.length === 0) {
                return true;
              }
              await headSiteData({ companyId, siteId }, 'devices', { name }, false).then((response) => {
                state = response.status === 404;
              });

              return state;
            } catch (error) {
              return true;
            }
          },
        }),
    });

  const onCreateNewDevice = (form, value) => {
    setStep(1);
    form.resetForm();
    onSelectDevice(value);
    onClearModal();
    onClearModalData();
  };

  const getActions = (form) => {
    if (step === 1) {
      return (
        <>
          <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
            <Button onClick={() => onCreateNewDevice(form)} startIcon={<ArrowBackIcon />} sx={{ marginRight: 'auto' }}>
              <FormattedMessage {...messages.formActionBackBtn} />
            </Button>
          </Box>
          <Button onClick={onClose}>
            <FormattedMessage {...messages.formActionCancelBtn} />
          </Button>
          <LoadingButton
            color="primary"
            disabled={!form.isValid}
            endIcon={<ArrowForwardIcon />}
            isLoading={form.isSubmitting}
            onClick={form.handleSubmit}
            type="submit"
            variant="contained"
          >
            <FormattedMessage {...messages.formActionNextBtn} />
          </LoadingButton>
        </>
      );
    }

    if (step === 2) {
      return (
        <>
          <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
            <Button disabled startIcon={<ArrowBackIcon />} sx={{ marginRight: 'auto' }}>
              <FormattedMessage {...messages.formActionBackBtn} />
            </Button>
          </Box>
          <Button onClick={() => onCreateNewDevice(form)}>
            <FormattedMessage {...messagesFinished.formActionAddNewDeviceBtn} />
          </Button>
          <Button color="primary" endIcon={<DoneIcon />} onClick={onClose} type="button" variant="contained">
            <FormattedMessage {...messagesFinished.formActionCloseBtn} />
          </Button>
        </>
      );
    }

    return null;
  };

  return (
    <Formik
      initialValues={{
        macAddress: '',
        name: '',
      }}
      onSubmit={onSubmit}
      render={(form) => (
        <Form autoComplete="off" noValidate>
          <AddDeviceModal
            actions={getActions(form)}
            title={<DeviceTitle device={DEVICE_TYPE.DOOR_STATION} />}
            open={open}
            onClose={onClose}
          >
            {step === 1 && (
              <>
                <StatusMessage />
                <FormikFields.Input
                  autoFocus
                  InputProps={{ inputComponent: MacAddressField }}
                  label={<FormattedMessage {...messages.axisDoorStationFormMacAddressLabel} />}
                  name="macAddress"
                  required
                />
                <FormikFields.Input
                  helperText={<FormattedMessage {...messages.axisDoorStationFormNameExample} />}
                  label={<FormattedMessage {...messages.axisDoorStationFormNameLabel} />}
                  name="name"
                  required
                />
              </>
            )}
            {step === 2 && <FinishedStep device={finishedData} deviceModalType={DEVICE_TYPE.DOOR_STATION} />}
          </AddDeviceModal>
        </Form>
      )}
      validationSchema={getValidationSchema()}
    />
  );
};

AxisDoorStationForm.propTypes = {
  finishedData: PropTypes.shape({
    services: PropTypes.shape({
      NOTIFICATION: PropTypes.shape({
        qrCodeUrl: PropTypes.string,
      }),
    }),
    userId: PropTypes.number,
  }),
  isFinished: PropTypes.bool,
  onClearModal: PropTypes.func.isRequired,
  onClearModalData: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelectDevice: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  validationExistName: PropTypes.shape({
    accessToken: PropTypes.string,
    companyId: PropTypes.number,
    siteId: PropTypes.number,
  }),
};

AxisDoorStationForm.defaultProps = {
  finishedData: null,
  isFinished: false,
  validationExistName: {
    accessToken: null,
    companyId: null,
    siteId: null,
  },
};

const mapStateToProps = (state) => ({
  finishedData: selectors.getFinishedDataDeviceModal(state),
  isFinished: selectors.isFinishedAddDeviceModal(state),
  validationExistName: getValidationParameters(state),
});

const dispatchToProps = {
  onClearModalData: actions.addDeviceClearModalData,
  onSubmit: addAxisDoorStationDevice,
};

export default connect(mapStateToProps, dispatchToProps)(AxisDoorStationForm);
