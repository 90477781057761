import { Box } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from '../../../../components';
import { FilledErrorBar } from '../../../../components/_DesignSystemWrappers/ErrorBar/FilledErrorBar';
import { PATHS } from '../../../../config/consts';
import { IFormAlertMessage } from '../../../../ory/hooks/oryResponseParser';
import OryErrorMessages from './../../../../ory/errors/messages';
import { isOryInvalidCredentialsError } from './utils';

export interface IOryErrorBarProps {
  error: IFormAlertMessage | undefined;
  onResetPassword?: () => void;
}

export function OryErrorBar(props: IOryErrorBarProps) {
  if (props.error) {
    return (
      <Box sx={{ pb: 3 }}>
        <FilledErrorBar>{getErrorBarContent(props.error)}</FilledErrorBar>
      </Box>
    );
  }

  return null;
}

function getErrorBarContent(error: IFormAlertMessage) {
  if (isOryInvalidCredentialsError(error.id)) {
    return (
      <FormattedMessage
        {...OryErrorMessages.ImsLoginErrorIncorrectCredentials4000006}
        values={{
          link: getLink(PATHS.PASSWORD_RESET),
        }}
      />
    );
  }

  return error.message;
}

function getLink(toPath: string) {
  return (chunks: string[]) => (
    <Link sx={{ textDecoration: 'underline' }} color={'error.text'} to={toPath}>
      {chunks}
    </Link>
  );
}
