import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useUrlParams } from '../../helpers/urlParams';
import actions, { selectors } from '../../modules/apartments';
import ApartmentHeader from './ApartmentHeader';
import ApartmentTabPanel from './ApartmentTabPanel';

const ApartmentDetail = ({ apartment, didInvalid, onLoadApartmentDetail, onOpenDeleteApartmentModal }) => {
  const { apartmentId, companyId, siteId } = useUrlParams();

  useEffect(() => {
    if (didInvalid) {
      onLoadApartmentDetail(companyId, siteId, apartmentId);
    }
  }, [didInvalid]);

  return (
    <>
      <ApartmentHeader
        apartment={apartment}
        companyId={companyId}
        onOpenDeleteApartmentModal={onOpenDeleteApartmentModal}
        siteId={siteId}
      />
      <ApartmentTabPanel />
      <Outlet />
    </>
  );
};

ApartmentDetail.propTypes = {
  apartment: PropTypes.shape({
    floor: PropTypes.shape({
      externalId: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    id: PropTypes.number,
    name: PropTypes.string,
    number: PropTypes.string,
  }).isRequired,
  didInvalid: PropTypes.bool.isRequired,
  onLoadApartmentDetail: PropTypes.func.isRequired,
  onOpenDeleteApartmentModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { data, didInvalid } = selectors.getApartmentDetail(state);
  return {
    apartment: data,
    didInvalid,
  };
};

const mapDispatchToProps = {
  onLoadApartmentDetail: actions.getApartmentDetail,
  onOpenDeleteApartmentModal: actions.deleteApartmentShowModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApartmentDetail);
