import { IntlFormatters } from 'react-intl';
import * as yup from 'yup';
import messages from '../../../../validations/messages';
import { getNewPasswordValidationSchema } from '../../../../validations/newPasswordValidationSchema';

export interface INewPasswordFormData {
  password: string;
}

export enum FormFieldNames {
  Password = 'password',
}

export const defaultValues: INewPasswordFormData = {
  password: '',
};

const oryPasswordMaxLength = 72;

export function getOryPasswordValidationDefinition(formatMessage: IntlFormatters['formatMessage']) {
  const oryPasswordSchema = getNewPasswordValidationSchema(formatMessage).password;
  return {
    [FormFieldNames.Password]: oryPasswordSchema.max(oryPasswordMaxLength, formatMessage(messages.validationMaxLength)),
  };
}

export function getNewPasswordFormValidationSchema(formatMessage: IntlFormatters['formatMessage']) {
  return yup.object().shape(getOryPasswordValidationDefinition(formatMessage));
}
