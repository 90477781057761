import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { CURRENCY, MOBILE_VIDEO_STATUS } from '../../../../config/consts';
import { BillingType } from '../../../../modules/commonTypes';
import CurrentMonthSummary from './CurrentMonthSummary';
import NextPaymentSummary from './NextPaymentSummary';
import SiteStatusSummary from './SiteStatusSummary';

const CodeSummary = ({ codeData, currency, isPaidServicesApartmentFFEnabled, mobileVideoLicense }) => (
  <Grid container spacing={2}>
    <Grid container direction="column" item spacing={2}>
      <Grid item>
        <CurrentMonthSummary
          isPaidServicesApartmentFFEnabled={isPaidServicesApartmentFFEnabled}
          billingType={mobileVideoLicense.billingType}
          codeData={codeData}
        />
      </Grid>
      {[
        MOBILE_VIDEO_STATUS.TRIAL_ACTIVE,
        MOBILE_VIDEO_STATUS.TRIAL_EXPIRED,
        MOBILE_VIDEO_STATUS.STANDARD_EXPIRED,
      ].includes(mobileVideoLicense.status) && (
        <Grid item>
          <SiteStatusSummary status={mobileVideoLicense.status} />
        </Grid>
      )}
      {codeData.activation.nextPayment && (
        <Grid item>
          <NextPaymentSummary
            currency={currency}
            expiration={codeData.activation.expiration}
            nextPayment={codeData.activation.nextPayment}
          />
        </Grid>
      )}
    </Grid>
  </Grid>
);

CodeSummary.propTypes = {
  codeData: PropTypes.shape({
    activation: PropTypes.shape({
      expiration: PropTypes.string,
      nextPayment: PropTypes.number,
    }),
  }).isRequired,
  currency: PropTypes.oneOf(Object.keys(CURRENCY)).isRequired,
  isPaidServicesApartmentFFEnabled: PropTypes.bool.isRequired,
  mobileVideoLicense: PropTypes.shape({
    billingType: PropTypes.oneOf(Object.keys(BillingType)),
    status: PropTypes.string,
  }).isRequired,
};

export default CodeSummary;
