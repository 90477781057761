import React from 'react';
import { APARTMENT_STATUS, MOBILE_VIDEO_STATUS, MOBILE_VIDEO_TYPE } from '../../../config/consts';
import { SERVICE } from '../../../config/devices';
import featureFlags from '../../../config/featureFlags';
import { IApartmentDetail } from '../../../modules/apartments/store';
import { IMVLicenceModel } from '../../../modules/sites/store';
import { useHasFeature } from '../../Feature/Feature';

interface IApartmentDataGridAction {
  apartment: IApartmentDetail;
  licenceModel: IMVLicenceModel;
  purchaseComponent: React.ReactElement;
  useAvailableCreditComponent: React.ReactElement;
}

export default function ApartmentDataGridAction({
  apartment,
  licenceModel,
  purchaseComponent,
  useAvailableCreditComponent,
}: IApartmentDataGridAction) {
  const isPaidServicesApartmentFFEnabled = useHasFeature(featureFlags.PAID_SERVICES_APARTMENT);
  const isApartmentUnlicenced = apartment?.services?.[SERVICE.MOBILE_VIDEO]?.status === APARTMENT_STATUS.UNLICENSED;
  const { additional, licencesPool, status, type } = licenceModel;

  if (isPaidServicesApartmentFFEnabled) {
    if (type === MOBILE_VIDEO_TYPE.STANDARD && isApartmentUnlicenced && apartment?.paidServicesActive) {
      return purchaseComponent;
    } else {
      return <>{false}</>;
    }
  } else {
    if (status === MOBILE_VIDEO_STATUS.STANDARD && isApartmentUnlicenced) {
      return additional > licencesPool ? purchaseComponent : useAvailableCreditComponent;
    } else {
      return <>{false}</>;
    }
  }
}
