import { defineMessages } from 'react-intl';

export default defineMessages({
  panelMy2NAppAccessActivatedLabel: {
    defaultMessage: 'My2N app access is <box>actived</box>',
    description: 'Panel My2N app access - label activated',
    id: 'panelMy2NAppAccessActivatedLabel',
  },
  panelMy2NAppAccessErrorLabel: {
    defaultMessage: 'Unknown',
    description: 'Panel My2N app access - error label',
    id: 'panelMy2NAppAccessErrorLabel',
  },
  panelMy2NAppAccessErrorMessage: {
    defaultMessage: 'My2N app access couldn’t be loaded. Refresh the page',
    description: 'User access form - PIN - Error message',
    id: 'panelMy2NAppAccessErrorMessage',
  },
  panelMy2NAppAccessLabel: {
    defaultMessage: 'My2N app access is enabled',
    description: 'Panel My2N app access - label',
    id: 'panelMy2NAppAccessLabel',
  },
  removeUserRfidCardButtonRemoveCard: {
    defaultMessage: 'Remove card',
    description: 'User RFID button - remove card',
    id: 'removeUserRfidCardButton.removeCard',
  },
  revokeUserPinButtonDeletePin: {
    defaultMessage: 'Delete PIN',
    description: 'User PIN buttun - delete PIN',
    id: 'revokeUserPinButton.deletePin',
  },
  sectionMy2NAppAccessAddButtonLabel: {
    defaultMessage: 'Enable My2N app access',
    description: 'User access form - My2N app access - add button label',
    id: 'sectionMy2NAppAccessAddButtonLabel',
  },
  sectionMy2NAppAccessDeleteButtonLabel: {
    defaultMessage: 'Disable my2N app access',
    description: 'User access form - My2N app access - delete button label',
    id: 'sectionMy2NAppAccessDeleteButtonLabel',
  },
  sectionMy2NAppAccessInfo: {
    defaultMessage:
      'My2N app access allows users to use their mobile app to open door.' +
      '<p>This method won’t work on a device that is set to manual configuration mode. Access credentials can’t be uploaded to the device.</p>',
    description: 'User access form - My2N app access - alert info',
    id: 'sectionMy2NAppAccessInfo',
  },
  sectionMy2NAppAccessTitle: {
    defaultMessage: 'My2N app access',
    description: 'User access form - My2NAppAccess - title',
    id: 'sectionMy2NAppAccessTitle',
  },
  userAccessFormPinCopied: {
    defaultMessage: 'The PIN has been copied to your clipboard',
    description: 'User access form - PIN - copied',
    id: 'userAccessFormPinCopied',
  },
  userAccessFormPinCopy: {
    defaultMessage: 'Copy',
    description: 'User access form - PIN - Copy',
    id: 'userAccessFormPinCopy',
  },
  userAccessFormPinErrorMessage: {
    defaultMessage: 'The PIN couldn’t be loaded. Refresh the page',
    description: 'User access form - PIN - Error message',
    id: 'userAccessFormPinErrorMessage',
  },
  userAccessFormPinGenerateNewPin: {
    defaultMessage: 'Generate new pin',
    description: 'User access form - PIN - Generate new pin',
    id: 'userAccessFormPinGenerateNewPin',
  },
  userAccessFormPinInfoDescription: {
    defaultMessage:
      'PIN code provides a quick and safe access method.' +
      ' Each user has their automatically generated unique PIN code.{br}{br}' +
      ' This method doesn’t work on devices set to manual configuration' +
      ' mode as access credentials cannot be uploaded.',
    description: 'User access form - PIN - Info description',
    id: 'userAccessFormPinInfoDescription',
  },
  userAccessFormPinTitle: {
    defaultMessage: 'PIN',
    description: 'User access form - PIN - Title',
    id: 'userAccessFormPinTitle',
  },
  userAccessFormRfidAssignRfidCard: {
    defaultMessage: 'Assign RFID card',
    description: 'User access form - RFID - Assign RFID card',
    id: 'userAccessFormRfidAssignRfidCard',
  },
  userAccessFormRfidCopied: {
    defaultMessage: 'The RFID card code has been copied to your clipboard',
    description: 'User access form - RFID - copied',
    id: 'userAccessFormRfidCopied',
  },
  userAccessFormRfidCopy: {
    defaultMessage: 'Copy',
    description: 'User access form - RFID - Copy',
    id: 'userAccessFormRfidCopy',
  },
  userAccessFormRfidErrorMessage: {
    defaultMessage: 'RFID card identifier couldn’t be loaded. Refresh the page',
    description: 'User access form - RFID - Error message',
    id: 'userAccessFormRfidErrorMessage',
  },
  userAccessFormRfidInfoDescription: {
    defaultMessage:
      'RFID cards are a convenient access method, especially for regular users.' +
      ' You can assign an RFID card (or a badge) for each user.{br}{br}' +
      ' This method doesn’t work on devices set to manual configuration mode as access' +
      ' credentials cannot be uploaded.',
    description: 'User access form - RFID - Info description',
    id: 'userAccessFormRfidInfoDescription',
  },
  userAccessFormRfidTitle: {
    defaultMessage: 'RFID card / badge',
    description: 'User access form - RFID - Title',
    id: 'userAccessFormRfidTitle',
  },
});
