import WarningIcon from '@mui/icons-material/Warning';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import ActivateIntegrationModal from '../../../../components/ActivateIntegrationModal/ActivateIntegrationModal';
import DeactivateIntegrationModal from '../../../../components/DeactivateIntegrationModal/DeactivateIntegrationModal';
import Checkbox from '../../../../components/HookFormFields/Checkbox';
import { useModal } from '../../../../hooks/useModal';
import styles from '../../../SiteUserDetail/Detail/UserInfoForm/styles/UserInfoForm';
import { useGetGlobalHooks } from './hooks/useGetGlobalHooks';
import messages from './messages';

export function IntegrationSettings() {
  const { formatMessage, formMethods } = useGetGlobalHooks();
  const landingCallEnabledValue = formMethods.watch('landingCallEnabled');
  const destinationCallEnabled = formMethods.watch('destinationCallEnabled');

  const { Modal: ActivateIntegrationUModal, onOpen: onOpenActivateIntegrationUModal } = useModal({
    Modal: ActivateIntegrationModal,
  });

  const { Modal: DeactivateIntegrationUModal, onOpen: onOpenDeactivateIntegrationUModal } = useModal({
    Modal: DeactivateIntegrationModal,
  });

  useEffect(() => {
    if (!landingCallEnabledValue) {
      formMethods.setValue('destinationCallEnabled', false);
    }
  }, [landingCallEnabledValue]);

  useEffect(() => {
    if (destinationCallEnabled) {
      formMethods.setValue('landingCallEnabled', true);
    }
  }, [destinationCallEnabled]);

  function showConfirmModal() {
    formMethods.getValues('elevatorIntegrationEnabled')
      ? onOpenDeactivateIntegrationUModal()
      : onOpenActivateIntegrationUModal();
  }

  return (
    <>
      <Typography sx={styles.formTitle} variant="h6">
        <FormattedMessage {...messages.elevatorIntegrationSettingsTitle} />
      </Typography>
      <Stack
        onClickCapture={(event) => {
          event.stopPropagation();
          showConfirmModal();
        }}
      >
        <Checkbox
          name="elevatorIntegrationEnabled"
          label={formatMessage(messages.elevatorIntegrationSettingsActiveSwitchLabel)}
        />
      </Stack>
      <Typography color="secondary" variant="body2" sx={{ mb: 2, pl: 4 }}>
        <FormattedMessage
          values={{
            br: <br />,
          }}
          {...messages.elevatorIntegrationSettingsActiveSwitchCaption}
        />
      </Typography>
      <Alert icon={<WarningIcon />} severity="warning" variant="outlined">
        <AlertTitle>
          <Typography variant="body2">
            <FormattedMessage {...messages.elevatorIntegrationSettingsAlertText} />
          </Typography>
        </AlertTitle>
      </Alert>
      <Typography color="secondary.main" sx={{ mt: 2 }} variant="body1">
        <FormattedMessage {...messages.elevatorIntegrationSettingsUserDefaultTitle} />
      </Typography>
      <Tooltip
        placement="right"
        title={<FormattedMessage values={{ br: <br /> }} {...messages.elevatorIntegrationSettingsLandingCallTtip} />}
      >
        <span>
          <Checkbox
            name="landingCallEnabled"
            label={formatMessage(messages.elevatorIntegrationSettingsLandingCallLabel)}
          />
        </span>
      </Tooltip>
      <br />
      <Tooltip
        placement="right"
        title={
          <FormattedMessage values={{ br: <br /> }} {...messages.elevatorIntegrationSettingsDestinationCallTtip} />
        }
      >
        <span>
          <Checkbox
            name="destinationCallEnabled"
            label={formatMessage(messages.elevatorIntegrationSettingsDestinationCallLabel)}
          />
        </span>
      </Tooltip>
      <ActivateIntegrationUModal
        onSubmit={() => {
          formMethods.setValue('elevatorIntegrationEnabled', true);
        }}
      />
      <DeactivateIntegrationUModal
        onSubmit={() => {
          formMethods.setValue('elevatorIntegrationEnabled', false);
        }}
      />
      <Typography color="secondary" variant="body2">
        <FormattedMessage {...messages.elevatorIntegrationSettingsUserDefaultCaption} />
      </Typography>
    </>
  );
}
