import { defineMessages } from 'react-intl';

export default defineMessages({
  siteSettingsAccessCancel: {
    id: 'siteSettings.access.cancel',
  },
  siteSettingsAccessGuestLengthErrRange: {
    id: 'siteSettings.access.guestLength.errRange',
  },
  siteSettingsAccessGuestLengthHelper: {
    id: 'siteSettings.access.guestLength.helper',
  },
  siteSettingsAccessGuestLengthLabel: {
    id: 'siteSettings.access.guestLength.label',
  },
  siteSettingsAccessGuestLengthUnit: {
    id: 'siteSettings.access.guestLength.unit',
  },
  siteSettingsAccessGuestQrLabel: {
    id: 'siteSettings.access.guestQR.label',
  },
  siteSettingsAccessMsgErr: {
    defaultMessage: 'There was an error, please try again',
    id: 'siteSettings.access.msgErr',
  },
  siteSettingsAccessMsgSucc: {
    defaultMessage: 'Settings updated successfully',
    id: 'siteSettings.access.msgSucc',
  },
  siteSettingsAccessSubmit: {
    id: 'siteSettings.access.submit',
  },
  siteSettingsAccessText1: {
    id: 'siteSettings.access.text1',
  },
  siteSettingsAccessText2: {
    id: 'siteSettings.access.text2',
  },
  siteSettingsAccessTitle1: {
    id: 'siteSettings.access.title1',
  },
  siteSettingsAccessTitle2: {
    id: 'siteSettings.access.title2',
  },
});
