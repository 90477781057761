import { defineMessages } from 'react-intl';

export default defineMessages({
  apartmentDataFormExistNumber: {
    defaultMessage: 'An apartment with this number already exists.',
    description: 'Apartment data form - apartment number already exists',
    id: 'apartmentDataForm.existNumber',
  },
  apartmentDataFormFloorDescription: {
    defaultMessage: 'We recommend grouping apartments by floor.',
    description: 'Apartment data form - floor description',
    id: 'apartmentDataForm.floorDescription',
  },
  apartmentDataFormHelperTextFloor: {
    defaultMessage: 'Usually a group of apartments.',
    description: 'Apartment data form - apartment floor helper text',
    id: 'apartmentDataForm.helperTextFloor',
  },
  apartmentDataFormHelperTextName: {
    defaultMessage: 'You can name the apartment after the family name.',
    description: 'Apartment data form - apartment name helper text',
    id: 'apartmentDataForm.helperTextName',
  },
  apartmentDataFormHelperTextNumber: {
    defaultMessage: 'Use a unique number, e.g. 27, 4A or 2.01',
    description: 'Apartment data form - apartment number helper text',
    id: 'apartmentDataForm.helperTextNumber',
  },
  apartmentDataFormName: {
    defaultMessage: 'Apartment name',
    description: 'Apartment data form - apartment name',
    id: 'apartmentDataForm.name',
  },
  apartmentDataFormNumber: {
    defaultMessage: 'Number',
    description: 'Apartment data form - number',
    id: 'apartmentDataForm.number',
  },
  apartmentDataFormRequiredFloor: {
    defaultMessage: 'Enter the floor number',
    description: 'Apartment data form - floor is required',
    id: 'apartmentDataForm.requiredFloor',
  },
  apartmentDataFormRequiredNumber: {
    defaultMessage: 'Enter the apartment number',
    description: 'Apartment data form - number is required',
    id: 'apartmentDataForm.requiredNumber',
  },
  apartmentFloorAutocompleteFloor: {
    defaultMessage: 'Floor',
    description: 'Apartment data form - floor',
    id: 'apartmentDataForm.floor',
  },
});
