import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import { FooterPublic, HeaderPublic } from '../../components';
import { isLoadingSelector } from '../../modules/auth/selectors';
import { EmailForm } from './components/EmailForm/EmailForm';
import { NewPasswordForm } from './components/NewPasswordForm';
import { RecoveryCodeForm } from './components/RecoveryCodeForm/RecoveryCodeForm';
import { styles } from './styles';
import { ResetPasswordFormStep } from './types';
import { useResetPasswordPage } from './useResetPasswordPage';

export function ResetPasswordPage() {
  const isFetching = useSelector(isLoadingSelector);

  const {
    email,
    emailFormSubmit,
    formTitle,
    isOryApiFetching,
    newPasswordFormSuccess,
    oryRecoveryFlowData,
    recoveryCodeFormSuccess,
    recoveryFlowRestart,
    recoveryWholeProcessRestart,
    resetPasswordFormStep,
  } = useResetPasswordPage();

  return (
    <>
      <HeaderPublic />
      <Box sx={styles.wrapper}>
        <Box sx={styles.boxContent}>
          <Typography sx={styles.title} variant="h1">
            {formTitle}
          </Typography>
          <Box sx={styles.box}>
            {resetPasswordFormStep === ResetPasswordFormStep.EmailForm && (
              <EmailForm initEmail={email} isDisabled={isFetching || isOryApiFetching} onSubmit={emailFormSubmit} />
            )}

            {resetPasswordFormStep === ResetPasswordFormStep.ORYRecoveryCodeForm && oryRecoveryFlowData && (
              <RecoveryCodeForm
                recoveryFlowData={oryRecoveryFlowData}
                email={email}
                onSuccess={recoveryCodeFormSuccess}
                onRecoveryProcessRestart={recoveryWholeProcessRestart}
                onRecoveryFlowRestart={recoveryFlowRestart}
              />
            )}
            {resetPasswordFormStep === ResetPasswordFormStep.ORYNewPasswordForm && (
              <NewPasswordForm
                onSuccess={newPasswordFormSuccess}
                onRecoveryProcessRestart={recoveryWholeProcessRestart}
              />
            )}
          </Box>
        </Box>
      </Box>
      <FooterPublic />
    </>
  );
}
