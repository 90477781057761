import { ICreateMultipleUsersData } from '../../api/apartmentApiTS';
import { IApartmentFormProps } from '../../containers/ApartmentsOverviewNew/hooks/useGetApartmentFormData';
import { getOffset } from '../../helpers/dataGrid';
import { TId } from '../commonTypes';
import { INewUser, IUser } from '../user/store';
import { IApartmentDetail, IFloor, INewApartment, INewFloor } from './store';
import types from './types';

export const getSiteApartmentsList = (
  companyId: TId,
  siteId: TId,
  page: number = 1,
  rowsPerPage: number = 5,
  filter = { fulltext: '' },
  order: string = 'floor'
) => ({
  companyId,
  filter,
  order,
  page,
  params: {
    filter: filter.fulltext,
    limit: rowsPerPage,
    offset: getOffset(page, rowsPerPage),
    order: ['floor', '-floor'].includes(order) ? `${order.trim()},number` : order,
  },
  rowsPerPage,
  siteId,
  type: types.GET_SITE_APARTMENTS_REQUEST,
});

export const addApartment = (
  companyId: TId,
  siteId: TId,
  floors: Array<IFloor>,
  values: INewApartment,
  toast: boolean = true
) => ({
  companyId,
  floors,
  siteId,
  toast,
  type: types.ADD_APARTMENT_REQUEST,
  values,
});

export const addFloor = (companyId: TId, siteId: TId, values: INewFloor) => ({
  companyId,
  siteId,
  type: types.ADD_FLOOR_REQUEST,
  values,
});

export const getSiteFloorsList = (companyId: TId, siteId: TId) => ({
  companyId,
  siteId,
  type: types.GET_SITE_FLOORS_REQUEST,
});

export const getApartmentDetail = (companyId: TId, siteId: TId, apartmentId: TId) => ({
  apartmentId,
  companyId,
  siteId,
  type: types.GET_APARTMENT_DETAIL_REQUEST,
});

export const deleteApartment = (companyId: TId, siteId: TId, apartmentId: TId) => ({
  apartmentId,
  companyId,
  siteId,
  type: types.DELETE_APARTMENT_REQUEST,
});

export const getApartmentDevicesList = (
  apartmentId: TId,
  companyId: TId,
  siteId: TId,
  page: number = 1,
  rowsPerPage: number = 10,
  filter = { fulltext: '' },
  order: string = 'name'
) => ({
  apartmentId,
  companyId,
  filter,
  order,
  page,
  params: {
    filter: filter.fulltext,
    limit: rowsPerPage,
    offset: getOffset(page, rowsPerPage),
    order,
  },
  rowsPerPage,
  siteId,
  type: types.GET_APARTMENT_DEVICES_REQUEST,
});

export const addApartmentUsersWithCreate = (apartmentId: TId, users: Array<TId>, newUser?: INewUser) => ({
  apartmentId,
  newUser,
  type: types.CREATE_ADD_APARTMENT_USERS_REQUEST,
  users,
});

export const editApartment = (
  companyId: TId,
  siteId: TId,
  apartment: IApartmentDetail,
  floors: Array<IFloor>,
  values: IApartmentFormProps
) => ({
  apartment,
  companyId,
  floors,
  siteId,
  type: types.EDIT_APARTMENT_REQUEST,
  values,
});

export const getApartmentsList = (companyId: TId, siteId: TId) => ({
  companyId,
  siteId,
  type: types.GET_APARTMENTS_LIST_REQUEST,
});

export const clearApartmentsList = () => ({
  type: types.GET_APARTMENTS_LIST_FAILURE,
});

export const deleteApartmentShowModal = (apartmentId: TId) => ({
  apartmentId,
  type: types.DELETE_APARTMENT_SHOW_MODAL,
});
export const deleteApartmentHideModal = () => ({ type: types.DELETE_APARTMENT_HIDE_MODAL });

export const setApartmentAccessGroupShowModal = (apartmentId: TId) => ({
  apartmentId,
  type: types.SET_APARTMENT_ACCESS_GROUP_SHOW_MODAL,
});

export const setApartmentAccessGroupHideModal = () => ({
  type: types.SET_APARTMENT_ACCESS_GROUP_HIDE_MODAL,
});

export function addMultipleApartmentUsers(
  companyId: TId,
  siteId: TId,
  apartmentId: TId,
  data: ICreateMultipleUsersData
) {
  return {
    apartmentId,
    companyId,
    data,
    siteId,
    type: types.CREATE_MULTIPLE_APARTMENT_USERS_REQUEST,
  };
}

export function getApartmentUsers(
  apartmentId: TId | undefined = undefined,
  companyId: TId,
  siteId: TId | undefined = undefined,
  page: number,
  rowsPerPage: number,
  filter: { fulltext: string },
  order: string
) {
  return {
    apartmentId,
    companyId,
    filter,
    order,
    page,
    params: {
      filter: filter.fulltext,
      limit: rowsPerPage,
      offset: getOffset(page, rowsPerPage),
      order,
    },
    rowsPerPage,
    siteId,
    type: types.GET_APARTMENT_USERS_REQUEST,
  };
}

export const deleteApartmentUser = (user: IUser) => ({
  type: types.DELETE_APARTMENT_USER_REQUEST,
  user,
});

export const revokeApartmentAdmin = (apartmentId: TId, userId: TId) => ({
  apartmentId,
  type: types.REVOKE_APARTMENT_ADMIN_REQUEST,
  userId,
});

export const grantApartmentAdmin = (apartmentId: TId, userId: TId) => ({
  apartmentId,
  type: types.GRANT_APARTMENT_ADMIN_REQUEST,
  userId,
});
