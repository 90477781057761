import { defineMessages } from 'react-intl';

export default defineMessages({
  AAModalApartmentDetailAccess: {
    defaultMessage: 'Apartment access settings',
    id: 'AAmodal.apartmentDetail.access',
  },
  AAModalApartmentDetailAccessNone: {
    defaultMessage: 'No access rights are set. Contact your facility manager.',
    id: 'AAmodal.apartmentDetail.accessNone',
  },
  AAModalApartmentDetailButtonLabel: {
    defaultMessage: 'Apartment label on intercom',
    id: 'AAmodal.apartmentDetail.buttonLabel',
  },
  AAModalApartmentDetailButtonLabelMultiple: {
    defaultMessage: `Different labels`,
    id: 'AAmodal.apartmentDetail.buttonLabelMultiple',
  },
  AAModalApartmentDetailButtonLabelNone: {
    defaultMessage: `No label on intercom`,
    id: 'AAmodal.apartmentDetail.buttonLabelNone',
  },
  AAModalApartmentDetailClose: {
    defaultMessage: 'Close',
    id: 'AAmodal.apartmentDetail.close',
  },
  AAModalApartmentDetailDevices: {
    defaultMessage: 'Devices in apartment',
    id: 'AAmodal.apartmentDetail.devices',
  },
  AAModalApartmentDetailDevicesCount: {
    defaultMessage: '<strong>{count}</strong> {count, plural, one {device} other {devices}}',
    id: 'AAmodal.apartmentDetail.devicesCount',
  },
  AAModalApartmentDetailMobileVideo: {
    defaultMessage: 'Mobile Video Service',
    id: 'AAmodal.apartmentDetail.mobileVideo',
  },
  AAModalApartmentDetailMobileVideoHelper: {
    defaultMessage: '{count, plural, =0 {No licenses are used} one {<strong>{count}</strong> out of {sum} licenses is used} other {<strong>{count}</strong> out of {sum} licenses are used}}',
    id: 'AAmodal.apartmentDetail.mobileVideo.helper',
  },
  AAModalApartmentDetailSite: {
    defaultMessage: 'Site name',
    id: 'AAmodal.apartmentDetail.site',
  },

  AAModalApartmentDetailUsers: {
    defaultMessage: 'Users in apartment',
    id: 'AAmodal.apartmentDetail.users',
  },
  AAModalApartmentDetailUsersCount: {
    defaultMessage: '<strong>{count}</strong> {count, plural, one {user} other {users}}',
    id: 'AAmodal.apartmentDetail.usersCount',
  },
});
