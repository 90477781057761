import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SITE_TYPE } from '../../../config/sites';
import actions, { selectors } from '../../../modules/apartments';
import { selectors as sitesSelectors } from '../../../modules/sites';
import { IApartmentFormProps, useGetApartmentFormData } from '../../ApartmentsOverview/hooks/useGetApartmentFormData';

export function useGetApartmentInfoData() {
  const dispatch = useDispatch();
  const floors = useSelector(selectors.getFloors);
  const apartment = useSelector(selectors.getApartmentData);
  const siteType = useSelector(sitesSelectors.getCurrentSiteType);
  const didInvalidFloors = useSelector(selectors.getDidInvalidFloors);

  const initValues: IApartmentFormProps = {
    floor: apartment.floor?.name || '',
    hasForm: true,
    isSiteMdu: siteType === SITE_TYPE.MDU,
    name: apartment.name || '',
    number: apartment.number,
  };

  const { companyId, formMethods, siteId } = useGetApartmentFormData(initValues);

  useEffect(() => {
    if (apartment && siteType) {
      formMethods.reset(initValues);
    }
  }, [apartment, siteType]);

  useEffect(() => {
    if (companyId && siteId) {
      dispatch(actions.getSiteFloorsList(companyId, siteId));
    }
  }, [companyId, siteId]);

  useEffect(() => {
    if (didInvalidFloors && companyId && siteId) {
      dispatch(actions.getSiteFloorsList(companyId, siteId));
    }
  }, [didInvalidFloors]);

  const onSubmit = (values: IApartmentFormProps) => {
    if (companyId && siteId) {
      dispatch(actions.editApartment(companyId, siteId, apartment, floors, values));
    }
  };

  return {
    floors,
    formMethods,
    onSubmit,
    siteType,
  };
}
