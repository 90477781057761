import Grid from '@mui/material/Grid';
import Box from '@mui/system/Box';
import React from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import Alert from '../../../components/Alert';
import Autocomplete from '../../../components/HookFormFields/Autocomplete';
import { OutlinedTextField } from '../../../components/HookFormFields/TextField';
import { SITE_TYPE } from '../../../config/sites';
import { IFloor, INewApartment } from '../../../modules/apartments/store';
import { IApartmentFormProps } from '../../ApartmentsOverview/hooks/useGetApartmentFormData';
import messages from './messages';

interface IApartmentDataForm {
  floors: Array<IFloor>;
  formMethods: UseFormReturn<IApartmentFormProps>;
  formWrapperProps?: object;
  onSubmit: (values: INewApartment) => void;
  siteType: SITE_TYPE;
}

const ApartmentDataForm = ({ floors, formMethods, formWrapperProps, onSubmit, siteType }: IApartmentDataForm) => {
  const { formatMessage } = useIntl();
  const isSiteMdu = siteType === SITE_TYPE.MDU;

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Box py={3}>
          <Grid {...formWrapperProps}>
            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                <Autocomplete
                  control={formMethods.control}
                  disableClearable
                  freeSolo
                  fullWidth
                  id="floor"
                  inputSettings={{
                    helperText: formatMessage(messages.apartmentDataFormHelperTextFloor),
                    InputLabelProps: {
                      shrink: true,
                    },
                    label: formatMessage(messages.apartmentFloorAutocompleteFloor),
                    required: isSiteMdu,
                  }}
                  name="floor"
                  options={floors.map((floor) => floor.name)}
                />
              </Grid>
              <Grid item sm={6} sx={{ paddingBottom: { sm: 'inherit', xs: 0 } }} xs={12}>
                <OutlinedTextField
                  id="number"
                  helperText={formatMessage(messages.apartmentDataFormHelperTextNumber)}
                  label={formatMessage(messages.apartmentDataFormNumber)}
                  name="number"
                  required
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ display: { sm: 'block', xs: 'none' } }}>
              <Grid item sm={12}>
                <Alert severity="info">{formatMessage(messages.apartmentDataFormFloorDescription)}</Alert>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item sm={12} sx={{ paddingTop: { sm: 'inherit', xs: 0 } }} xs={12}>
                <OutlinedTextField
                  id="name"
                  helperText={formatMessage(messages.apartmentDataFormHelperTextName)}
                  hasTopAndBottomMargin
                  label={formatMessage(messages.apartmentDataFormName)}
                  name="name"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </form>
    </FormProvider>
  );
};

export default ApartmentDataForm;
