import CheckIcon from '@mui/icons-material/Check';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useGetCreditCostUntilEndOfMonthQuery } from '../../apiRtk/be4feApi';
import { IModalBase } from '../../modules/commonTypes';
import ActionModal from '../_DesignSystemWrappers/Modal/ActionModal';
import { useGetAvailableCreditModalData } from './hooks/useGetAvailableCreditModalData';
import messages from './messages';

interface IAvailableCreditModal extends IModalBase {
  unlicensedCount: number;
}

function AvailableCreditModal({ onClose, open, unlicensedCount }: IAvailableCreditModal) {
  const { companyId, formatMessage, isMdu, onSubmit, siteId } = useGetAvailableCreditModalData(onClose);
  const countData = useGetCreditCostUntilEndOfMonthQuery(
    {
      companyId: companyId,
      siteId: siteId,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <ActionModal
      icon={<CheckIcon />}
      isPrimaryButtonDisabled={false}
      muiDialogProps={{
        maxWidth: 'xs',
      }}
      onClose={onClose}
      open={open}
      primaryButtonAction={onSubmit}
      primaryButtonText={formatMessage(messages.AvailableCreditModalConfirmBtn)}
      secondaryButtonText={formatMessage(messages.AvailableCreditModalCancelBtn)}
      title={formatMessage(messages.AvailableCreditModalModalTitle)}
    >
      <Stack my={2.5} spacing={1}>
        <Typography>
          <FormattedMessage
            {...(isMdu ? messages.AvailableCreditModalMduMessage1 : messages.AvailableCreditModalMessage1)}
            // při odstraňování FF -> PAID_SERVICES_APARTMENT odstranit podmínku na MDU(PER_APARTMENT) typ site
            // modal se bude využívat jen pro typ site = PER_DEVICE
            values={{ count: isMdu ? unlicensedCount : countData?.data?.licencesCount }}
          />
        </Typography>
        <Typography>
          <FormattedMessage {...messages.AvailableCreditModalMessage2} />
        </Typography>
      </Stack>
    </ActionModal>
  );
}

export default AvailableCreditModal;
