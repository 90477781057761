import { createApi } from '@reduxjs/toolkit/query/react';
import { AxiosError } from 'axios';
import axiosInstance from '../api';
import { IError } from '../modules/reduxTypes';

interface IRequestData {
  body?: object;
  headers?: object;
  method?: string;
  params?: object;
  url: string;
}

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async (request: IRequestData) => {
    try {
      const result = await axiosInstance({
        data: request.body,
        headers: request.headers,
        method: request.method,
        params: request.params,
        url: baseUrl + request.url,
      });

      if (result.status >= 200 && result.status < 300) {
        return { data: result.data };
      }

      return {
        error: {
          data: result.data,
          status: result.status,
        },
      };
    } catch (axiosError) {
      const err: AxiosError<IError> = axiosError as AxiosError<IError>;
      return {
        error: {
          data: err.response?.data || err.message,
          status: err.response?.status,
        },
      };
    }
  };

export const api = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.API_URL!,
  }),
  endpoints: () => ({}),
  reducerPath: 'my2nApi',
});
