import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import SvgLoader from '../../components/SvgLoader';
import { isArrayEmpty } from '../../helpers/array';
import { selectors as authSelectors } from '../../modules/auth';
import commonActions, { selectors as commonSelectors } from '../../modules/common';
import localizationActions, { selectors as localizationSelectors } from '../../modules/localization';
import { selectors as userSelectors } from '../../modules/user';

export const AppContext = createContext({
  accessToken: null,
  currentUserLang: null,
  feSiteRoles: {
    isCompanyAdmin: false,
    isSiteAdmin: false,
    isSiteAdminPayment: false,
    isSiteUser: false,
  },
  globalFeatures: [],
  loggedIn: false,
  userRoles: [],
});

export const useAppContext = () => useContext(AppContext);

const AppContextProvider = ({
  accessToken,
  children,
  currentUserLang,
  feSiteRoles,
  globalFeatures,
  isLoadingFeatures,
  loggedIn,
  onLoadCurrentUserLang,
  onLoadGlobalFeatures,
  userRoles,
}) => {
  useEffect(() => {
    window.onstorage = (event) => {
      const parseToJson = (data) => {
        try {
          return JSON.parse(data);
        } catch (_) {
          return {};
        }
      };

      if (event.key === 'state') {
        const oldAccessToken = parseToJson(event.oldValue)?.auth?.accessToken || '';
        const newAccessToken = parseToJson(event.newValue)?.auth?.accessToken || '';
        if (oldAccessToken && oldAccessToken !== newAccessToken) {
          window.location.reload();
        }
      }
    };

    return () => {
      window.onstorage = null;
    };
  }, []);

  useEffect(() => {
    if (isArrayEmpty(globalFeatures)) {
      onLoadGlobalFeatures();
    }
    if (loggedIn) {
      onLoadCurrentUserLang();
    }
  }, [loggedIn]);

  return (
    <AppContext.Provider
      value={{
        accessToken,
        currentUserLang,
        feSiteRoles,
        globalFeatures,
        loggedIn,
        userRoles,
      }}
    >
      {isLoadingFeatures ? <SvgLoader /> : children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  accessToken: PropTypes.string,
  children: PropTypes.any.isRequired,
  currentUserLang: PropTypes.string,
  feSiteRoles: PropTypes.shape({
    isCompanyAdmin: PropTypes.bool,
    isSiteAdmin: PropTypes.bool,
    isSiteAdminPayment: PropTypes.bool,
    isSiteUser: PropTypes.bool,
  }),
  globalFeatures: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  isLoadingFeatures: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool,
  onLoadCurrentUserLang: PropTypes.func.isRequired,
  onLoadGlobalFeatures: PropTypes.func.isRequired,
  userRoles: PropTypes.arrayOf(PropTypes.string),
};

AppContextProvider.defaultProps = {
  accessToken: null,
  currentUserLang: '',
  feSiteRoles: {},
  globalFeatures: [],
  loggedIn: false,
  userRoles: [],
};

const mapStateToProps = (state) => ({
  accessToken: authSelectors.getAccessTokenSelector(state),
  currentUserLang: localizationSelectors.getCurrentUserLanguage(state),
  feSiteRoles: userSelectors.getCurrentUserRoles(state),
  globalFeatures: commonSelectors.getGlobalFeatures(state),
  isLoadingFeatures: commonSelectors.isFetchingFeatures(state),
  loggedIn: authSelectors.isLoggedInSelector(state),
  userRoles: userSelectors.getUserRoles(state),
});

const mapDispatchToProps = {
  onLoadCurrentUserLang: localizationActions.getCurrentUserLanguage,
  onLoadGlobalFeatures: commonActions.loadGlobalFeatures,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContextProvider);
