import Warning from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IconBox } from '../../../../components';
import messages from './messages';

const CreditSummaryWarning = ({ licencesPool }) => (
  <>
    <Box pb={2}>
      <Typography>
        <Box color="error.main">
          <FormattedMessage
            {...messages.creditSummaryWarningInfo}
            values={{
              licencesPool: licencesPool.toFixed(1),
            }}
          />
        </Box>
      </Typography>
    </Box>
    <IconBox icon={<Warning color="error" />}>
      <Typography>
        <FormattedMessage {...messages.creditSummaryWarningContent} />
      </Typography>
    </IconBox>
  </>
);

CreditSummaryWarning.propTypes = {
  licencesPool: PropTypes.number.isRequired,
};

export default CreditSummaryWarning;
